import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router";
import {
  UncontrolledTooltip,
  Row,
  Col,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import Table from "./CustomTable";
import TextPopup from "components/common/TextModal";
import Pagination from "./Pagination";
import env from "env";
import useCulture from "hooks/useCulture";

const SearchModal = ({
  searchPopup,
  updateData,
  shipmentType,
  extraField,
  popupSearchCtx = () => {},
  data = [],
  extraFilterField
}) => {

  const culture = useCulture();

  const [disabledPagination, setDisabledPagination] = useState(true);
  const [hookData, setHookData] = useState([]);
  const shipmentTypeArr = ["aircargo", "lcl", "fcl"];

  const {
    searchField,
    setSearchField,
    // hookData,
    openPopup,
    handleClose,
    column,
    header,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    initPagination,
    handleColumnName,
    // sortColumn,
    // isAscending,
    // handleSort
  } = searchPopup;

  const columns = [column];
  const headers = ["actions", header];

  // extra field is an object with column and header props
  // which allow you to add another column to the popup table
  // if the extraField has a column and it's not the selected one show it
  if (extraField && extraField.column && column !== extraField.column) {
    headers.push(extraField.header);
    columns.push(extraField.column);
  }

  // if the column name has 'text' in the name that means it's an enum
  // remove 'text'
  const editedColumn = useCallback(() => handleColumnName())();

  const params = useParams();

  const shipmentTypeText = shipmentType
    ? shipmentTypeArr[shipmentType - 1]
    : "";

  const handleSearch = async () => {
    const args = shipmentTypeText || params.shipmentType || shipmentType || extraFilterField;

    searchPopup.onSearch(args, (data) => {
      setDisabledPagination(false);
      setHookData(data);
    });
  };

  const handleSelect = () => {
    if (searchField) searchPopup.setIsSelected(true);
    updateData(hookData);
    popupSearchCtx(column, searchField);
    handleClose();
  };

  useEffect(() => {
    initPagination();
  }, []);

  useEffect(() => {
    // This shouldn't be run if there's nothing in search field
    if (disabledPagination) return;

    handleSearch();
  }, [pageNo, docsPerPage, 
    // sortColumn, isAscending
  ]);

  return (
    <TextPopup
      modal={openPopup}
      handleCancel={handleClose}
      fn={() => {}}
      color="success"
      noBtn
    >
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="customerCode">
                {header}
              </label>
              {column === "followUpStatusText" ? (
                <Input
                  id="customerCode"
                  placeholder="search"
                  type="select"
                  onChange={(e) => setSearchField(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                >
                  <option value="disabled" disabled>-- select an option --</option>
                  {env.followUpStatus.map(item => (
                    <option key={item.key} value={item.key}>{item[culture]}</option>
                  ))}
                </Input>
              ) : (
                <Input
                  id="customerCode"
                  placeholder="search"
                  type={
                    column === "orderNumber" ||
                    column === "bulkPckgNum" ||
                    column === "spCode"
                      ? "number"
                      : "text"
                  }
                  onChange={(e) => setSearchField(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                />
              )}
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={handleSearch}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-search" />
              </span>
              <span>Search</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={hookData.length}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
        disabled={disabledPagination}
      >
        <Table
          data={hookData}
          setData={setHookData}
          headers={headers}
          columns={columns}
          // handleSort={handleSort}
          handleSelect={handleSelect}
        >
          <span className="me-1" fun="handleSelect">
            <div id="check" className="table-action cursor-pointer">
              <i className="far fa-check-circle hover-success fa-lg" />
            </div>
            <UncontrolledTooltip delay={0} target="check">
              check
            </UncontrolledTooltip>
          </span>
        </Table>
      </Pagination>
    </TextPopup>
  );
};

export default SearchModal;
