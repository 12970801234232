import client from "./client";

const endpoint = "/v1/FCL";

const getAllFCL = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const FCLPreload = (page, docPerPage) =>
  client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const createFCL = (schema) => client.post(`${endpoint}`, schema);

const updateFCL = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getFCL = (id) => client.get(`${endpoint}/GetById?id=${id}`);

const approveFCL = (id) => client.put(`${endpoint}/Approve/${id}`);

const uploadImgFCL = (id, imgs) => {
  const data = new FormData();
  for (const key in imgs) {
    data.append("Image", imgs[key]);
  }

  return client.put(`${endpoint}/img/${id}`, data);
};

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const loaded = (id) => client.put(`${endpoint}/loaded/${id}`);

const shipped = (id) => client.put(`${endpoint}/shipped/${id}`);

const findSp = (id) => client.get(`${endpoint}/findSpFcl/${id}`);

export default {
  FCLPreload,
  createFCL,
  updateFCL,
  getFCL,
  getAllFCL,
  approveFCL,
  uploadImgFCL,
  getByColumn,
  loaded,
  shipped,
  findSp
};
