import React, { useEffect, useState, useContext } from "react";
//router
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
// get culture from utils
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import useAlert from "hooks/useAlert";
import NavPills from "components/common/NavPills";
import useAbility from "hooks/useAbility";
import Comment from "components/common/Comment";
import TicketApi from "../../../api/TicketApi";
import { useForm, Controller } from "react-hook-form";
import useCulture from "hooks/useCulture";
import commentContent from "components/common/content";
import content from "../content";
import env from "env";
import userPic from "assets/img/icons/common/avatar.jpg";
import Gallery from "components/common/Gallery";

const TicketDetails = () => {
  const ticketsApi = useApi(TicketApi.findById);
  const [navPills, setNavPills] = useState(0);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { alert, sweetAlert } = useAlert();

  const history = useHistory();
  const location = useLocation();

  const checkAbility = useAbility("Comment");

  const culture = useCulture();

  const createComment = useApi(TicketApi.createComment);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const { id } = useParams();
  const getTickets = async () => {
    const res = await ticketsApi.request(id);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const type = ["internalComment", "externalComment"];
  const commentType = ["internalComments", "externalComments"];

  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = {
      ...formData,
      ticketId: id,
    };

    const res = await createComment.request(type[navPills], schema);
    if (res.ok) {
      // if the open tab is clicked
      // add the new row
      const newData = { ...data };
      newData[commentType[navPills]].unshift(res.data.data);
      //const newData = [prevData => prevData.commentType[navPills].push(res.data.data.commentType[navPills])]
      setData(newData);
      reset();
      sweetAlert("Done");
    }
    setSpinner(false);
  };
  useEffect(() => {
    getTickets();
  }, [location]);

  const links = [content.internal[culture], content.external[culture]];

  const commentConditon = () => {
    if (
      navPills === 1 &&
      data.ticketState === 1 &&
      checkAbility(type[navPills])
    )
      return true;
    if (navPills === 0 && checkAbility(type[navPills])) return true;
    return false;
  };

  if (ticketsApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;
  return (
    <>
      {alert}
      {ticketsApi.errorAlert}
      {createComment.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      <SimpleHeader parents={[`Customer Service`]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.ticketDetails[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="client">
                    {commentContent.user[culture]}
                  </label>
                  <Input
                    id="client"
                    placeholder={commentContent.user[culture]}
                    type="text"
                    value={
                      (data.currentHolder && data.currentHolder.fullName) || ""
                    }
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="client">
                    {commentContent.customer[culture]}
                  </label>
                  <Input
                    id="client"
                    placeholder={commentContent.customer[culture]}
                    type="text"
                    value={(data.customer && data.customer.customerName) || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="client">
                    {commentContent.customerCode[culture]}
                  </label>
                  <Input
                    id="client"
                    placeholder={commentContent.customer[culture]}
                    type="text"
                    value={(data.customer && data.customer.customerCode) || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="subject">
                    {content.subject[culture]}
                  </label>
                  <Input
                    id="subject"
                    placeholder={content.subject[culture]}
                    type="text"
                    readOnly
                    value={data.subject || ""}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="subject">
                    {content.ticketState[culture]}
                  </label>
                  <Input
                    id="subject"
                    type="text"
                    readOnly
                    value={
                      env.ticketState[data?.ticketState - 1]?.[culture] || ""
                    }
                  />
                </FormGroup>
              </Col>
              {data.oldTicket?.subject && (
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="old-ticket">
                      {content.oldticket[culture]}
                    </label>
                    <div className="input-view">
                      <span>
                        {(data.oldTicket && data.oldTicket.subject) || ""}
                      </span>
                      <Link
                        to={`/${culture}-admin/ticket/${data.oldTicket?.id}`}
                      >
                        <i className="fas fa-eye hover-info" />
                      </Link>
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="description">
                    {content.description[culture]}
                  </label>
                  <Input
                    id="description"
                    placeholder="description"
                    type="textarea"
                    value={data.description || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Gallery title={commentContent.images[culture]} imgs={data.path?.split(",")} />
        <Card>
          <CardHeader>
            <h3>{content.ticketHolders[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className="ticket-holder">
              {data.ticketHolders?.map((holder) => (
                <>
                  <Col className="d-flex flex-column align-items-center">
                    <div style={{ textAlign: "center" }}>
                      <div style={{ width: 50, height: 50 }}>
                        <img
                          src={userPic}
                          alt={holder.fullName}
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div>{holder.fullName}</div>
                    </div>
                  </Col>
                  <Col className="align-self-center d-flex justify-content-center">
                    <i
                      className={`fas fa-long-arrow-alt-${
                        culture === "en" ? "right" : "left"
                      }`}
                    />
                  </Col>
                </>
              ))}
              <Col className="d-flex flex-column align-items-center">
                <div style={{ textAlign: "center" }}>
                  <div style={{ width: 50, height: 50 }}>
                    <img
                      src={userPic}
                      alt={data.currentHolder?.fullName}
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>{data.currentHolder?.fullName}</div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {checkAbility("view") && (
          <Card>
            <CardHeader>
              <h3>{content.comment[culture]}</h3>
            </CardHeader>
            <CardBody>
              <NavPills
                links={links}
                navPills={navPills}
                setNavPills={setNavPills}
              />
              <hr />
              {data[commentType[navPills]]?.map((comment) => (
                <Comment
                  key={comment.id}
                  date={comment.createdOn}
                  content={comment.theComment}
                  username={comment.applicationUser?.fullName}
                  commentType={commentType[navPills]}
                />
              ))}
              {commentConditon() && (
                <form onSubmit={handleSubmit(handleCreate)}>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <FormGroup>
                        <Button color="success" type="submit">
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-fat-add" />
                          </span>
                          <span>{commentContent.add[culture]}</span>
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col lg="10">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="theComment"
                          rules={{ required: "Subject feild is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="theComment"
                              placeholder={content.comment[culture]}
                              type="text"
                              className={errors.theComment && "error"}
                              value={value || ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              )}
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default TicketDetails;
