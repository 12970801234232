import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import countryApi from "api/country";
import bulks from "api/bulks";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";

const BulkTable = (props) => {
  const {
    setLinkModal,
    setValue,
    sweetAlert,
    shipmentType,
  } = props;

  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [bulkPackageNumber, setBulkPackageNumber] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const findBulkApi = useApi(bulks.getByColumn);

  const editResponse = item => {
    item.bulkPckgNum = `B-${item.bulkPckgNum}${item.country?.countryCode}`;
    return item;
  };

  const findBulk = async () => {
    const res = await findBulkApi.request("bulkPckgNum", bulkPackageNumber, pageNo, docsPerPage, shipmentType);

    if (res.status === 200) {
      res.data.data.forEach(item => editResponse(item));
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    setLinkModal(true);
    setValue("bulkId", obj.id);
  };

  useEffect(() => {
    findBulk();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
    findBulkApi.error && findBulkApi.data && sweetAlert(findBulkApi.data, true),
    [findBulkApi.data]
  );

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="number-of-package"
              >
                Bulk package number
              </label>
              <Input
                id="number-of-package"
                placeholder="search"
                type="text"
                value={bulkPackageNumber}
                onChange={(e) => setBulkPackageNumber(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findBulk()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findBulk}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-search" />
              </span>
              <span>Search</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={data?.length || 0}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={["actions", "Bulk package number"]}
          columns={["bulkPckgNum"]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info" />
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
};

export default BulkTable;
