import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import { useForm } from "react-hook-form";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import Spinner from "../../../components/Spinner";
import useApi from "../../../hooks/useApi";
import user from "api/user";
import useAlert from "hooks/useAlert";
import TextPopup from "components/common/TextModal";
import content from "../Users/content";
import commenContent from "components/common/content";
import SearchModal from "components/common/SearchModal";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import usePagination from "hooks/usePagination";
import { PaginationContext } from "context/PaginationContext";
import useSearchPopup from "hooks/useSearchPopup";
import usePrevRequest from "hooks/usePrevRequest";
import useAbility from "hooks/useAbility";

function ApproveUsers() {
  // useAlert custom hook
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const checkAbility = useAbility("User");

  // Apis
  const notActiveUsers = useApi(user.notActiveUsers);
  const userActive = useApi(user.userActive);
  const getByColumn = useApi(user.getNotApprovedByColumn);
  const deleteNotActiveUsersApi = useApi(user.deleteNotActiveUsers);

  // states
  const [data, setData] = useState([]);
  // const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [approveModal, setApproveModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  // form validation
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const headers = [
    commenContent.actions[culture],
    commenContent.name[culture],
    commenContent.phone[culture],
  ];

  const columns = ["fullName", "whatsappPhone"];

  const paginationCtx = useContext(PaginationContext);
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "approve-users";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const updateData = (arr) => {
    setData(arr);
  };

  const editResponse = (item) => item;

  // search popup
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const approveUser = async () => {
    setSpinner(true);
    setApproveModal(false);
    const res = await userActive.request(rowId);
    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
    }
    setSpinner(false);
    setRowId("");
  };
  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = {
      applicationUserId: currentUser.id,
      cusName: formData.fullName,
      phone: formData.whatsappPhone,
      cusCode: currentUser.cusCode,
    };

    // const res = await createCustomer.request(schema);

    // // 200 or 201
    // if (res.ok) {
    //   const newData = data.filter((user) => user.id !== currentUser.id);

    //   setData(newData);
    //   reset();
    //   sweetAlert("Done");
    // }
    setSpinner(false);
  };

  const getNotActiveUsers = async () => {
    const res = await notActiveUsers.request(pageNo, docsPerPage);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleApprove = (obj) => {
    setApproveModal(true);
    setRowId(obj.id);
  };

  const handleCancel = () => {
    setRowId("");
    setApproveModal(false);
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteNotActiveUsersApi.request(rowId);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const renderApprove = () => checkAbility("useractive");

  const deleteRenderer = () => checkAbility("notacitve");

  usePrevRequest(
    getNotActiveUsers,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck
  );

  return (
    <div>
      {alert}
      {userActive.errorAlert}
      {notActiveUsers.errorAlert}
      {getByColumn.errorAlert}
      {deleteNotActiveUsersApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {notActiveUsers.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.approveUsers[culture]]} />
          <Container className="mt--6" fluid>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.approveUsers[culture]}
                  </h3>
                </div>
              </CardHeader>
              <Pagination
                dataLength={data.length}
                // when the user search using the popup and select a row
                // after then only use the pagination states from searchPopup hook
                pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
                setPageNo={
                  searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
                }
                docsPerPage={
                  searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
                }
                setDocsPerPage={
                  searchPopup.isSelected
                    ? searchPopup.setDocsPerPage
                    : setDocsPerPage
                }
              >
                <Table
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  data={data || []}
                  setData={setData}
                  handleDelete={handleDelete}
                  renderApprove={renderApprove}
                  handleApprove={handleApprove}
                  deleteRenderer={deleteRenderer}
                  onHeaderClick={searchPopup.handleHeaderClicked}
                  visitedRowId={paginationCtx.visitedRowId}
                  // when the user was searching useing the popup and select a row
                  // store the popup pagination in the context
                  // otherwise store the normal pagination in the context
                  setPagination={
                    searchPopup.isSelected
                      ? (rowId) =>
                          paginationCtx.setPagination(
                            searchPopup.pageNo,
                            searchPopup.docsPerPage,
                            rowId,
                            pageName
                          )
                      : (rowId) =>
                          paginationCtx.setPagination(
                            pageNo,
                            docsPerPage,
                            rowId,
                            pageName
                          )
                  }
                >
                  <span
                    to={`/${culture}-admin/userdetails/${pageNo}/${docsPerPage}`}
                  >
                    <div id="edit1" className="table-action cursor-pointer">
                      <i className="fas fa-eye fa-lg hover-info"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="edit1">
                      {commenContent.showDetails[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span fun="handleApprove" condition="renderApprove">
                    <div id="aprove1" className="table-action cursor-pointer">
                      <i className="far fa-check-circle hover-success fa-lg" />
                    </div>
                    <UncontrolledTooltip delay={0} target="aprove1">
                      {content.approveUser[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span
                    className="ms-1"
                    fun="handleDelete"
                    condition="deleteRenderer"
                  >
                    <div id="delete" className="table-action cursor-pointer">
                      <i className="fas fa-trash hover-danger fa-lg" />
                    </div>
                    <UncontrolledTooltip delay={0} target="delete">
                      {content.delete[culture]}
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
            </Card>
          </Container>
          <TextPopup
            modal={approveModal}
            text="Approve"
            handleCancel={handleCancel}
            fn={approveUser}
            color="primary"
            name={data.find((user) => user.id === rowId)?.fullName}
          >
            <Container>
              <h3>{content.approveUserPopUp[culture]}</h3>
            </Container>
          </TextPopup>
          <TextPopup
            modal={deleteModal}
            text={content.yes[culture]}
            handleCancel={handleCancel}
            fn={deleteRow}
            color="danger"
          >
            <Container>
              <h2>{content.deletePopup[culture]}</h2>
            </Container>
          </TextPopup>
        </>
      )}
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: "order id", column: "orderNumber" }}
        />
      )}
    </div>
  );
}

export default ApproveUsers;
