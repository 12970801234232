import React, { useState, useEffect } from "react";
import { Card, Container, CardHeader, UncontrolledTooltip } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import country from "api/country";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import EditCountry from "./components/EditCountry";
import getCulture from "utils/getCulture";
import useAbility from "hooks/useAbility";
import commonContent from "components/common/content";
import content from "./content";

const Country = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const headers = [
    commonContent.actions[culture],
    commonContent.name[culture],
    commonContent.code[culture],
  ];
  const columns = ["name", "countryCode"];

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);

  const checkAbility = useAbility("Country");
  const { alert, sweetAlert } = useAlert();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors, isDirty: editIsDirty },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const countriesApi = useApi(country.countries);
  const getByColumn = useApi(country.getByColumn);
  const updateCountryApi = useApi(country.updateCountry);
  const activateCountryApi = useApi(country.activateCountry);
  const deactivateCountryApi = useApi(country.deactivateCountry);

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    (item) => item
  );

  const getCountries = async () => {
    const res = await countriesApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    setData(arr);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setActivateModal(false);
    setDeactivateModal(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("name", obj.name);
    editSetValue("countryCode", obj.countryCode);
    setEditModal(true);
  };

  const handleActivate = (obj) => {
    setRowId(obj.id);
    setActivateModal(true);
  };

  const handleDeactivate = (obj) => {
    setRowId(obj.id);
    setDeactivateModal(true);
  };

  const editCountryHandler = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateCountryApi.request(rowId, {
      countryCode: formData.countryCode,
    });

    if (res.status === 200) {
      const newData = [...data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      newData[rowIndex] = res.data.data;
      setData(newData);
      sweetAlert("Done");
    }
    setRowId("");
    setSpinner(false);
  };

  const activateCountryHandler = async () => {
    setActivateModal(false);
    setSpinner(true);
    const res = await activateCountryApi.request(rowId);
    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      newData[index].isActive = true;
      setData(newData);
    }
    setSpinner(false);
    setRowId("");
  };

  const deactivateCountryHandler = async () => {
    setSpinner(true);
    setDeactivateModal(false);
    const res = await deactivateCountryApi.request(rowId);
    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      newData[index].isActive = true;
      setData(newData);
    }
    setSpinner(false);
    setRowId("");
  };

  const editRenderer = () => checkAbility("edit");

  const renderActivateIcon = (obj) => !obj.isActive && checkAbility("active");

  const renderDeactivateIcon = (obj) =>
    obj.isActive && checkAbility("deactive");

  useEffect(() => {
    getCountries();
  }, [pageNo, docsPerPage]);

  if (countriesApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;
  return (
    <div>
      {alert}
      {countriesApi.errorAlert}
      {updateCountryApi.errorAlert}
      {getByColumn.errorAlert}
      {activateCountryApi.errorAlert}
      {deactivateCountryApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[commonContent.country[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {commonContent.country[culture]}
              </h3>
            </div>
          </CardHeader>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName="country"
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              handleEdit={handleEdit}
              handleActivate={handleActivate}
              handleDeactivate={handleDeactivate}
              editRenderer={editRenderer}
              renderActivateIcon={renderActivateIcon}
              renderDeactivateIcon={renderDeactivateIcon}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              {/* <span fun="handleEdit" className="me-1" condition="editRenderer">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  Edit
                </UncontrolledTooltip>
              </span> */}
              <span
                fun="handleActivate"
                className="me-1"
                condition="renderActivateIcon"
              >
                <div id="activate" className="table-action cursor-pointer">
                  <i className="fas fa-bolt fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="activate">
                  {content.activate[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleDeactivate"
                className="me-1"
                condition="renderDeactivateIcon"
              >
                <div id="deactivate" className="table-action cursor-pointer">
                  <i className="fas fa-ban fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="deactivate">
                  {content.deactivate[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text="Yes"
        handleCancel={handleCancel}
        fn={editHandleSubmit(editCountryHandler)}
        color="primary"
        disabled={!editIsDirty}
      >
        <Container>
          <EditCountry control={editControl} errors={editErrors} />
        </Container>
      </TextPopup>
      <TextPopup
        modal={activateModal}
        text="Yes"
        handleCancel={handleCancel}
        fn={activateCountryHandler}
        color="primary"
        name={data.find((item) => item.id === rowId)?.name}
      >
        <Container>
          <h3>Are you sure you want to Activate This country ?</h3>
        </Container>
      </TextPopup>
      <TextPopup
        modal={deactivateModal}
        text="Yes"
        handleCancel={handleCancel}
        fn={deactivateCountryHandler}
        color="primary"
        name={data.find((item) => item.id === rowId)?.name}
      >
        <Container>
          <h3>Are you sure you want to Deactivate This country ?</h3>
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={(item) => item}
          // popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: "name", column: "coutryName" }}
        />
      )}
    </div>
  );
};

export default Country;
