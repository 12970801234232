import accouningClient from "./accountingClient";

const endpoint = "/v1/costCenter";

const getAll = (page, docPerPage) =>
  accouningClient.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const filter = (schema, page, docPerPage) =>
  accouningClient.put(
    `${endpoint}/filter?Page=${page}&PostsPerPage=${docPerPage}`,
    schema
  );

const getByColumn = (col, val, page, docsPerPage) =>
  accouningClient.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

export default {
  getAll,
  filter,
  getByColumn,
};
