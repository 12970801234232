export default {
  warehouse: {
    ar: "المخازن",
    en: "Warehouses",
  },
  name: {
    ar: "الإسم",
    en: "Name",
  },
  city: {
    ar: "المدينة",
    en: "City",
  },
  note: {
    ar: "الملاحظات",
    en: "Notes",
  },
  title: {
    ar: "العنوان",
    en: "Title",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المخزن ؟",
    en: "Are you sure you want to delete this warehouse ?",
  },
};
