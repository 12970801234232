const Url = "https://gw.mail.oneify-store.com/api/v1";
const farhat = "http://192.168.0.107/GW.Mail.WebServerApi/api/v1";
const origin = "http://192.168.0.100:5000";
//http://192.168.0.100:3000/#/ar-auth/login
const heroku = "https://gw-archive.herokuapp.com";
const frontUrl = "http://localhost:3000";
const ls = "https://archive.ggw.ly";
const netlify = "https://gifted-snyder-ceea35.netlify.app";
const shaheen = "https://gisloaction.gw.ly";

// exports.Url = () => Url;
// exports.farhat = () => farhat;
// exports.Origin = () => Origin;
exports.activeServer = () => `${shaheen}/api/v1`;
exports.frontUrl = () => netlify;
exports.employee = () => "https://hr.gw.ly/api/v1/Employee";
//http://192.168.0.102:5000
exports.activeServerUrl = () => heroku;
exports.identity = () => `https://identety.herokuapp.com/api/v1`;
exports.swagger = () => "https://hr.gw.ly/api/v1";
// http://192.168.0.106/GW.Mail.WebServerApi/api/v1
exports.status = {
  ar: [
    "تم انشائها",
    "تم التواصل",
    "تم تسديد الفاتورة",
    "تم التنسيق",
    "في الطريق",
    "تم الاستلام",
    "مدفوعة",
    "تم تسليمها",
  ],
  en: [
    "Initiated",
    "Contacted",
    "Paid",
    "Arranged",
    "Ontheway",
    "Recived",
    "Invoiced",
    "Delivered",
  ],
};

// en: [ 'Initiated', 'Contacted', 'Recived', 'Swift']
//          1               2           6          9

exports.followUpStatusBulk = [
  { key: 3, text: "Shipped" },
  { key: 4, text: "Arrived" },
];

// These Two are used for search popup
exports.followUpStatusInvoice = [
  { key: 1, text: "Initiated" },
  { key: 2, text: "Contacted" },
  { key: 9, text: "Swift" },
];

exports.followUpStatusRecived = [
  { key: 4, text: "Recived" },
  { key: 9, text: "Swift" },
  { key: 13, text: "Rest received" },
];

exports.colors = [
  "#34eb71",
  "#34ebe5",
  "#3452eb",
  "#eba834",
  "#e9f230",
  "#f28130",
  "#00ff33",
  "gray",
];

exports.country = ["Uae", "Eur", "China", "Usa"];

exports.price = ["Dollar", "Yuan"];

exports.shipmentType = ["Air", "LCL", "", "FCL"];

exports.priceValue = ["kg", "cbm"];

exports.priceType = ["Plus", "normal", "custome price"];

exports.types = ["Shipping", "Transaction"];

exports.usersType = ["Personal", "Company"];

exports.pages = [5, 10, 15, 25, 50];

exports.bulkType = ["", "Air", "LCL"];

exports.shippingType = ["Noraml", "Medical", "machine CNC", "copy"];

exports.commType = [];

exports.priceRange = ["1-25k", "26-100k", "101-150k", "> 150 k"];

exports.priceRangeMedical = ["1-20k", "> 20k"];

exports.followUpStatus = [
  { key: 1, text: "Initiated", en: "Initiated", ar: "تم الإنشاء" },
  { key: 2, text: "Contacted", en: "Contacted", ar: "تم التواصل" },
  { key: 3, text: "Wrong", en: "Wrong", ar: "خطأ في التواصل" },
  { key: 4, text: "Swift", en: "Swift", ar: "سويفت" },
  { key: 5, text: "Recived", en: "Recived", ar: "استلمت في المخزن" },
  { key: 6, text: "Rest Recived", en: "Rest Recived", ar: "شحن جزئي" },
  { key: 7, text: "Shipping", en: "Shipping", ar: "في الشحن" },
  { key: 8, text: "Arraived", en: "Arraived", ar: "تم وصول الشحنة" },
  { key: 9, text: "Deliverd", en: "Deliverd", ar: "تم الإستلام" },
  { key: 10, text: "Created Bulk", en: "Created Bulk", ar: "إنشاء Bulk" },
  { key: 11, text: "Loaded", en: "Loaded", ar: "تم التحميل" },
  {
    key: 12,
    text: "ChangedToLcl",
    en: "Changed To Lcl",
    ar: "تغيرت الى شحنة بحرية",
  },
  {
    key: 13,
    text: "ChangedToAir",
    en: "Changed To Air",
    ar: "تغييرت الى شحنة جوية",
  },
];

// exports.currency = ["LYD", "Yuan", "USD"];

exports.currency = ["USD", "Lira", "RMB", "EU", "Pound", "AE"];

exports.commidityType = ["Normal", "Medical", "MachineCNC", "Copy"];

exports.size = ["20", "40", "40HQ", "45"];

exports.trackLine = ["CMA", "Mearsek", "MSC", "COSCO", "VOLTA", "MESSINA"];

exports.ticketType = ["Open", "Re-open"];

exports.customerType = [
  { en: "Vip", ar: "مهم" },
  {
    ar: "عادي",
    en: "Normal",
  },
];

exports.ticketState = [
  { en: "Opened", ar: "مفتوحة" },
  { en: "Resolved", ar: "تم حلها" },
  { en: "Closed", ar: "مغلقه" },
];

exports.stationNumberLcl = [
  { ar: "محطة1", en: "Station1" },
  { ar: "محطة2", en: "Station2" },
  { ar: "محطة3", en: "Station3" },
  { ar: "محطة4", en: "Station4" },
  { ar: "محطة5", en: "Station5" },
];

exports.stationNumberAir = [
  { ar: "مستودع الصين", en: "China Warehouse" },
  { ar: "هونج كونج", en: "Hong Kong" },
  { ar: "مطار الشارقة", en: "Sharjah Airport" },
  { ar: "مطار ليبيا", en: "Libya Airport" },
];

exports.entryCurrency = [
  { en: "USD", ar: "دولار أمريكي" },
  { en: "LYD", ar: "دينار ليبي" },
  { en: "Euro", ar: "يورو" },
];

exports.currency = [
  { ar: "دولار امريكي", en: "USD" },
  { ar: "دينار ليبي", en: "LYD" },
  { ar: "يورو", en: "Euro" },
];

exports.stationNumberLcl = [
  { ar: "الصين", en: "China" },
  { ar: "سري لانكا", en: "Sri Lanka" },
  { ar: "صوماليا", en: "Somalia" },
  { ar: "مصر", en: "Egypt" },
  { ar: "ليبيا", en: "Libya" },
];
/**
 * 1 China
2 Sri Lanka
3 Somalia
4 Egypt 
5 Libya
 */
exports.stationNumberAir = [
  { ar: "مستودع الصين", en: "China Warehouse" },
  { ar: "هونج كونج", en: "Hong Kong" },
  { ar: "مطار الشارقة", en: "Sharjah Airport" },
  { ar: "مطار ليبيا", en: "Libya Airport" },
];
/*
Station1  => China Warehouse 
Station2  => Hong Kong 
Station3 => Sharjah Airport 
Station4 =>Libya Airport
*/
