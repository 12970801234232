import React, { useState } from "react";
import { Col, FormGroup, Input, Row, Container } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import { Controller } from "react-hook-form";
import content from "../content";
import env from "env";
import FilePicker from "components/common/FilePicker";
import useAlert from "hooks/useAlert";
import CountryTable from "components/common/CountryTable";
import TextPopup from "components/common/TextModal";
import PopupTable from "components/common/PopupTableUsed";
import commenContent from "components/common/content";

function EditLCL({ editImage, setEditImage, errors, control, setValue }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [countryTable, setCountryTable] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const handleCancel = () => {
    setCountryTable(false);
    setCustomerModal(false);
  };

  return (
    <div>
      {alert}
      <Row>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="client">
              {commenContent.customer[culture]}
            </label>
            <Controller
              control={control}
              name="client"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="client"
                  placeholder={commenContent.customer[culture]}
                  type="text"
                  className={errors.client && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCustomerModal(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="volume">
              {commenContent.volume[culture]}
            </label>
            <Controller
              control={control}
              name="volume"
              rules={{ required: "volume field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="volume"
                  type="number"
                  className={errors.volume && "error"}
                  value={value || 0}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(+value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="numberOfPkgs">
              {commenContent.numOfPackages[culture]}
            </label>
            <Controller
              control={control}
              name="numberOfPkgs"
              rules={{ required: "volume field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="numberOfPkgs"
                  type="number"
                  value={value || ""}
                  onWheel={(e) => e.target.blur()}
                  className={errors.numberOfPkgs && "error"}
                  onChange={({ target: { value } }) => onChange(+value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="country">
              {commenContent.country[culture]}
            </label>
            <Controller
              control={control}
              name="country"
              rules={{ required: "volume field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="country"
                  placeholder={commenContent.country[culture]}
                  type="text"
                  value={value || 0}
                  className={errors.country && "error"}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCountryTable(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="commodityType">
              {commenContent.commodityType[culture]}
            </label>
            <Controller
              control={control}
              name="commodityType"
              rules={{ required: "commidity type field is required", min: 1 }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="commodityType"
                  type="select"
                  value={value || 0}
                  className={errors.commodityType && "error"}
                  onChange={({ target: { value } }) => onChange(+value)}
                >
                  <option disabled value={0}>
                    -- {commenContent.selectAnOption[culture]} --
                  </option>
                  {env.commidityType.map((status, i) => (
                    <option key={i} value={i + 1}>
                      {status}
                    </option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="commodity">
              {commenContent.commodity[culture]}
            </label>
            <Controller
              control={control}
              name="commodity"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="commodity"
                  type="textarea"
                  style={{ resize: "none" }}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <label className="form-control-label" htmlFor="img">
            {content.uploadImg[culture]}
          </label>
          <FilePicker
            images={editImage}
            setImages={(files) => setEditImage(files)}
          />
        </Col>
      </Row>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={() => setCountryTable(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={customerModal}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(obj) => setValue("customerId", obj.id)}
            setTableModal={setCustomerModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </div>
  );
}

export default EditLCL;
