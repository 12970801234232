import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import FilePicker from "components/common/FilePicker";
import template from "./templateApi";
import useApi from "hooks/useApi";
import useAlert from "hooks/useAlert";
import Spinner from "../../components/Spinner";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Explore = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const [cards, setCards] = useState([0]);
  const [spinner, setSpinner] = useState(false);

  const templateApi = useApi(template.getPortfolio);
  const serviceApi = useApi(template.postService);

  const { alert, sweetAlert } = useAlert();

  const handleAddRow = () => {
    const lastItem = cards.at(-1);

    const newCards = [...cards, lastItem + 1];
    setCards(newCards);
  };

  const handleDeleteRow = (index) => {
    const newCards = [...cards];
    newCards.splice(index, 1);
    setCards(newCards);
  };

  const preload = async () => {
    const res = await templateApi.request();

    if (res.status === 200) {
      const resData = res.data.data?.cards;

      const cardArrays = [];

      for (let i = 0; i < resData.length; i++) {
        cardArrays.push(i);
      }

      setCards(cardArrays);

      resData.forEach((item, index) => {
        setValue(`cardName-${index}`, item.name || "");
        setValue(`cardContent-${index}`, item.content || "");
        setValue(`cardArName-${index}`, item.arName || "");
        setValue(`cardArContent-${index}`, item.arContent || "");
      });
    }
  };

  const submit = async (data) => {
    setSpinner(true);
    const schema = {
      cardName: [],
      cardArName: [],
      cardContent: [],
      cardArContent: [],
      cardImg: [],
    };

    cards.forEach(() => {
      schema.cardName.push("");
      schema.cardArName.push("");
      schema.cardContent.push("");
      schema.cardArContent.push("");
      schema.cardImg.push("");
    });

    for (const key in data) {
      const propName = key.split("-")[0];
      const index = key.split("-")[1];

      schema[propName][index] = data[key];
    }
    const formData = new FormData();
    for (const key in schema) {
      if (key === "cardImg") {
        schema[key].forEach((item, index) => formData.append("cardImg", item[0], `${index}`));
        // schema[key].forEach((value, i) => formData.append(key + `[${i}]`, value[0]))

      } else {
        schema[key].forEach((item) => formData.append(key, item));
        // schema[key].forEach((value, i) => formData.append(key + `[${i}]`, value))
      }
    }
    // const schema = [];

       //   schema.cardName.push("");
    //   schema.cardArName.push("");
    //   schema.cardContent.push("");
    //   schema.cardArContent.push("");
    //   schema.cardImg.push("");

    // for(let i = 0; i < cards.length; i++) {
    //   schema.push({
    //     name: data[`cardName-${i}`],
    //     arName: data[`cardArName-${i}`],
    //     content: data[`cardContent-${i}`],
    //     arContent: data[`cardArContent-${i}`]
    //   })
    // }
    const res = await serviceApi.request(formData);
    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  useEffect(() => {
    preload();
  }, []);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  const imgChecker = () => {
    for (let i = 0; i < cards.length; i++) {
      if (!watch(`cardImg-${i}`)) return true;
    }
    return false;
  };

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {serviceApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.explore[culture]}</h3>
            </div>
          </CardHeader>
        </Card>
        <form onSubmit={handleSubmit(submit)}>
          {cards.map((item, index) => (
            <Card key={item}>
              <CardBody>
                {cards.length > 1 && (
                  <Button
                    color="danger"
                    type="button"
                    className="mb-4"
                    style={{ marginLeft: "auto", display: "block" }}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-trash" />
                    </span>
                  </Button>
                )}
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Card title - {item + 1}
                          </label>
                          <Controller
                            control={control}
                            name={`cardName-${index}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="name"
                                placeholder="Name"
                                type="text"
                                className={
                                  errors[`cardName-${index}`] && "error"
                                }
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`subText-${item}`}
                          >
                            Content - {item + 1}
                          </label>
                          <Controller
                            control={control}
                            name={`cardContent-${index}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id={`subText-${item}`}
                                placeholder="Name"
                                type="textarea"
                                className={
                                  errors[`cardContent-${index}`] && "error"
                                }
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col md="6" className="rtl-col">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`arHeader-${item}`}
                          >
                            العنوان - {item + 1}
                          </label>
                          <Controller
                            control={control}
                            name={`cardArName-${index}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id={`arHeader-${item}`}
                                placeholder="العنوان"
                                type="text"
                                className={
                                  errors[`cardArName-${index}`] && "error"
                                }
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="rtl-col">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="arSubText"
                          >
                            المحتوى - {item + 1}
                          </label>
                          <Controller
                            control={control}
                            name={`cardArContent-${index}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="arSubText"
                                placeholder="المحتوى"
                                type="textarea"
                                className={
                                  errors[`cardArContent-${index}`] && "error"
                                }
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12">
                    <label className="form-control-label" htmlFor="arSubText">
                      Card background picture
                    </label>
                    <FilePicker
                      multiple={false}
                      images={watch(`cardImg-${index}`) || {}}
                      setImages={(img) => setValue(`cardImg-${index}`, img)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
          <Card>
            <CardBody>
              <Button color="info" type="button" className="me-1" onClick={handleAddRow}>
                <span>{content.addCard[culture]}</span>
              </Button>
              <Button
                color="success"
                type="submit"
                className={imgChecker() ? "disabled" : ""}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </CardBody>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default Explore;
