import client from './client';

const endpoint = '/v1';

const getAllInvoice = (page, docPerPage, shipment) => client.get(`${endpoint}/${shipment}invoice?Page=${page}&PostsPerPage=${docPerPage}`);

const invoicePreload = (page, docPerPage, shipment) => client.get(`${endpoint}/${shipment}invoice/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const createInvoice = (schema, shipment) => client.post(`${endpoint}/${shipment}invoice`, schema);

const updateInvoice = (id, schema, shipment) => client.put(`${endpoint}/${shipment}invoice/${id}`, schema);

const approveContacted = (id, shipment) => client.put(`${endpoint}/${shipment}invoice/contacted/${id}`);

const getInvoice = (id, shipment) => client.get(`${endpoint}/${shipment}invoice/GetById?id=${id}`);

const approveInvoice = (id, shipment) => client.put(`${endpoint}/${shipment}invoice/Approve/${id}`);

const wrongContact = (id, shipment) => client.put(`${endpoint}/${shipment}invoice/wrong/${id}`);

const savedContact = (id, schema, shipment) => client.put(`${endpoint}/${shipment}invoice/saved/${id}`, schema)

const approveSwift = (id, shipment, imgs) => {
  const data = new FormData();
  for(const key in imgs) {
    data.append('Image', imgs[key]);
  }
  
  return client.put(`${endpoint}/${shipment}invoice/swift/${id}`, data)
}

const getByColumn = (col, val, page, docsPerPage, shipment) => (
  client.get(`${endpoint}/${shipment}invoice/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
)

const deleteAir = (id, shipment) => client.delete(`${endpoint}/${shipment}invoice/deleteAir/${id}`);

const deleteLcl = (id, shipment) => client.delete(`${endpoint}/${shipment}invoice/deleteLcl/${id}`);

const deleteInvoice = (shipment, id) => client.delete(`${endpoint}/${shipment}invoice/${id}`);

const updateAir = (id, schema, shipment) => client.put(`${endpoint}/${shipment}invoice/updateAir/${id}`, schema);

const updateLcl = (id, schema, shipment) => client.put(`${endpoint}/${shipment}invoice/updateLcl/${id}`, schema);

const changeCargoType = (id, schema, shipment) => client.put(`${endpoint}/${shipment}/changeToThisType/${id}`, schema);

const advanceSearch = (schema, page, docsPerPage, shipment) =>
  client.put(
    `${endpoint}/${shipment}invoice/invoiceAdvanceFilter?Page=${page}&PostsPerPage=${docsPerPage}`, schema
  );


export default {
  invoicePreload,
  createInvoice,
  updateInvoice,
  getInvoice,
  getAllInvoice,
  approveInvoice,
  approveContacted,
  approveSwift,
  wrongContact,
  savedContact,
  getByColumn,
  deleteAir,
  deleteLcl,
  updateAir,
  updateLcl,
  deleteInvoice,
  changeCargoType,
  advanceSearch
}