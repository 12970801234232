import React, { useState, useEffect, useContext } from "react";

// reactstrap components
import { Table, UncontrolledTooltip } from "reactstrap";

import { Link, useHistory } from "react-router-dom";

import Print from "./Print";
import ExcelGenerater from "./ExcelGenerator";
import { PaginationContext } from "context/PaginationContext";

function Tables(props) {
  const history = useHistory();

  const [isFirtsRender, setIsFirstRender] = useState(true);
  const { sortColumn, handleSortColumn } = useContext(PaginationContext);

  const setPagination = (e, rowId, to) => {
    e.preventDefault();
    props.setPagination(rowId);
    history.push(`${to}/${rowId}`);
  };

  const isAction = (item) =>
    ["actions", "العمليات"].includes(item.toLowerCase());

  const hideColumn = (index, e) => {
    e.stopPropagation();
    const headers = [...props.headers];
    const columns = [...props.columns];
    // headers.splice(index, 1);

    if (isAction(headers[0])) {
      headers.splice(index, 1);
      columns.splice(index - 1, 1);
    } else {
      headers.splice(index, 1);
      columns.splice(index, 1);
    }
    props.setHeaders(headers);
    props.setColumns(columns);
  };

  const passDataToChildren = (obj) => {
    // loop though the children elements
    return React.Children.map(props.children, (child) => {
      // if the child attach to a condition function
      if (
        child.props.hasOwnProperty("condition") &&
        props[child.props.condition]
      ) {
        // Call the function and if it's false don't show the 'Element'
        if (!props[child.props.condition](obj)) return;
      }

      // if the child has "to" attibute wrap it in "Link" element
      if (child.props.hasOwnProperty("to")) {
        // set pagination sets the context with page page length and id of the clicked row
        // so we push it with useHistory instead of using 'Link' element
        if (props.hasOwnProperty("setPagination")) {
          return React.cloneElement(
            <Link
              className="me-1"
              {...child.props}
              to={`${child.props.to}/${obj.id}`}
              onClick={(e) => setPagination(e, obj.id, child.props.to)}
            >
              {/* <Link 
                {...child.props} 
                // setPagination function sets the pagination info for pagination context
                onClick={() => setPagination(obj.id, child.props.to)}onClick={() => setPagination(obj.id, child.props.to)}
              > */}
              {child}
              {/* </Link> */}
            </Link>
          );
        } else {
          return React.cloneElement(
            <span className="me-1">
              <Link
                {...child.props}
                to={`${child.props.to}/${obj.id || obj.fclId || obj.lclId}`}
                // setPagination function sets the pagination info for pagination context
              >
                {child}
              </Link>
            </span>
          );
        }
      }

      // if the child attach to a function add the function to click event
      if (child.props.hasOwnProperty("fun")) {
        // the function comes from table props and the name of the child funtion comes from "fun" attribute
        return React.cloneElement(child, {
          onClick: () => props[child.props.fun](obj),
        });
      }

      // otherwise only clone the element
      return React.cloneElement(child);
    });
  };

  const nestedObj = (object, columns) => {
    // 1) get all the keys as an array
    const keys = columns.split(".");
    // 2) loop throw the keys with the object parameters as an default value
    const nestedVal = keys.reduce(
      (obj, prop) => (obj[prop] ? obj[prop] : " "),
      object
    );
    // 3) return the nested value
    return nestedVal;
  };

  const CheckLength = (content) => {
    // 1) check if the content length is less 25 return the content
    if (String(content)?.length <= 25 || !content) return <>{content}</>;

    // 2) else return the substring content with tooltip
    return (
      <span>
        <div id="long-string">{content.substring(0, 25) + "..."}</div>
        <UncontrolledTooltip delay={0} target="long-string">
          {content}
        </UncontrolledTooltip>
      </span>
    );
  };

  const handleSort = (e, index) => {
    e.stopPropagation();

    if (props.columns[index - 1] === sortColumn) {
      // desc
      handleSortColumn(`-${props.columns[index - 1]}`);
    } else {
      //asc
      handleSortColumn(props.columns[index - 1]);
    }
    setIsFirstRender(true);
  };

  const sortFields = () => {
    setIsFirstRender(false);

    const newData = [...props.data];

    const isDescending = sortColumn.includes("-") ? true : false;
    const column = sortColumn.split("-").at(-1);

    const sortedData = newData.sort((a, b) => {
      if (nestedObj(a, column) < nestedObj(b, column))
        return isDescending ? 1 : -1;
      if (nestedObj(a, column) > nestedObj(b, column))
        return isDescending ? -1 : 1;
      return 0;
    });

    props.setData(sortedData);
  };

  const checkBranch = (branchName) => {
    if (branchName.startsWith("الرئي")) return;
    if (branchName.startsWith("مصرا"))
      return <i className="fab fa-medium me-1 text-danger" />;
    if (branchName.startsWith("بنغا"))
      return <i className="fas fa-bold me-1 text-primary" />;
    return;
  };

  useEffect(() => {
    let timeout;

    if (sortColumn && isFirtsRender && props.data?.length) {
      timeout = setTimeout(() => {
        sortFields();
      }, 200);
    }

    return () => clearImmediate(timeout);
  }, [sortColumn, props.data, isFirtsRender]);

  return (
    <>
      <div className="d-flex mt-4">
        <div className="ms-1">
          <Print
            headers={props.headers}
            isPrint={props.isPrint}
            printBody={props.printBody}
            printFooter={props.printFooter}
            data={props.printData?.length ? props.printData : props.data}
            columns={props.columns}
          />
        </div>
        {props.pageName && props.data?.length ? (
          <div>
            <ExcelGenerater
              pageName={props.pageName}
              excellData={props.data}
              excellHeaders={props.headers}
              excellColumns={props.columns}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Table className="align-items-center" responsive>
        {/* <div className="table-responsive overflow-print">
        <table className="table"> */}
        <thead className="thead-light">
          <tr>
            {props.headers?.map((item, index) => (
              <th
                key={index}
                scope="col"
                // "onHeaderClick" is used to search by column
                // you can't search with  follow up status
                className={`
                  ${props.onHeaderClick ? "cursor-pointer" : ""}
                  ${isAction(item) ? "notPrint" : ""}`}
                onClick={
                  isAction(item)
                    ? () => {}
                    : () =>
                        props.onHeaderClick
                          ? props.onHeaderClick(index)
                          : () => {}
                }
              >
                {!isAction(item) && props.setData && (
                  <i
                    className="fa fa-sort me-1 hover-success fa-lg cursor-pointer notPrint"
                    onClick={(e) => handleSort(e, index)}
                  />
                )}
                {item}
                {!isAction(item) &&
                  props.headers?.length > 2 &&
                  props.setHeaders && (
                    <i
                      className="fas fa-eye-slash ms-1 hover-danger cursor-pointer notPrint"
                      onClick={hideColumn.bind(null, index)}
                    />
                  )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((item, index) => (
              <tr
                key={index}
                // style={{ backgroundColor: item.bgColor }}
                className={`
              table-row 
              ${item.className}
              ${item.id === props.visitedRowId ? "selected" : ""} 
            `}
              >
                {props.children ? (
                  <td className="notPrint">
                    <div className="d-flex align-items-center justify-content-start">
                      {passDataToChildren(item)}
                    </div>
                  </td>
                ) : null}
                {props.columns.map((column, index) => (
                  <th scope="row" key={index}>
                    <span className="mb-0 text-sm custom-table-cell">
                      {column.includes("customerName") &&
                        (item.priceType || item.customer?.priceType) === 1 && (
                          <i className="fas fa-crown me-1 text-gold" />
                        )}

                      {column.includes("customerName") &&
                        (item.customer?.currentBranch || item.currentBranch) &&
                        checkBranch(
                          item.customer?.currentBranch.name ||
                            item.currentBranch?.name
                        )}

                      {column.includes("orderNumber") && item?.isApplication && (
                        <i className="fas fa-mobile-alt text-danger" />
                      )}
                      {/* if the key has "." it's means it is a nested like `item.chidl1.child2`  */}
                      {column.includes(".")
                        ? CheckLength(nestedObj(item, column))
                        : CheckLength(item[column])}
                    </span>
                  </th>
                ))}
                {/* {props.children ? <td>{passDataToChildren(item)}</td>: null} */}
              </tr>
            ))}
        </tbody>
        {/* </table> */}
        {/* </div> */}
      </Table>
    </>
  );
}

export default Tables;
