import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import customerApi from "api/customer";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import TicketApi from "../../api/TicketApi";

function PopupOldTicketTable({
  setTicket,
  setTableModal,
  setValue,
  sweetAlert,
  oldTicket = [],
}) {
  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [subject, setSubject] = useState("");
  const [shipmentNo, setShipmentNo] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const oldTickets = useApi(TicketApi.getOldTickets);
  const findByName = useApi(TicketApi.getByColumn);

  const getAlloldTickets = async () => {
    const schema = {
      ticketState: 3,
    };
    if (subject) schema.subject = subject;
    const res = await oldTickets.request(pageNo, docsPerPage, schema);

    if (res.status === 200) setData(res.data.data);
  };

//   const findOldTicketBySubject = async () => {
//     if (!subject.trim()) return getAlloldTickets();
//     const res = await oldTickets.request(pageNo, docsPerPage, subject);
//     if (res.status === 200) {
//       setData(res.data.data);
//     }
//   };

  const handleSelect = (obj) => {
    setTicket(obj);
    setTableModal(false);
    setValue("oldTicketId", obj.id);
    setValue("odticketSubject", obj.subject)
  };

  useEffect(() => {
    if (subject.trim().length) getAlloldTickets();
    else getAlloldTickets();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
      oldTickets.error && oldTickets.data && sweetAlert(oldTickets.data, true),
    [oldTickets.data]
  );

  return (
    <div>
      {oldTickets.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <div className="padding-around pb-0">
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="customer-code">
                    Old ticket subject
                  </label>
                  <Input
                    id="customer-code"
                    placeholder="search"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && getAlloldTickets()
                    }
                  />
                </FormGroup>
              </Col>
              <span style={{ paddingTop: 7 }}>
                <Button
                  color="success"
                  type="button"
                  className="mt-4"
                  onClick={getAlloldTickets}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-search" />
                  </span>
                  <span>Search</span>
                </Button>
              </span>
            </Row>
          </div>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={["actions", "subject", "description"]}
              columns={["subject", "description"]}
              data={data}
              setData={setData}
              handleSelect={handleSelect}
              isPrint={false}
            >
              <span className="me-1" fun="handleSelect">
                <div id="approve" className="table-action cursor-pointer">
                  <i className="fas fa-check fa-lg hover-info"></i>
                </div>
              </span>
            </Table>
          </Pagination>
        </>
      )}
    </div>
  );
}

export default PopupOldTicketTable;
