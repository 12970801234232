import React, { useEffect, useState, useContext } from "react";
//router
import { useParams, useLocation } from "react-router-dom";
// get culture from utils
import {
  Card,
  Container,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "../../hooks/useApi";
import shaheenPayApi from "api/ShaheenSp";
import Spinner from "../../components/Spinner";
import { useForm } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import Pagination from "components/common/Pagination";
import useAlert from "hooks/useAlert";
import SearchModal from "components/common/SearchModal";
import useSearchPopup from "hooks/useSearchPopup";
import usePagination from "hooks/usePagination";
import { PaginationContext } from "context/PaginationContext";
import InfoPopup from "./components/InfoPopup";
import PayPopup from "../ShaheenPay/components/PayPopup";
import NavPills from "components/common/NavPills";
import useAbility from "hooks/useAbility";
import content from "./content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import WaitingApprovingInfo from "./components/WaitingApprovingInfo";

const ShaheenSP = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const { shipmentType } = useParams();
  // const field = shipmentType.toLowerCase() === "air" ? "airCargo" : "lclCargo";
  const field = `${shipmentType.toLowerCase()}Cargo`;

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.date[culture],
    content.spCode[culture],
    content.orderId[culture],
    content.customerCode[culture],
    content.customerName[culture],
    content.usdAmount[culture],
    content.rmbAmount[culture],
    // "brokerRate",
    commenContent.rate[culture],
    // "country.countryName",
    content.feeDollar[culture],
    content.feeDiner[culture],
    commenContent.bankAccount[culture],
  ]);

  const [columns, setColumns] = useState([
    "createdOn",
    "spCode",
    "orderNumber",
    `customer.customerCode`,
    `customer.customerName`,
    "usD_Amount",
    "rmB_Amount",
    // "brokerRate",
    "rate",
    // "country.countryName",
    "feeDollar",
    "feeDinar",
    "bankAccount",
  ]);

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `shaheen-sp-${shipmentType}`;
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const paginationCtx = useContext(PaginationContext);
  const location = useLocation();

  const checkAbility = useAbility("Payment");

  // Apis
  const getAllSpsApi = useApi(shaheenPayApi.spShaheen);
  const getByColumn = useApi(shaheenPayApi.getByColumn);
  const amountApi = useApi(shaheenPayApi.amount);
  const payInvoice = useApi(shaheenPayApi.payInvoice);
  const cancelInvoiceApi = useApi(shaheenPayApi.cancelInvoice);
  const updateInvoiceApi = useApi(shaheenPayApi.updateInvoice);
  const updateApprovingToTransferingApi = useApi(
    shaheenPayApi.updateApprovingToTransfering
  );

  // States
  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [payModal, setPayModal] = useState(false);
  const [navPills, setNavPills] = useState(0);
  const [cancelModal, setCancelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [wpRows, setWPRows] = useState([]);
  const [wpInfoModal, setWPInfoModal] = useState(false);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const {
    handleSubmit: infoSubmit,
    formState: { errors: infoError },
    control: infoControler,
    setValue: infoSetValue,
    watch: infoWatch,
    reset: infoReset,
  } = useForm();

  const {
    handleSubmit: paySubmit,
    formState: { errors: payError },
    control: payControler,
    reset: resetPay,
    setValue: paySetValue,
  } = useForm();

  const {
    handleSubmit: wpInfoSubmit,
    formState: { errors: wpInfoError },
    control: wpInfoControler,
    reset: wpInfoReset,
    setValue: wpInfoSetValue,
    watch: wpInfoWatch,
  } = useForm();

  const editResponse = (item) => {
    const acronym =
      shipmentType.toLowerCase() === "air"
        ? "AC"
        : shipmentType.toLowerCase() === "lcl"
        ? "LC"
        : "FC";
    item.customer = item[field].customer;
    item.orderNumber = `${item[field]?.country?.countryCode}-${item[field]?.orderNumber}${acronym}`;

    item.createdOn = item.createdOn?.split("T")[0];
    item.spCode = ` SP-${item.spCode}-${acronym}`;
    return item;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  const updateData = (arr) => {
    const editedResponse = [...arr];
    setData((d) => ({ ...d, data: editedResponse }));
  };

  const getAllSps = async () => {
    const route = ["", "/waitingapproving", "/transferred", "/canceled"];

    const res = await getAllSpsApi.request(
      shipmentType,
      pageNo,
      docsPerPage,
      route[navPills]
    );

    if (res.status === 200) {
      res.data.data.data.forEach((item) => editResponse(item));
      setData(res.data.data);

      // if the prev page is not the same as this one clear the context
      if (!prevPageCheck()) paginationCtx.resetPagination();
    } else {
      setData([]);
    }
  };

  const handleCancel = () => {
    setRowId("");
    setInfoModal(false);
    setPayModal(false);
    setCancelModal(false);
    setUpdateModal(false);
    setWPInfoModal(false);
    resetPay();
    infoReset();
  };

  const handlePaymentInfo = (obj) => {
    setRowId(obj.id);
    setOrderNumber(obj.orderNumber.match(/(\d+)/)[0]);
    infoSetValue("orderNumberShown", obj.orderNumberShown);
    infoSetValue("rmB_Amount", obj.rmB_Amount);
    infoSetValue("spCode", obj.spCode);
    // infoSetValue("brokerId", obj.brokerId || "");
    // infoSetValue("bankAccount", obj.bankAccount || "");
    // infoSetValue("bankAccount", obj.usD_Amount || 0);
    infoSetValue("rate", obj.rate || 0);
    infoSetValue("bankAccount", obj[field].bankAccount);
    infoSetValue("customerName", obj.customer?.customerName);
    infoSetValue("customerCode", obj.customer?.customerCode);
    // infoSetValue("brokerRate", obj.brokerRate || 0);
    setInfoModal(true);
  };

  const handleInfo = async (formData) => {
    setInfoModal(false);
    setSpinner(true);
    const orderNumber = data.data.find(
      (item) => item.id === rowId
    )?.orderNumber;

    const schema = {
      spCode: +formData.spCode.match(/(\d+)/)[0],
      rmB_Amount: formData.rmB_Amount,
      usD_Amount: +formData.usD_Amount,
      rate: +formData.rate,
      orderNumber: +orderNumber.match(/(\d+)/)[0],
      // brokerRate: +formData.brokerRate
    };

    if (formData.brokerId) schema.brokerId = formData.brokerId;
    if (formData.bankAccount) schema.bankAccount = formData.bankAccount;

    const res = await amountApi.request(rowId, schema, shipmentType);

    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      editResponse(res.data.data);
      const newData = { ...data };
      const index = newData.data.findIndex((item) => item.id === rowId);
      newData.data[index] = { ...res.data.data };
    }
    setSpinner(false);
    infoReset();
  };

  const handlePay = (obj) => {
    setRowId(obj[field].id);
    paySetValue("id", obj[field]?.id);
    setOrderNumber(+obj.orderNumber.match(/(\d+)/)[0]);
    setPayModal(true);
  };

  const cancelClicked = (obj) => {
    setRowId(obj.id);
    setCancelModal(true);
  };

  const handleUpdate = (obj) => {
    setRowId(obj.id);
    setUpdateModal(true);
  };

  const handleCancelRow = async () => {
    setSpinner(true);
    setCancelModal(false);
    const res = await cancelInvoiceApi.request(rowId, shipmentType);
    if (res.status === 200) {
      const newData = [...data.data].filter((item) => item.id !== rowId);
      setData((d) => ({ ...d, data: newData }));
      sweetAlert(commenContent.done[culture]);
    }

    setSpinner(false);
  };

  const handleUpdateRow = async () => {
    setSpinner(true);
    setUpdateModal(false);
    const res = await updateInvoiceApi.request(rowId, shipmentType);
    if (res.status === 200) {
      const newData = [...data.data].filter((item) => item.id !== rowId);
      setData((d) => ({ ...d, data: newData }));
      sweetAlert(commenContent.done[culture]);
    }

    setSpinner(false);
  };

  const payFunctionality = async (formData) => {
    setSpinner(true);
    setPayModal(false);
    const schema = {
      ...formData,
      rmB_Amount: +formData.rmB_Amount,
      orderNumber,
      // cargoType: +shipmentType,
    };

    const res = await payInvoice.request(schema, shipmentType);

    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      editResponse(res.data.data);
      // setData((d) => ({ ...d, data: [res.data.data, ...d.data] }));
      getAllSps();
    }
    resetPay();
    setOrderNumber("");

    setRowId("");
    setSpinner(false);
  };

  const links = [
    content.initiated[culture],
    content.waitingApproving[culture],
    content.transferred[culture],
    commenContent.canceled[culture],
  ];

  const renderPaymentInfo = () => navPills === 0 && checkAbility("amount");
  const renderPay = () => navPills === 0 && checkAbility("create");
  const renderCancel = () =>
    [0, 1].includes(navPills) && checkAbility("cancel");

  // Render update waiting approving
  const renderUpdateWP = (obj) => {

    if (new Date(obj[field]?.createdOn) < new Date("2023-11-20"))
      return false;
    if (!!!obj.entryId) return false;
    const index = wpRows.findIndex((row) => row.spCode === obj.spCode);

    if (index > -1) return false;
    // const index = wpRows.find(row => row.orderNumber)
    if (navPills === 1 && checkAbility("update")) return true;
    return false;
  };
  // navPills === 1 && checkAbility("update") && obj.entryId;

  const renderUpdate = (obj) => {
    if (
      navPills === 1 &&
      new Date(obj[field].createdOn) >= new Date("2023-11-20")
    )
      return false;

    if ([0, 1].includes(navPills) && checkAbility("update")) return true;
    // if (navPills === 1 && checkAbility("update") && obj.entryId) return true;
    return false;
  };

  const handleUpdateWP = (obj) => {
    setRowId(obj.id);
    setOrderNumber(obj.orderNumber.match(/(\d+)/)[0]);
    wpInfoSetValue("id", obj.id);
    wpInfoSetValue("orderNumberShown", obj.orderNumberShown);
    wpInfoSetValue("rmB_Amount", obj.rmB_Amount);
    wpInfoSetValue("spCode", obj.spCode);
    wpInfoSetValue("brokerId", obj.brokerId || "");
    // wpInfoSetValue("bankAccount", obj.bankAccount || "");
    // wpInfoSetValue("bankAccount", obj.usD_Amount || 0);
    // wpInfoSetValue("bankAccount", obj[field].bankAccount);
    wpInfoSetValue("customerName", obj.customer?.customerName);
    wpInfoSetValue("customerCode", obj.customer?.customerCode);
    wpInfoSetValue("brokerId", wpRows[0]?.brokerId);
    wpInfoSetValue("currency", obj.currency);
    setWPInfoModal(true);
  };

  const addWPInfo = (d) => {
    setWPRows((prevVal) => [...prevVal, d]);
    wpInfoReset();
    setWPInfoModal(false);
  };

  const renderWpCancel = (obj) =>
    wpRows.findIndex((row) => row.id === obj.id) > -1;

  const handleCancelWP = (obj) => {
    setWPRows((prevVal) => prevVal.filter((row) => row.spCode !== obj.spCode));
  };

  const handleApprovingToTransfering = async () => {
    setSpinner(true);
    const res = await updateApprovingToTransferingApi.request(
      wpRows,
      shipmentType
    );
    if (res.status === 200) {
      setWPRows([]);
      setData((prevData) => {
        const newData = prevData.data.filter((item) => {
          return !wpRows.some((selected) => selected.id === item.id);
        });
        return {
          ...prevData,
          data: newData,
        };
      });
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  useEffect(() => {
    // if search popup has a values and it's the same page stored in the context
    // don't run these request
    // and go to the other useEffect
    if (searchPopup.isSelected && searchPopup.searchField && prevPageCheck())
      return;
    if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    )
      return;

    // else
    getAllSps();
  }, [pageNo, docsPerPage, location, navPills]);

  useEffect(() => {
    if (
      searchPopup.isSelected &&
      searchPopup.searchField &&
      searchPopup.openPopup
    ) {
      searchPopup.onSearch("", () => {});
    } else if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch("", (data) => {
        updateData(data);
      });
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, searchPopup.isSelected]);
  if (getAllSpsApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {getAllSpsApi.errorAlert}
      {amountApi.errorAlert}
      {amountApi.errorAlert}
      {cancelInvoiceApi.errorAlert}
      {updateInvoiceApi.errorAlert}
      {payInvoice.errorAlert}
      {updateApprovingToTransferingApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      <>
        <SimpleHeader
          parents={[
            `${content.shaheenSP[culture]} ${" "} ${
              content[shipmentType.toLowerCase()][culture]
            }`,
          ]}
        />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <h3 className={`mb-0`}>
                {content.shaheenSP[culture]}{" "}
                {content[shipmentType.toLowerCase()][culture]}
              </h3>
            </CardHeader>
            <CardBody>
              <NavPills
                links={links}
                navPills={navPills}
                setNavPills={setNavPills}
              />
              {!!wpRows.length && (
                <Button
                  className="mt-5"
                  color="success"
                  // outline
                  size="md"
                  onClick={handleApprovingToTransfering}
                >
                  <span className="btn-inner--icon me-1">
                    <i className="far fa-arrow-alt-circle-down" />
                  </span>
                  <span>{commenContent.save[culture]}</span>
                </Button>
              )}
            </CardBody>
            <Pagination
              dataLength={data.data?.length}
              // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
              // when the user search using the popup and select a row
              // after then only use the pagination states from searchPopup hook
              pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
              setPageNo={
                searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
              }
              docsPerPage={
                searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
              }
              setDocsPerPage={
                searchPopup.isSelected
                  ? searchPopup.setDocsPerPage
                  : setDocsPerPage
              }
            >
              <Table
                pageName={pageName}
                headers={headers}
                columns={columns}
                setHeaders={setHeaders}
                setColumns={setColumns}
                // when the user search using the popup and select a row
                // after then only use the hookData states from searchPopup hook
                data={data.data}
                setData={(sortedData) =>
                  setData((d) => ({ ...d, data: sortedData }))
                }
                handlePaymentInfo={handlePaymentInfo}
                handlePay={handlePay}
                cancelClicked={cancelClicked}
                renderCancel={renderCancel}
                renderUpdate={renderUpdate}
                renderUpdateWP={renderUpdateWP}
                handleUpdate={handleUpdate}
                renderPay={renderPay}
                renderPaymentInfo={renderPaymentInfo}
                renderWpCancel={renderWpCancel}
                handleUpdateWP={handleUpdateWP}
                handleCancelWP={handleCancelWP}
                onHeaderClick={searchPopup.handleHeaderClicked}
                // when the user was searching useing the popup and select a row
                // store the popup pagination in the context
                // otherwise store the normal pagination in the context
                setPagination={
                  searchPopup.isSelected
                    ? (rowId) =>
                        paginationCtx.setPagination(
                          searchPopup.pageNo,
                          searchPopup.docsPerPage,
                          rowId,
                          pageName
                        )
                    : (rowId) =>
                        paginationCtx.setPagination(
                          pageNo,
                          docsPerPage,
                          rowId,
                          pageName
                        )
                }
              >
                <span
                  className="me-1"
                  condition="renderPaymentInfo"
                  fun="handlePaymentInfo"
                >
                  <div id="info" className="table-action cursor-pointer">
                    <i className="fas fa-money-check-alt hover-primary" />
                  </div>
                  <UncontrolledTooltip delay={0} target="info">
                    {content.paymentInfo[culture]}
                  </UncontrolledTooltip>
                </span>
                <span className="me-1" condition="renderPay" fun="handlePay">
                  <div id="pay" className="table-action cursor-pointer">
                    <i className="fas fa-plus fa-lg hover-success" />
                  </div>
                  <UncontrolledTooltip delay={0} target="pay">
                    {content.additionalPay[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  className="me-1"
                  fun="cancelClicked"
                  condition="renderCancel"
                >
                  <div id="cancel" className="table-action cursor-pointer">
                    <i className="fas fa-ban fa-lg hover-warning" />
                  </div>
                  <UncontrolledTooltip delay={0} target="cancel">
                    {commenContent.cancel[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  className="me-1"
                  fun="handleUpdate"
                  condition="renderUpdate"
                >
                  <div
                    id="update-state"
                    className="table-action cursor-pointer"
                  >
                    <i className="fas fa-arrow-alt-circle-up fa-lg hover-info" />
                  </div>
                  <UncontrolledTooltip delay={0} target="update-state">
                    {commenContent.updateState[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  className="me-1"
                  condition="renderUpdateWP"
                  fun="handleUpdateWP"
                >
                  <div id="pay-wp" className="table-action cursor-pointer">
                    <i className="fas fa-check-square fa-lg hover-success" />
                  </div>
                  <UncontrolledTooltip delay={0} target="pay-wp">
                    {commenContent.updateState[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  className="me-1"
                  condition="renderWpCancel"
                  fun="handleCancelWP"
                >
                  <div
                    id="cancel-update"
                    className="table-action cursor-pointer"
                  >
                    <i className="fas fa-times fa-lg hover-danger" />
                  </div>
                  <UncontrolledTooltip delay={0} target="cancel-update">
                    {content.cancelUpdate[culture]}
                  </UncontrolledTooltip>
                </span>
              </Table>
            </Pagination>
            <TextPopup
              modal={infoModal}
              text={commenContent.confirm[culture]}
              handleCancel={handleCancel}
              // fn={editAirCargo}
              color="primary"
              title="Payment info"
              fn={infoSubmit(handleInfo)}
            >
              <Container>
                <InfoPopup
                  control={infoControler}
                  errors={infoError}
                  watch={infoWatch}
                  orderNumber={orderNumber}
                  setValue={infoSetValue}
                  brokers={data.brokers}
                />
              </Container>
            </TextPopup>
            <TextPopup
              modal={payModal}
              text={commenContent.confirm[culture]}
              handleCancel={handleCancel}
              // fn={editAirCargo}
              color="success"
              // disabled={!formIsEdited && !editSchema?.imgs}
              fn={paySubmit(payFunctionality)}
            >
              <Container>
                <PayPopup
                  control={payControler}
                  errors={payError}
                  orderNumber={orderNumber}
                  rowId={rowId}
                  setValue={paySetValue}
                />
              </Container>
            </TextPopup>
            <TextPopup
              modal={cancelModal}
              text={commenContent.confirm[culture]}
              handleCancel={handleCancel}
              // fn={editAirCargo}
              color="warning"
              // disabled={!formIsEdited && !editSchema?.imgs}
              fn={handleCancelRow}
              name={data.data?.find((item) => item.id === rowId)?.spCode}
            >
              <Container>
                <h2>{commenContent.deleteInvoice[culture]}</h2>
              </Container>
            </TextPopup>
            <TextPopup
              modal={updateModal}
              text={commenContent.confirm[culture]}
              handleCancel={handleCancel}
              // fn={editAirCargo}
              color="primary"
              // disabled={!formIsEdited && !editSchema?.imgs}
              fn={handleUpdateRow}
              name={data.data?.find((item) => item.id === rowId)?.spCode}
            >
              <Container>
                <h2>{commenContent.updateInvoice[culture]}</h2>
              </Container>
            </TextPopup>
            <TextPopup
              modal={wpInfoModal}
              text={commenContent.confirm[culture]}
              handleCancel={handleCancel}
              // fn={editAirCargo}
              color="success"
              // disabled={!formIsEdited && !editSchema?.imgs}
              fn={wpInfoSubmit(addWPInfo)}
            >
              <Container>
                <WaitingApprovingInfo
                  selectedBroker={wpRows[0]?.brokerId}
                  control={wpInfoControler}
                  errors={wpInfoError}
                  rowId={rowId}
                  watch={wpInfoWatch}
                  setValue={wpInfoSetValue}
                  brokers={data.brokers}
                />
              </Container>
            </TextPopup>
          </Card>
        </Container>
        {searchPopup.openPopup && (
          <SearchModal
            data={data.data}
            setData={setData}
            updateData={updateData}
            searchPopup={searchPopup}
            editResponse={editResponse}
            popupSearchCtx={paginationCtx.popupSearch}
            extraField={{ header: "order id", column: "orderNumber" }}
          />
        )}
      </>
    </div>
  );
};

export default ShaheenSP;
