import client from "./client";

const endpoint = "/v1/PkgsBulks";

const packages = (shipmentType, number, code, page, docPerPage) =>
  client.get(
    `${endpoint}?cargoType=${shipmentType}&number=${number}&CountryCode=${code}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const updatePackage = (shipmentType, id, schema) =>
  client.put(`${endpoint}/${id}?cargoType=${shipmentType}`, schema);

const printCustomer = (shipmentType, id, customerCode) =>
  client.get(`${endpoint}/print${shipmentType}?id=${id}&code=${customerCode}`);

const paid = (shipmentType, id) =>
  client.put(`${endpoint}/paid/${id}?cargoType=${shipmentType}`);

const searchByCode = (shipmentType, id, code) =>
  client.put(
    `${endpoint}/SearcByCode${shipmentType}/${id}?CustomerCode=${code}&Page=1&PostsPerPage=5`
  );

export default {
  packages,
  updatePackage,
  printCustomer,
  paid,
  searchByCode
};
