const { useRouteMatch } = require("react-router");
const { default: getCulture } = require("utils/getCulture");

const useCulture = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  return culture;
}


export default useCulture;