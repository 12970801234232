import warehouse from "api/warehouse";
import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  Row,
  Input,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "./content";
import useCulture from "hooks/useCulture";
import commonContent from "components/common/content";
import DocsCard from "components/common/DocsCard";
import Gallery from "components/common/Gallery";

function WarehouseDetails() {
  const { id } = useParams();
  const culture = useCulture();
  const getOneApi = useApi(warehouse.getOne);

  const [data, setData] = useState({});

  const fetchOne = async () => {
    const res = await getOneApi.request(id);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  useEffect(() => {
    fetchOne();
  }, [id]);
  return (
    <div>
      {getOneApi.errorAlert}
      {getOneApi.loading && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.warehouse[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.warehouse[culture]}
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="title">
                    {content.title[culture]}
                  </label>
                  <Input
                    readOnly
                    id="title"
                    placeholder={content.title[culture]}
                    type="text"
                    value={data.title ?? ""}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="country">
                    {commonContent.country[culture]}
                  </label>
                  <Input
                    readOnly
                    id="country"
                    type="text"
                    value={data.country?.name ?? ""}
                    placeholder={`-- ${commonContent.selectOption[culture]} --`}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="city">
                    {content.city[culture]}
                  </label>
                  <Input
                    readOnly
                    id="name"
                    placeholder={content.city[culture]}
                    type="text"
                    value={data.city ?? ""}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="note">
                    {content.note[culture]}
                  </label>
                  <Input
                    readOnly
                    id="note"
                    placeholder={content.note[culture]}
                    type="textarea"
                    style={{ height: "150px" }}
                    value={data.note ?? ""}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
        <Gallery title={commonContent.images[culture]} imgs={data.image ? [data.image] : []} />
    </div>
  );
}

export default WarehouseDetails;
