import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import branch from "api/branch";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import EditBranch from "./components/EditWarehouse";
import useAbility from "hooks/useAbility";
import commonContent from "components/common/content";
import content from "./content";
import EditWarehouse from "./components/EditWarehouse";
import CountryTable from "components/common/CountryTable";
import warehouse from "api/warehouse";
import FilePicker from "components/common/FilePicker";

const Warehouses = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const headers = [
    commonContent.actions[culture],
    content.title[culture],
    commonContent.country[culture],
    content.city[culture],
    content.note[culture],
  ];
  const columns = ["title", "country.name", "city", "note"];

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const [images, setImages] = useState({});
  const [editImage, setEditImage] = useState({});

  const checkAbility = useAbility("Branch");
  const { alert, sweetAlert } = useAlert();

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const getAllApi = useApi(warehouse.getAll);
  const createOneApi = useApi(warehouse.createOne);
  const getByColumn = useApi(warehouse.getByColumn);
  const deleteOneApi = useApi(warehouse.deleteOne);
  const updateOneApi = useApi(warehouse.updateOne);

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    (item) => item
  );

  const createOneHandler = async (formData) => {
    setSpinner(true);
    const schema = new FormData();
    schema.append("url", images[0]);
    Object.keys(formData).forEach((key) => {
      schema.append(key, formData[key]);
    });
    const res = await createOneApi.request(schema);
    if (res.status === 200) {
      setData((d) => [res.data.data, ...d]);
      sweetAlert("Done");
      reset();
    }
    setSpinner(false);
  };

  const getBranches = async () => {
    const res = await getAllApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    setData(arr);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteOneApi.request(rowId);
    if (res.status === 204) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("title", obj.title);
    editSetValue("city", obj.city);
    editSetValue("country", obj.country?.name ?? "");
    editSetValue("countryId", obj.country?.id ?? "");
    editSetValue("note", obj.note);
    setEditModal(true);
  };

  const editWarehouseHandler = async (formData) => {
    setEditModal(false);
    setEditImage({});
    setSpinner(true);
    const schema = new FormData();
    schema.append("url", editImage?.[0] ?? null);
    Object.keys(formData).forEach((key) => {
      schema.append(key, formData[key]);
    });
    const res = await updateOneApi.request(rowId, schema);

    if (res.status === 200) {
      const newData = [...data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      newData[rowIndex] = res.data.data;
      setData(newData);
      sweetAlert("Done");
    }
    setRowId("");
    setSpinner(false);
  };

  const editRenderer = () => checkAbility("edit");

  const deleteRenderer = () => checkAbility("delete");

  useEffect(() => {
    getBranches();
  }, [pageNo, docsPerPage]);

  if (getAllApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {getAllApi.errorAlert}
      {createOneApi.errorAlert}
      {deleteOneApi.errorAlert}
      {updateOneApi.errorAlert}
      {getByColumn.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.warehouse[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.warehouse[culture]}
              </h3>
            </div>
          </CardHeader>
          {checkAbility("create") && (
            <CardBody>
              <form onSubmit={handleSubmit(createOneHandler)}>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="title">
                        {content.title[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="title"
                        rules={{
                          required: "name field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="title"
                            placeholder={content.title[culture]}
                            type="text"
                            className={errors.title && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="country">
                        {commonContent.country[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: "Country feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="country"
                            type="text"
                            className={errors.country && "error"}
                            value={value || ""}
                            placeholder={`-- ${commonContent.selectOption[culture]} --`}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => setCountryTable(true)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="city">
                        {content.city[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="city"
                        rules={{
                          required: "city field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            placeholder={content.city[culture]}
                            type="text"
                            className={errors.city && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="note">
                        {content.note[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="note"
                        rules={{
                          required: "note field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="note"
                            placeholder={content.note[culture]}
                            type="textarea"
                            className={errors.note && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FilePicker
                  images={images}
                  setImages={setImages}
                  multiple={false}
                />
                <Button
                  color="success"
                  type="submit"
                  disabled={Object.keys(images).length === 0}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commonContent.create[culture]}</span>
                </Button>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName="warehouses"
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              editRenderer={editRenderer}
              deleteRenderer={deleteRenderer}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              <span to={`/${culture}-admin/settings/warehouses`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-primary" />
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleEdit" className="me-1" condition="editRenderer">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleDelete"
                condition="deleteRenderer"
              >
                <div id="delete" className="table-action cursor-pointer">
                  <i className="fas fa-trash hover-danger fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commonContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editWarehouseHandler)}
        color="primary"
      >
        <Container>
          <EditWarehouse
            control={editControl}
            errors={editErrors}
            editImage={editImage}
            setEditImage={setEditImage}
            setValue={editSetValue}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.yes[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={(item) => item}
          // popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: content.title[culture], column: "title" }}
        />
      )}
    </div>
  );
};

export default Warehouses;
