import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Container } from "reactstrap";
import { Controller } from "react-hook-form";
import env from "env";
import { useParams } from "react-router";
import useApi from "hooks/useApi";
import InvoiceApi from "api/invoice";
import Spinner from "../../../components/Spinner";
import { useEffect } from "react";
import content from "../content";
import commenContent from "components/common/content";
import TextPopup from "components/common/TextModal";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import CostCenterTable from "components/common/CostCenterTable";
import useAlert from "hooks/useAlert";

const PayPopup = ({ control, errors, orderNumber, rowId, setValue }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const { alert, sweetAlert } = useAlert();

  const { shipmentType } = useParams();
  const [yuan, setYuan] = useState("");
  const [costCenterModal, setCostCenterModal] = useState(false);

  // const shippingType = shipmentType === "air" ? "aircargo" : "lclcargo";

  const type = () => {
    if (shipmentType.toLowerCase() === "air") {
      return "AirCargo";
    } else if (shipmentType.toLowerCase() === "lcl") {
      return "LCLCargo";
    } else return "FCL";
  };

  const shippingType = type();

  const field = `${shipmentType}Cargo`;

  const getByColumn = useApi(InvoiceApi.getByColumn);
  const getInvoiceById = useApi(InvoiceApi.getInvoice);

  const getInvoice = async () => {
    // const res = await getByColumn.request("orderNumber", orderNumber, 1, 1, shippingType);
    const res = await getInvoiceById.request(rowId, shippingType);

    if (res.status === 200) {
      setYuan(res.data.data?.[field]?.valueIwan || res.data.data?.valueIwan);
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  if (getByColumn.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      <Row>
        {getByColumn.errorAlert}
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="rmb_amount">
              {content.rmbAmount[culture]}
            </label>
            <Controller
              control={control}
              name="rmB_Amount"
              rules={{
                required: "rmb_amount field is required",
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="rmb_amount"
                  type="number"
                  // min={0}
                  className={errors.rmB_Amount && "error"}
                  value={value || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="rmb_amount">
              {content.currency[culture]}
            </label>
            <Controller
              control={control}
              name="currency"
              rules={{ required: "Client currency field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="currency"
                  type="select"
                  // min={0}
                  className={errors.currency && "error"}
                  value={value || "disabled"}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(+value)}
                >
                  <option disabled value="disabled">
                    {`-- ${commenContent.selectOption[culture]} --`}
                  </option>
                  {env.currency.map((item, index) => (
                    <option key={item[culture]} value={index + 1}>
                      {item[culture]}
                    </option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="yuan">
              {content.yuanValue[culture]}
            </label>
            <Input
              id="yuan"
              type="number"
              // min={0}
              value={yuan || ""}
              readOnly
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="costCenter">
              {commenContent.costCenter[culture]}
            </label>
            <Controller
              control={control}
              name="costCenter"
              rules={{ required: "costCenter feild is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="costCenter"
                  placeholder={`-- ${commenContent.costCenter[culture]} --`}
                  type="text"
                  className={errors.costCenter && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCostCenterModal(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <TextPopup
        modal={costCenterModal}
        text={commenContent.next[culture]}
        handleCancel={() => setCostCenterModal(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CostCenterTable
            // setCountry={setCountry}
            setModal={setCostCenterModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </>
  );
};

export default PayPopup;
