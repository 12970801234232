import client from "./client";

const endpoint = "/v1/country";

const preload = (page, docPerPage) =>
  client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const countries = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const createCountry = (schema) => client.post(`${endpoint}`, schema);

const findByName = (name, page, docPerPage) =>
  client.get(
    `${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const deleteCountry = (id) => client.delete(`${endpoint}/${id}`);

const updateCountry = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const activateCountry = (id) => client.put(`${endpoint}/active/${id}`);

const deactivateCountry = (id) => client.put(`${endpoint}/deactive/${id}`);

// This api for filter and has no permission
const getByPopupCoulum = (col, val, page, docsPerPage) =>
  client.put(
    `${endpoint}/GetByPopupCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

  const getUserCountryCoulum = (col, val, page, docsPerPage) =>
  client.put(
    `${endpoint}/getUserCountryCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );


export default {
  preload,
  countries,
  createCountry,
  findByName,
  getByColumn,
  deleteCountry,
  updateCountry,
  activateCountry,
  getByPopupCoulum,
  deactivateCountry,
  getUserCountryCoulum
};
