import { useState } from "react"
import ReactBSAlert from "react-bootstrap-sweetalert";
import commenContent from "components/common/content";
import useCulture from "./useCulture";

const useAlert = () => {

  const culture = useCulture();
  const [alert, setAlert] = useState(null);

  // set sweet alert
  const sweetAlert = (text, warning = false, fn) => {

    const handleConfrim = () => {
      setAlert(false);
      if (fn) fn();
    }

    setAlert(
      <ReactBSAlert
        show={true}
        showCancel={!!fn}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={handleConfrim}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText={commenContent.ok[culture]}
        cancelBtnText={commenContent.cancel[culture]}
        cancelBtnBsStyle="secondary"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    ); 
  }

  return { alert, sweetAlert };
}

export default useAlert;