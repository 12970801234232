import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Container } from "reactstrap";
import CountryTable from "components/common/CountryTable";
import { Controller } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import commonContent from "components/common/content";

const EditInvoice = ({ control, errors, setValue }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  // Use form hook
  const [countryTable, setCountryTable] = useState(false);
  const { alert, sweetAlert } = useAlert();

  return (
    <div>
      {alert}
      <Row>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="value">
              {commonContent.supplyContact[culture]}
            </label>
            <Controller
              control={control}
              name="supplierContact"
              rules={{ required: "supplier field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="supplierContact"
                  type="text"
                  min={0}
                  className={errors.supplierContact && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="country">
              {commonContent.country[culture]}
            </label>
            <Controller
              control={control}
              name="country"
              rules={{ required: "Country feild is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="country"
                  type="text"
                  className={errors.country && "error"}
                  value={value || ""}
                  placeholder={`-- ${commonContent.selectAnOption[culture]} --`}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCountryTable(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="bankAccount">
              {commonContent.bankAccount[culture]}
            </label>
            <Controller
              control={control}
              name="bankAccount"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="bankAccount"
                  type="text"
                  min={0}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={() => setCountryTable(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </div>
  );
};

export default EditInvoice;
