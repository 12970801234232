export default {
  news: {
    en: "News Settings",
    ar: "اعدادات الاخبار",
  },
  title: {
    en: "Title",
    ar: "العنوان",
  },
  description: {
    en: "Description",
    ar: "التفاصيل",
  },
  date: {
    en: "Date",
    ar: "التاريخ",
  },
  image: {
    en: "Image",
    ar: "صورة",
  },
  select: {
    en: "Select file",
    ar: "اختبر الملف",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  create: {
    ar: "انشاء",
    en: "Create",
  },
  deactivate: {
    ar: "تعطيل",
    en: "Deactivate",
  },
  deactivateModal: {
    ar: "انت على وشك تعطيل هذا المنشور هل تريد الاستمرار ؟",
    en: "You are about to deactivate this post Do you want to continue ?"
  },
};
