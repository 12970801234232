export default {
    header: {
      ar: "العنوان",
      en: "Header"
    },
    template: {
      ar: "الواجهة",
      en: "Template"
    },
    subText: {
      ar: "المحتوى الفرعي",
      en: "Sub text"
    },
    arHeader: {
      ar: "العنوان بالعربية",
      en: "Ar Header"
    },
    arSubText: {
      ar: "المحتوى الفرعي بالعربية",
      en: "Ar sub text"
    },
    bg: {
      ar: "صورة خلفية",
      en: "background picture"
    },
    headerContent: {
      ar: "محتوى قسم العنوان",
      en: "Header content"
    },
    save: {
      ar: "حفظ",
      en: "Save"
    },
    explore: {
      ar: "اكتشفنا",
      en: "Explore"
    },
    addCard: {
      ar: "اضافة بطاقة",
      en: "Add card"
    },
    deleteSubText: {
      ar: "حذف النص الفرعي",
      en: "Delete sub text"
    },
    addImage: {
      ar: "اضافة صورة",
      en: "Add image"
    },
    cardPic: {
      ar: "صورة خلفية البطاقة",
      en: "Card background picture"
    },
    explore: {
      ar: "اكتشفنا",
      en: "Explore"
    },
    info: {
      ar: "معلومات",
      en: "Info"
    },
    phone: {
      ar: "رقم الهاتف",
      en: "Phone"
    },
    email: {
      ar: "البريد الالكتروني",
      en: "Email"
    },
    location: {
      ar: "الموقع",
      en: "Location"
    },
    aboutUs: {
      ar: "عنا",
      en: "About us"
    },
    airPlan: {
      ar: "الاسعار الجوية",
      en: "Air plan"
    },
    seaPlan: {
      ar: "الاسعار البحرية",
      en: "Sea plan"
    },
    environment: {
      ar: "بيئة العمل",
      en: "Our environment"
    }
}