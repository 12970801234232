import client from "./client";

const endpoint = "/v1/Payment";

const getAllPays = (shipmentType, page, docsPerPage) =>
  client.get(
    `${endpoint}?cargoType=${shipmentType}&Page=${page}&postPage=${docsPerPage}`
  );

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getByColumnPayAir = (col, val, page, docsPerPage, shipmentType) =>  
  client.get(
    `${endpoint}/getbycoulumPayAir?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  )
const getByColumnPayLcl = (col, val, page, docsPerPage, shipmentType) =>  
  client.get(
    `${endpoint}/getbycoulumPayLcl?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  )

const payInvoice = (schema) => client.post(`${endpoint}`, schema);

const spShaheen = (shipmentType, pageNo, docsPerPage, route = "") =>
  client.get(
    `${endpoint}/${route}?cargoType=${shipmentType}&page=${pageNo}&postPage=${docsPerPage}`
  );

const amount = (id, schema) => client.put(`${endpoint}/amount/${id}`, schema);

const cancelInvoice = id => client.put(`${endpoint}/cancel/${id}`);

const updateInvoice = id => client.put(`${endpoint}/update/${id}`);

const getByOrderNumber = (shipmentType, orderNo) =>
  client.get(`/v1/${shipmentType}/GetByOrder?value=${orderNo}`);

export default {
  getAllPays,
  getByColumn,
  payInvoice,
  spShaheen,
  amount,
  cancelInvoice,
  updateInvoice,
  getByOrderNumber,
  getByColumnPayAir,
  getByColumnPayLcl
};
