import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import { useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import { useForm, Controller } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import Gallery from "components/common/Gallery";
import useApi from "hooks/useApi";
import bulks from "api/bulks";
import Spinner from "../../../components/Spinner";
import CustomerPopup from "../components/CustomerPopup";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import FilePicker from "components/common/FilePicker";
import DeleteCustomerBulk from "../components/DeleteCustomerBulk";
import useAbility from "hooks/useAbility";
import commonContent from "components/common/content";
import content from "./content";
import env from "env";
import BulkStation from "../components/BulkStation";
import TreasuryTable from "components/common/TreasuryTable";

const BulkDetails = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  // params
  const { shipmentType, id } = useParams();

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  const checkAbility = useAbility(
    `${shipmentType.toLowerCase() === "aircargo" ? "AirCargoBulk" : "LCLBulk"}`
  );

  //Apis
  const bulkDetails = useApi(bulks.bulkDetails);
  const uploadImages = useApi(bulks.uploadImg);
  const updateBulkArrivalDate = useApi(bulks.updateBulkArrivalDate);
  const arrivedBulk = useApi(bulks.arrivedBulk);
  const linkOrderApi = useApi(bulks.linkOrder);
  const deleteOrdersApi = useApi(bulks.deleteOrders);

  // states
  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [viewOrdersModal, setViewOrdersModal] = useState(false);
  const [imgs, setImgs] = useState({});
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalDateModal, setArrivalDateModal] = useState(false);
  const [arrivedModal, setArrivedModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [tableModal, setTableModal] = useState(false);
  const [deleteBulk, setDeleteBulk] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const postClient = clients.map((client) => {
    const newClient = {
      id: client.id,
      followUpStatus: client.followUpStatus,
      actualNoPackg: client.actualNoPackg || client.numberOfPkgs,
      orderNumber: client.orderNumber,
      cus_Code: client.cus_Code,
    };

    if (shipmentType.toLowerCase() === "aircargo") {
      newClient.actualWeight = client.actualWeight || client.weight;
    } else {
      newClient.actualVolume = client.actualVolume || client.volume;
    }

    return newClient;
  });

  const numberOfPkgs = clients.reduce((total, current) => {
    if (current.actualNoPackg) return current.actualNoPackg + total;
    return current.numberOfPkgs + total;
  }, 0);

  setValue("numberOfPkgs", numberOfPkgs);

  const totalWeight =
    clients.reduce((total, current) => {
      if (current.actualWeight) return current.actualWeight + total;
      return current.weight + total;
    }, 0) || 0;

  setValue("weight", totalWeight);

  const totalVolume =
    clients.reduce((total, current) => {
      if (current.actualVolume) return current.actualVolume + total;
      return current.volume + total;
    }, 0) || 0;

  setValue("volume", totalVolume);

  const getBulkDetails = async () => {
    const response = await bulkDetails.request(shipmentType, id);

    if (response.ok) {
      response.data.data.bulkPckgNumShown = `B-${response.data?.data?.bulkPckgNum}-${response.data?.data?.country?.countryCode}`;
      setData(response.data.data);
    } else {
      sweetAlert(bulkDetails.data, true);
    }
  };

  const arrivalDataHandler = (e) => {
    e.preventDefault();
    setArrivalDateModal(true);
  };

  const handleArrived = async () => {
    setArrivedModal(false);
    setSpinner(true);
    const res = await arrivedBulk.request(shipmentType, id);

    if (res.ok) {
      setData({
        ...data,
        followUpStatus: 4,
      });
      sweetAlert("Done");
    }

    setSpinner(false);
  };

  const handleUpdate = async () => {
    setArrivalDateModal(false);
    setSpinner(true);
    const res = await updateBulkArrivalDate.request(
      shipmentType,
      id,
      arrivalDate
    );

    if (res.ok) {
      setData({
        ...data,
        arrivalDate: `${arrivalDate}T00:00:00`,
      });
      setArrivalDate("");
      sweetAlert("Done");
    }

    setSpinner(false);
  };

  const handleUploadImages = async (e) => {
    e.preventDefault();
    setSpinner(true);
    // const imgs = imgs;
    const response = await uploadImages.request(shipmentType, id, imgs);

    if (response.ok) {
      setImgs({});
      sweetAlert("Done");
      getBulkDetails();
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setViewOrdersModal(false);
    setArrivalDateModal(false);
    setArrivedModal(false);
    setTableModal(false);
    setDeleteBulk([]);
  };

  const handleDisabled = () => {
    const clientsCopy = [...clients];

    for (let i = 0; i < clientsCopy.length; i++) {
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualWeight <= 0)
        return true;
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualVolume <= 0)
        return true;
    }

    return false;
  };

  const linkHandler = async (formData) => {
    setSpinner(true);
    const schema = {
      id,
      orders: [],
      [shipmentType.toLowerCase() === "aircargo" ? "weight" : "volume"]:
        shipmentType.toLowerCase() === "aircargo"
          ? formData.weight
          : formData.volume,
    };

    postClient.forEach((item) => {
      // if follow up status is "reset recived" this means all the fields should be disabled
      // and send the rest version of the fields
      schema.orders.push({
        id: item.id,
        // numberOfPkgs: item.actualNoPackg,
        numberOfPkgs: item.actualNoPackg,
        [shipmentType.toLowerCase() === "aircargo" ? "weight" : "volume"]:
          shipmentType.toLowerCase() === "aircargo"
            ? item.actualWeight
            : item.actualVolume,
      });
    });

    const res = await linkOrderApi.request(shipmentType, schema);
    if (res.status === 200) {
      const totalPkgs = schema.orders.reduce(
        (prev, current) => prev + current.numberOfPkgs,
        0
      );

      const totalWeight = schema.orders.reduce(
        (prev, current) => prev + current.weight,
        0
      );

      const totalVolume = schema.orders.reduce(
        (prev, current) => prev + current.volume,
        0
      );

      sweetAlert("Done");
      setClients([]);
      setData((d) => ({
        ...d,
        numberOfPkgs: +(d.numberOfPkgs + totalPkgs).toFixed(2),
        volume: +(d.volume + totalVolume).toFixed(2),
        weight: +(d.weight + totalWeight).toFixed(2),
      }));
      reset();
    }
    setSpinner(false);
  };

  const handleDelete = async () => {
    setDeleteModal(false);
    setViewOrdersModal(false);
    setDeleteBulk([]);
    setSpinner(true);

    const schema = deleteBulk.map((item) => item.id);

    const res = await deleteOrdersApi.request(shipmentType, id, schema);
    if (res.status === 200) {
      const totalPkgs = deleteBulk.reduce(
        (prev, current) => prev + current.numberOfPkgs,
        0
      );

      const totalWeight = deleteBulk.reduce(
        (prev, current) => prev + current.weight,
        0
      );

      const totalVolume = deleteBulk.reduce(
        (prev, current) => prev + current.volume,
        0
      );

      sweetAlert("Done");
      setData((d) => ({
        ...d,
        numberOfPkgs: +(d.numberOfPkgs - totalPkgs).toFixed(2),
        weight: +(d.weight - totalWeight).toFixed(2),
        volume: +(d.volume - totalVolume).toFixed(2),
      }));
    }
    setSpinner(false);
  };

  useEffect(() => {
    getBulkDetails();
  }, []);

  if (!checkAbility("view")) return <></>;

  return (
    <div>
      {alert}
      {bulkDetails.errorAlert}
      {uploadImages.errorAlert}
      {arrivedBulk.errorAlert}
      {updateBulkArrivalDate.errorAlert}
      {linkOrderApi.errorAlert}
      {deleteOrdersApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {bulkDetails.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[]} backBtn />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.page[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                {checkAbility("create") && data.followUpStatus === 7 && (
                  <form onSubmit={handleSubmit(linkHandler)}>
                    <Row>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols1Input"
                          >
                            {commonContent.customer[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="client"
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="example3cols1Input"
                                placeholder="Client"
                                type="text"
                                className={errors.numberOfPkgs && "error"}
                                value={`${postClient.length} ${content.selected[culture]}`}
                                onClick={() => setTableModal(true)}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="4">
                        {shipmentType.toLowerCase() === "aircargo" ? (
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="weight"
                            >
                              {commonContent.weight[culture]}
                            </label>
                            <Controller
                              control={control}
                              name="weight"
                              // rules={{
                              //   required: "weight field is required",
                              //   valueAsNumber: true,
                              //   validate: (value) => value > 0
                              // }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id="weight"
                                  placeholder="Code"
                                  type="number"
                                  readOnly
                                  className={errors.weight && "error"}
                                  value={value || 0}
                                  onWheel={(e) => e.target.blur()}
                                />
                              )}
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="example3cols1Input"
                            >
                              {commonContent.volume[culture]}
                            </label>
                            <Controller
                              control={control}
                              name="volume"
                              // rules={{
                              //   required: "Voluem field is required",
                              //   valueAsNumber: true,
                              //   validate: (value) => value > 0
                              // }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id="example3cols1Input"
                                  type="number"
                                  readOnly
                                  className={errors.volume && "error"}
                                  value={value || 0}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={({ target: { value } }) =>
                                    onChange(+value)
                                  }
                                />
                              )}
                            />
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="numberOfPkgs"
                          >
                            {commonContent.numOfPackages[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="numberOfPkgs"
                            // rules={{
                            //   required: "numberOfPkgs field is required",
                            //   valueAsNumber: true,
                            //   validate: (value) => value > 0
                            // }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="numberOfPkgs"
                                placeholder="Number of packages"
                                type="number"
                                readOnly
                                className={errors.numberOfPkgs && "error"}
                                value={value}
                                onWheel={(e) => e.target.blur()}
                                onChange={({ target: { value } }) =>
                                  onChange(+value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      color="success"
                      type="submit"
                      disabled={!clients.length}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{commonContent.create[culture]}</span>
                    </Button>
                  </form>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <form onSubmit={arrivalDataHandler}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          {content.customers[culture]}
                        </label>
                        <div className="bulk-clients">
                          <span>Click to view clients</span>
                          <span onClick={() => setViewOrdersModal(true)}>
                            <div
                              id="details1"
                              className="table-action cursor-pointer mt-1 me-1"
                            >
                              <i className="far fa-eye fa-lg hover-success" />
                            </div>
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="bulkPckgNum"
                        >
                          {commonContent.code[culture]}
                        </label>
                        <Input
                          id="bulkPckgNum"
                          type="text"
                          defaultValue={data.bulkPckgNumShown}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="noOfPkgs"
                        >
                          {commonContent.numOfPackages[culture]}
                        </label>
                        <Input
                          id="noOfPkgs"
                          type="text"
                          defaultValue={data.numberOfPkgs}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="weight">
                          {shipmentType.toLowerCase() === "aircargo"
                            ? commonContent.weight[culture]
                            : commonContent.volume[culture]}
                        </label>
                        <Input
                          id="weight"
                          type="text"
                          defaultValue={
                            shipmentType.toLowerCase() === "aircargo"
                              ? data.weight
                              : data.volume
                          }
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="weight">
                          {content.trackLine[culture]}
                        </label>
                        <Input
                          id="weight"
                          type="text"
                          defaultValue={env.trackLine[data.trackLine] || ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="weight">
                          {content.trackNumber[culture]}
                        </label>
                        <Input
                          id="weight"
                          type="text"
                          defaultValue={data.trackNumber}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          {content.arrivalDate[culture]}
                        </label>
                        <Input
                          id="client"
                          type="date"
                          disabled={data.followUpStatus === 4 ? true : false}
                          value={
                            arrivalDate || data.arrivalDate?.split("T")[0] || ""
                          }
                          onChange={(e) => setArrivalDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {data.followUpStatus !== 4 && (
                        <>
                          <Button
                            color="success"
                            type="button"
                            className="mt-4 me-1"
                            onClick={() => setArrivedModal(true)}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-check"></i>
                            </span>
                            <span>{content.arrived[culture]}</span>
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            className="mt-4"
                            disabled={!arrivalDate}
                          >
                            <span>{content.updateDate[culture]}</span>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
            {data?.country?.countryCode === "CN" && (
              <BulkStation
                data={data}
                setData={setData}
                sweetAlert={sweetAlert}
                setSpinner={setSpinner}
                shipmentType={shipmentType}
              />
            )}
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>Upload images</h3>
                </div>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleUploadImages}>
                  <Row>
                    <Col>
                      <FilePicker images={imgs} setImages={setImgs} />
                    </Col>
                  </Row>
                  <Button
                    color="success"
                    type="submit"
                    className="mt-4"
                    // disabled={false}
                    onClick={() => {}}
                    disabled={!Object.keys(imgs).length}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span>{commonContent.upload[culture]}</span>
                  </Button>
                </form>
              </CardBody>
              <TextPopup
                modal={viewOrdersModal}
                text="Delete"
                handleCancel={handleCancel}
                fn={() => setDeleteModal(true)}
                color="danger"
                noBtn={!deleteBulk.length}
              >
                <DeleteCustomerBulk
                  sweetAlert={sweetAlert}
                  shipmentType={shipmentType.toLowerCase()}
                  bulkPckgNum={data.bulkPckgNum}
                  countryCode={data.country?.countryCode}
                  deleteBulk={deleteBulk}
                  setDeleteBulk={setDeleteBulk}
                  canDelete={data.followUpStatus === 7}
                  bulkId={id}
                />
              </TextPopup>
              <TextPopup
                modal={arrivalDateModal}
                text={commonContent.update[culture]}
                handleCancel={handleCancel}
                fn={handleUpdate}
                color="success"
              >
                <Container>
                  <h3>
                    {content.datePopup[culture]}
                    <span style={{ color: "var(--primary)" }}>
                      {" "}
                      {arrivalDate}
                    </span>
                  </h3>
                </Container>
              </TextPopup>
              <TextPopup
                modal={arrivedModal}
                text={commonContent.yes[culture]}
                handleCancel={handleCancel}
                fn={handleArrived}
                color="success"
              >
                <Container>
                  <h3>{content.arrivedPopup[culture]}</h3>
                </Container>
              </TextPopup>
              <TextPopup
                modal={deleteModal}
                text="Yes"
                handleCancel={() => setDeleteModal(false)}
                fn={handleDelete}
                color="danger"
              >
                <Container>
                  <h3>
                    {content.deletePopup[culture]}{" "}
                    <span className="text-danger">{deleteBulk.length}</span>{" "}
                    {content.orders[culture]} ?
                  </h3>
                </Container>
              </TextPopup>
              <TextPopup
                modal={tableModal}
                text="Next"
                disabled={handleDisabled()}
                handleCancel={handleCancel}
                fn={handleCancel}
                color="primary"
              >
                <CustomerPopup
                  multiple
                  create
                  id={id}
                  shipmentType={shipmentType.toLowerCase()}
                  setValue={setValue}
                  clients={clients}
                  sweetAlert={sweetAlert}
                  setClients={setClients}
                  setTableModal={setTableModal}
                />
              </TextPopup>
            </Card>
          </Container>
          <Gallery imgs={data.images} />
        </>
      )}
    </div>
  );
};

export default BulkDetails;
