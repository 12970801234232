import client from './client';

const endpoint = '/v1/User';

const signIn = (schema) => client.post(`${endpoint}/Login`, schema);

const signUp = (schema) => {
    const data = new FormData();
    data.append("FullName", schema?.fullName);
    data.append("UserName", schema?.userName);
    data.append("Email", schema?.email);
    data.append("Password", schema?.password);
    data.append("ConfirmPassword", schema?.confirmPassword);
    data.append("CompanyName", schema?.companyName);
    data.append("WhatsappPhone", schema?.whatsappPhone);
    data.append("UserType", schema?.userType);
    data.append("Culture", 'ar');
    data.append("DocType", '1');
    schema.images.forEach((image, index) =>
    data.append("Files", {
      name: "image" + index,
      type: "image/jpeg",
      uri: image,
    })
  );
  //  data.append("Files", schema?.images);
   return client.post(`${endpoint}/register`, data);
}

const dashboardSignup = schema => {
  const data = new FormData();
  data.append("FullName", schema?.fullName);
  data.append("UserName", schema?.username);
  data.append("Email", schema?.email);
  data.append("Password", schema?.password);
  data.append("ConfirmPassword", schema?.confirmPassword);
  data.append("CompanyName", schema?.companyName);
  data.append("WhatsappPhone", schema?.WhatsappPhone);
  data.append("UserType", schema?.userType);
  data.append("Culture", schema?.culture);
  data.append("DocType", schema?.DocType);
  Array.from(schema.Files).forEach(file => {
    data.append("Image", file);
  })

  return client.post(`${endpoint}/register`, data)
}

export default {
    signIn,
    signUp,
    dashboardSignup
} 