const useDelete = (
  data,
  setData,
  apiFun,
  setSpinner,
  setModal,
  sweetAlert = () => {}
) => {
  const deleteRow = async (id, ...params) => {
    setSpinner(true);
    setModal(false);
    const res = await apiFun.request(id, ...params);

    if (res.status === 200) {
      const newData = [...data].filter((item) => item.id !== id);
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  return deleteRow;
};

export default useDelete;
