import React, { useState, useContext } from "react";

// router
import { useRouteMatch, useParams, useLocation } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";

// test data
import env from "env";
// BK
import Table from "components/common/CustomTable";
import useApi from "../../hooks/useApi";
import invoiveApi from "api/invoice";
import Spinner from "../../components/Spinner";
import { useForm, Controller } from "react-hook-form";
import PopupTable from "components/common/PopupTableUsed";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import ContactedInvoice from "./components/ContactedInvoice";
import useAlert from "hooks/useAlert";
import EditInvoice from "./components/EditInvoice";
import SearchModal from "components/common/SearchModal";
import useSearchPopup from "hooks/useSearchPopup";
import FilePicker from "components/common/FilePicker";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import CountryTable from "components/common/CountryTable";
import useAbility from "hooks/useAbility";
import content from "./content";
import usePrevRequest from "hooks/usePrevRequest";
import ChangeCargoType from "components/common/ChangeCargoType";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import useCheckRequest from "hooks/useCheckRequest";
import AdvanceTable from "components/common/AdvanceTable";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";

function Invoice() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const location = useLocation();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    commenContent.customer[culture],
    commenContent.code[culture],
    commenContent.followUpStatus[culture],
    commenContent.supplyContact[culture],
    commenContent.branch[culture],
    commenContent.bankAccount[culture],
    content.isApproved[culture],
    commenContent.date[culture],
    commenContent.lastModifiedOn[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "customer.customerName",
    "customer.customerCode",
    "followUpStatusText",
    "supplierContact",
    "branch.name",
    "bankAccount",
    "isApprovedText",
    "createdOn",
    "lastModifiedOn",
  ]);

  const { shipmentType } = useParams();
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `${shipmentType}-managment-invoice`;

  const paginationCtx = useContext(PaginationContext);

  // Apis
  const getInvoiceApi = useApi(invoiveApi.invoicePreload);
  const savedContact = useApi(invoiveApi.savedContact);
  const approveInvoice = useApi(invoiveApi.approveInvoice);
  const createInvoice = useApi(invoiveApi.createInvoice);
  const getByColumn = useApi(invoiveApi.advanceSearch);
  const deleteInvoiceApi = useApi(invoiveApi.deleteInvoice);
  const updateInvoiceApi = useApi(invoiveApi.updateInvoice);
  const changeCargoApi = useApi(invoiveApi.changeCargoType);

  // States
  const [data, setData] = useState({});
  const [imgs, setImgs] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [customer, setCustomer] = useState({});
  const [tableModal, setTableModal] = useState(false);
  const [contactedModal, setContactedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [countryTable, setCountryTable] = useState(false);
  const [changeTypeModal, setChangeTypeModal] = useState(false);

  const type = (shipmentType) => {
    if (shipmentType.toLowerCase() === "aircargo") {
      return "AirCargoInvoice";
    } else if (shipmentType.toLowerCase() === "lclcargo") {
      return "LCLCargoInvoice";
    } else return "FCLInvoice";
  };
  const pageType = (shipmentType) => {
    if (shipmentType.toLowerCase() === "aircargo") {
      return "AC";
    } else if (shipmentType.toLowerCase() === "lclcargo") {
      return "LC";
    } else return "FC";
  };
  const checkAbility = useAbility(
    type(shipmentType)
    // shipmentType.toLowerCase() === "aircargo"
    //   ? "AirCargoInvoice"
    //   : "LCLCargoInvoice"
  );

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const editResponse = (item) => {
    // This code will split any date by checking it's key
    Object.keys(item).forEach((key) => {
      if (key.includes("date") || key.includes("Date")) {
        item[key] = item[key] ? item[key].split("T")[0] : "";
      }
    });

    item.createdOn = item.createdOn.split("T")[0];
    item.lastModifiedOn = item.lastModifiedOn.split("T")[0];
    // Replace the normal isApprove with replacement text
    item.isApprovedText = item.isApproved ? "Approved" : "Not approved";
    // Replce the status number with the text
    item.followUpStatusText = env.followUpStatus.find(
      (stats) => stats.key === item.followUpStatus
    )?.[culture];

    const acOrLcOrFc = pageType(shipmentType);
    // const acOrLc = shipmentType.toLowerCase() === "aircargo" ? "AC" : "LC";
    item.orderNumber = `${item.country?.countryCode}-${item.orderNumber}${acOrLcOrFc}`;

    if (item.isInvoiceRowColoraized && [1, 2].includes(item.followUpStatus)) {
      item.className = ["initiated", "contacted"][item.followUpStatus - 1];
    }

    return item;
  };

  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
    resetAdvanceFilter,
  } = useAdvanceSearch(pageName, editResponse);

  // Use form hook
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const {
    handleSubmit: contactedInvoiceSubmit,
    formState: { errors: contactedInvoiceError },
    control: contactedInvoiceControler,
    setValue: contactedInvoiceSetValue,
  } = useForm();

  const {
    handleSubmit: editInvoiceSubmit,
    formState: { errors: editInvoiceError },
    control: editInvoiceControler,
    setValue: editInvoiceSetValue,
  } = useForm();

  const {
    handleSubmit: changeTypeSubmit,
    setValue: changeTypeSetValue,
    formState: { errors: changeTypeErrors },
    control: changeTypeControl,
    reset: changeTypeReset,
  } = useForm();

  const getInvoice = async () => {
    setData((d) => ({
      ...d,
      data: [],
    }));
    const res = await getInvoiceApi.request(pageNo, docsPerPage, shipmentType);
    if (res.status === 200) {
      res.data.data.data.forEach((item) => editResponse(item));

      setData(res.data?.data);

      // if the prev page is not the same as this one clear the context
      // if (!prevPageCheck()) paginationCtx.resetPagination();
    }
  };

  const handleCancel = () => {
    setEditModal(false);
    setTableModal(false);
    setApproveModal(false);
    setContactedModal(false);
    setDeleteModal(false);
    setCountryTable(false);
    setInvoiceId("");
    setImgs({});
    setRowId("");
    reset();
  };

  const handleContacted = (obj) => {
    setContactedModal(true);
    contactedInvoiceSetValue("value", obj.value);
    contactedInvoiceSetValue("id", obj.id);
    contactedInvoiceSetValue("valueIwan", obj.valueIwan);
    contactedInvoiceSetValue("bankAccount", obj.bankAccount);
    contactedInvoiceSetValue(
      "remittanceCommissionDinar",
      obj.remittanceCommissionDinar
    );
    contactedInvoiceSetValue(
      "remittanceCommissionDollar",
      obj.remittanceCommissionDollar
    );
  };

  const handleEdit = (obj) => {
    setEditModal(true);
    editInvoiceSetValue("country", obj.country.name);
    editInvoiceSetValue("countryId", obj.country.id);
    editInvoiceSetValue("bankAccount", obj.bankAccount || "");
    editInvoiceSetValue("supplierContact", obj.supplierContact);
    setImgs({});
    setRowId(obj.id);
  };

  const contactedInvoice = async (formData) => {
    setContactedModal(false);
    setSpinner(true);
    const schema = { ...formData, shipmentType };
    delete schema.id;

    const res = await savedContact.request(formData.id, schema, shipmentType);

    if (res.ok) {
      const newData = { ...data };
      const index = newData.data.findIndex((item) => item.id === formData.id);

      newData.data[index] = {
        ...newData.data[index],
        ...formData,
      };
      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleApprove = (obj) => {
    setInvoiceId(obj.id);
    setApproveModal(true);
  };

  const approveInvoicement = async () => {
    setApproveModal(false);
    setSpinner(true);
    const res = await approveInvoice.request(invoiceId, shipmentType);

    if (res.ok) {
      const newData = { ...data };
      const index = newData.data.findIndex((item) => item.id === invoiceId);
      newData.data[index] = {
        ...newData.data[index],
        isApproved: true,
        isApprovedText: "Approved",
      };
      setData(newData);
      setInvoiceId("");
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleCreate = async (formData) => {
    setSpinner(true);
    // const imgs = imageRef.current.files
    const schema = new FormData();
    schema.append("supplierContact", formData.SupplierContact);
    schema.append("countryId", formData.countryId);
    schema.append("customerId", customer.id);
    schema.append("branchId", formData.branchId);

    for (let i = 0; i < imgs.length; i++) {
      schema.append("Image", imgs[i]);
    }

    const res = await createInvoice.request(schema, shipmentType);
    if (res.ok) {
      setImgs({});
      reset();
      getInvoice();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const approveRenderCondition = (child) =>
    !child.isApproved && checkAbility("approve");

  const contactedRenderCondition = (child) =>
    [1, 2].includes(child.followUpStatus) && checkAbility("contacted")
      ? true
      : false;

  const renderEditCondition = (child) =>
    child.followUpStatus === 1 && checkAbility("edit") ? true : false;

  const renderDeleteCondition = (child) =>
    child.followUpStatus === 1 && checkAbility("delete") ? true : false;

  const handleDeleteInvoice = async () => {
    // let res;
    setSpinner(true);
    setDeleteModal(false);
    const res = await deleteInvoiceApi.request(shipmentType, rowId);

    if (res.status === 200) {
      const newData = { ...data };
      newData.data = data.data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const editInvoice = async (formData) => {
    setSpinner(true);
    setEditModal(false);
    let res;

    const schema = {
      countryId: formData.countryId,
      supplierContact: formData.supplierContact,
      bankAccount: formData.bankAccount,
    };

    res = await updateInvoiceApi.request(rowId, schema, shipmentType);

    if (res.status === 200) {
      const newData = { ...data };
      const rowIndex = newData.data.findIndex((item) => item.id === rowId);
      newData.data[rowIndex].supplierContact = formData.supplierContact;
      newData.data[rowIndex].country.name = formData.country;
      newData.data[rowIndex].country.id = formData.countryId;

      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
    setRowId("");
  };

  const updateData = (arr) => {
    setData((d) => ({
      ...d,
      data: arr,
    }));
  };

  const renderChangeType = (obj) =>
    obj.followUpStatus < 6 && shipmentType.toLowerCase() !== "fcl";

  const handleChangeType = (obj) => {
    setChangeTypeModal(true);
    changeTypeSetValue("id", obj.id);
  };

  const changeCargoHandler = async (data) => {
    setSpinner(true);
    setChangeTypeModal(false);
    const otherType =
      shipmentType.toLowerCase() === "aircargo" ? "lcl" : "aircargo";

    const field =
      shipmentType.toLowerCase() === "aircargo" ? "volume" : "weight";

    const res = await changeCargoApi.request(
      data.id,
      { [field]: data[field] },
      otherType
    );
    if (res.status === 200) {
      setData((oldData) => ({
        ...oldData,
        data: oldData.data.filter((item) => item.id !== data.id),
      }));
      sweetAlert(commenContent.done[culture]);
      changeTypeReset();
    }
    setSpinner(false);
  };

  const handleSearch = async () => {
    const res = await searchHandler(
      getByColumn,
      pageNo,
      docsPerPage,
      shipmentType
    );
    if (res.ok) {
      updateData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
  };

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  };

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [getInvoice, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
    depandancies: [location],
    resetAdvanceFilter,
  });

  if (getInvoiceApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {approveInvoice.errorAlert}
      {savedContact.errorAlert}
      {getInvoiceApi.errorAlert}
      {createInvoice.errorAlert}
      {updateInvoiceApi.errorAlert}
      {deleteInvoiceApi.errorAlert}
      {changeCargoApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      <SimpleHeader parents={[content[shipmentType.toLowerCase()][culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3 className={`mb-0`}>
              {content[shipmentType.toLowerCase()][culture]}
            </h3>
          </CardHeader>
          <CardBody>
            {checkAbility("create") && (
              <form onSubmit={handleSubmit(handleCreate)}>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="customer">
                        {commenContent.customer[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="client"
                        rules={{ required: "Client field is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="customer"
                            placeholder={commenContent.customer[culture]}
                            type="text"
                            className={errors.client && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => setTableModal(true)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="country">
                        {commenContent.country[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: "Country feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="country"
                            type="text"
                            className={errors.country && "error"}
                            value={value || ""}
                            placeholder={`-- ${commenContent.selectAnOption[culture]} --`}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => setCountryTable(true)}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="phone">
                        {commenContent.supplyContact[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="SupplierContact"
                        rules={{ required: "Supply contact feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="phone"
                            type="text"
                            placeholder={commenContent.supplyContact[culture]}
                            className={errors.phone && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="branch">
                        {commenContent.branch[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="branchId"
                        rules={{ required: "branch feild is required" }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="branch"
                            type="select"
                            className={errors.branchId && "error"}
                            value={value || "disabled"}
                            placeholder={`-- ${commenContent.selectAnOption[culture]} --`}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          >
                            <option disabled value="disabled">
                              -- {commenContent.selectAnOption[culture]} --
                            </option>
                            {data.branch?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <FilePicker images={imgs} setImages={setImgs} />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="success"
                  type="submit"
                  disabled={!Object.keys(imgs).length || false}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commenContent.create[culture]}</span>
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
        <Card className="mb-4">
          <Pagination
            dataLength={data.data?.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <AdvanceTable
              pageName={pageName}
              headers={headers}
              columns={columns}
              setHeaders={setHeaders}
              setColumns={setColumns}
              data={data.data}
              setData={(sortedData) =>
                setData((d) => ({ ...d, data: sortedData }))
              }
              handleEdit={handleEdit}
              handleContacted={handleContacted}
              contactedRenderCondition={contactedRenderCondition}
              handleApprove={handleApprove}
              approveRenderCondition={approveRenderCondition}
              renderEditCondition={renderEditCondition}
              renderDeleteCondition={renderDeleteCondition}
              handleDelete={handleDelete}
              handleChangeType={handleChangeType}
              renderChangeType={renderChangeType}
              searchedHeaders={header}
              onHeaderClick={onHeaderClick}
              handleRemove={handleRemove}
            >
              <span to={`/${culture}-admin/invoice-management/${shipmentType}`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-primary"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commenContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleContacted"
                condition="contactedRenderCondition"
              >
                <div
                  id="contacted-value"
                  className="table-action cursor-pointer"
                >
                  <i className="fas fa-file-import fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="contacted-value">
                  {commenContent.contactedValue[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleEdit"
                condition="renderEditCondition"
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commenContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleApprove"
                condition="approveRenderCondition"
              >
                <div id="check" className="table-action cursor-pointer">
                  <i className="far fa-check-circle hover-success fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="check">
                  {commenContent.check[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleDelete"
                condition="renderDeleteCondition"
              >
                <div id="delete" className="table-action cursor-pointer">
                  <i className="fas fa-trash hover-danger fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commenContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleChangeType"
                className="me-1"
                condition="renderChangeType"
              >
                <div id="change-type" className="table-action cursor-pointer">
                  <i className="fas fa-exchange-alt fa-lg hover-warning" />
                </div>
                <UncontrolledTooltip delay={0} target="change-type">
                  {
                    commenContent[`${shipmentType.toLowerCase()}Change`][
                      culture
                    ]
                  }
                </UncontrolledTooltip>
              </span>
            </AdvanceTable>
          </Pagination>
        </Card>
        <TextPopup
          modal={contactedModal}
          text={commenContent.save[culture]}
          handleCancel={handleCancel}
          fn={contactedInvoiceSubmit(contactedInvoice)}
          color="primary"
        >
          <Container>
            <ContactedInvoice
              control={contactedInvoiceControler}
              errors={contactedInvoiceError}
            />
          </Container>
        </TextPopup>
        <TextPopup
          modal={editModal}
          text={commenContent.save[culture]}
          handleCancel={handleCancel}
          fn={editInvoiceSubmit(editInvoice)}
          color="primary"
        >
          <Container>
            <EditInvoice
              errors={editInvoiceError}
              control={editInvoiceControler}
              setValue={editInvoiceSetValue}
            />
          </Container>
        </TextPopup>
        <TextPopup
          modal={approveModal}
          text={commenContent.save[culture]}
          handleCancel={handleCancel}
          fn={approveInvoicement}
          color="primary"
        >
          <Container>
            <h2>{content.approvePopup[culture]}</h2>
          </Container>
        </TextPopup>
        <TextPopup
          modal={deleteModal}
          text="Yes"
          handleCancel={handleCancel}
          fn={handleDeleteInvoice}
          color="danger"
        >
          <Container>
            <h2>{content.deletePopup[culture]}</h2>
          </Container>
        </TextPopup>
        <TextPopup
          modal={tableModal}
          text="Next"
          handleCancel={handleCancel}
          fn={() => {}}
          color="primary"
          noBtn
        >
          <Container>
            <PopupTable
              setCustomer={setCustomer}
              setTableModal={setTableModal}
              setValue={setValue}
              sweetAlert={sweetAlert}
            />
          </Container>
        </TextPopup>
        <TextPopup
          modal={countryTable}
          text="Next"
          handleCancel={handleCancel}
          fn={() => {}}
          color="primary"
          noBtn
        >
          <Container>
            <CountryTable
              setCountryTable={setCountryTable}
              setValue={setValue}
              sweetAlert={sweetAlert}
            />
          </Container>
        </TextPopup>
        <TextPopup
          modal={changeTypeModal}
          text={commenContent.change[culture]}
          name={commenContent.changeToLcl[culture]}
          handleCancel={() => setChangeTypeModal(false)}
          fn={changeTypeSubmit(changeCargoHandler)}
          color="success"
        >
          <ChangeCargoType
            control={changeTypeControl}
            errors={changeTypeErrors}
            cargoType={
              shipmentType.toLowerCase() === "aircargo" ? "aircargo" : "lcl"
            }
          />
        </TextPopup>
        {openPopup && (
          <AdvanceSearchModal
            setKey={setKey}
            openPopup={openPopup}
            pageName={pageName}
            getByColumn={getByColumn}
            updateData={updateData}
            setParentPageNo={setPageNo}
            setParentDocsPerPage={setDocsPerPage}
            setParentSearchField={setSearchField}
            handleClose={closeAdvanceSearch}
            editResponse={editResponse}
            extraParams={[shipmentType]}
            extraField={{
              header: commenContent.orderNumber[culture],
              column: "orderNumber",
            }}
          />
        )}
      </Container>
    </div>
  );
}

export default Invoice;
