import React from "react";
import { Col, Row, FormGroup, Input } from "reactstrap";
import classes from "./Comment.module.css";
import avatar from "assets/img/icons/common/avatar.jpg";
import { useForm, Controller } from "react-hook-form";
const Comment = ({ username, content, date }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  return (
    <>
      <Row className="align-items-baseline">
        <Col className="flex-grow-0">
          <img src={avatar} alt="person" className={classes.img} />
        </Col>
        <Col className="flex-grow-1">
          <div className={classes["dialogbox"]}>
            <div className={classes["message"]}>
              <div>{username}</div>
              <span>{content}</span>
              <div className={classes.date}>{date.split("T")[0]}</div>
            </div>
          </div>
        </Col>
      </Row>
      
    </>
  );
};

export default Comment;
