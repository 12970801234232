import React from "react";
import { Col, FormGroup, Input, Row, Container, CardBody } from "reactstrap";
import env from "env";
import { Controller } from "react-hook-form";
import content from "../content";
import useCulture from "hooks/useCulture";
import commonContent from "components/common/content";

const EditAirshipment = ({ control, errors }) => {
  const culture = useCulture();

  return (
    <CardBody>
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="priceType">
              {commonContent.priceType[culture]}
            </label>
            <Controller
              control={control}
              name="priceType"
              rules={{
                required: "priceType value field is required",
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="priceType"
                  type="select"
                  className={errors.priceType && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(+value)}
                >
                  <option value="" disabled>
                    -- {commonContent.selectAnOption[culture]} --
                  </option>
                  {env.priceType.map((price, index) => (
                    <option value={index + 1} key={index}>{price}</option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="minimumWightKg">
              {content.minimumWightKg[culture]}
            </label>
            <Controller
              control={control}
              name="minimumWightKg"
              rules={{
                required: "minimumWightKg value field is required",
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="price-value"
                  placeholder="0"
                  type="number"
                  className={errors.minimumWightKg && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="airMinimumPrice">
              {content.airMinimumPrice[culture]}
            </label>
            <Controller
              control={control}
              name="airMinimumPrice"
              rules={{
                required: "airMinimumPrice value field is required",
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="airMinimumPrice"
                  placeholder="0"
                  type="number"
                  className={errors.airMinimumPrice && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="minimumCBM">
              {content.minimumCBM[culture]}
            </label>
            <Controller
              control={control}
              name="minimumCBM"
              rules={{
                required: "minimumCBM value field is required",
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="minimumCBM"
                  placeholder="0"
                  type="number"
                  className={errors.minimumCBM && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="lclMinimumPrice">
              {content.lclMinimumPrice[culture]}
            </label>
            <Controller
              control={control}
              name="lclMinimumPrice"
              rules={{
                required: "lclMinimumPrice value field is required",
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="lclMinimumPrice"
                  placeholder="0"
                  type="number"
                  className={errors.lclMinimumPrice && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  );
};

export default EditAirshipment;
