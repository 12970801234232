import React from "react";
import classes from "./PrintBody.module.css";
// import shaheen from "assets/img/brand/shaheen-mini.png";
import shaheen from "assets/img/brand/shaheen.jpg";
import qrCode from "assets/img/icons/common/qr-code.png";

const PrintBody = ({ title, printBody = [] }) => {
  return (
    <div className={classes.printBody}>
      <header>
        <div className={classes.qr}>
          <img src={qrCode} alt="bar-code" />
        </div>
        <div className={classes.headerContent}>
          <span>Tripoli - Libya Office 00218 918170700</span>
          <span>00218 917538959 00218 920902383</span>
          <a className="email">Info@shahen.ly</a>
        </div>
      </header>
      <div className={classes.headerSection}>
        <div className={classes.date}>
          تاريخ الإصدار Issue date
          <span className="font-weight-normal mr-4 d-block">
            {new Date().toLocaleDateString()}
          </span>
        </div>
        <div className={classes.logo}>
          <img src={shaheen} alt="shaheen-logo" />
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between">
        <main className={classes.comp}>
          {/* <h1>شركة الشاهين للشحن</h1> */}
          <h1>{title}</h1>
        </main>
      </div>
      <div className="d-flex flex-row-reverse align-items-center justify-content-between">
        <div className={classes.company}>
          {/* <span>شركة الشاهين لشحن</span>
          <h3>Alshahen Shipping Co.</h3> */}
          <p>,Hai Dimashk District, Near the Highway, Tripoli, Libya</p>
          <p>حي دمشق، بالقرب من الطريق السريع، طرابلس، ليبيا</p>
          <p>التاريخ</p>
          <p>{new Date(Date.now()).toISOString()?.split("T")?.[0]}</p>
        </div>
        <div className={classes.extraField}>
          {printBody.length ? (
            printBody.map((item, index) => (
              <div key={index} className={classes.textUpperTable}>
                <p style={{ fontWeight: "bold" }}>{item.text}</p>
                <p style={{ direction: "ltr" }}>{item.value}</p>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintBody;
