export default {
  fcl: {
    ar: "حمولة حاوية كاملة",
    en: "FCL",
  },
  fclId: {
    en: "FCL ID",
  },
  orderId: {
    en: "order ID",
  },
  client: {
    en: "Client",
  },
  followUpStatus: {
    en: "Follow up status",
  },
  dateDeprature: {
    en: "Departure date",
  },
  dateEstArrival: {
    en: "Arrival estimation date",
  },
  sealNum: {
    en: "Seal No.",
  },
  contNum: {
    en: "Cont No.",
  },
  country: {
    en: "Country",
  },
  billLoading: {
    ar: "فاتورة التحميل",
    en: "Bill of loading",
  },
  images: {
    en: "Images",
  },
  date: {
    en: "Date",
  },
  quantity: {
    ar: "الكمية",
    en: "Quantity",
  },
  polNumber: {
    ar: "رقم البول",
    en: "Pol Number",
  },
  polDate: {
    ar: "تاريخ البول",
    en: "Pol Date",
  },
  trackNumber: {
    ar: "رقم الشاحنة",
    en: "Track Number",
  },
  trackLine: {
    ar: "مسار الشاحنة",
    en: "Track Line",
  },
  containerPrice: {
    ar: "سعر الحاوية",
    en: "Container price"
  },
  containerSellPrice: {
    ar: "سعر بيع الحاوية",
    en: "Container Sell Price"
  },
  loaded: {
    ar: "تم التحميل",
    en: "Loaded"
  },
  loadedPopup: {
    ar: "هل انت متأكد من ان الشحنة تم تحميلها ؟",
    en: "Are you sure this order is loaded ?"
  },
  arrivedPopup: {
    ar: "هل أنت متأكد أنك تريد تغيير حالة الشحنة إلى تم الايصال ؟",
    en: "Are you sure you want to change shipment state to arrived ?"
  },
  datePopup: {
    ar: 'هل أنت متأكد أنك تريد تحديث تاريخ الوصول إلى',
    en: "Are you sure you want to update the arrival date to"
  },
  shippedPopup: {
    ar: "هل انت متأكد ان هذه الحاوية تم شحنها ؟",
    en: "Are you sure this container has been shipped?"
  },
  shipped: {
    ar: "تم شحنها",
    en: "Shipped"
  },
  arrival: {
    ar: "الوصول",
    en: "Arrival"
  },
  uploadImgs: {
    ar: "رفع الصور",
    en: "Upload images"
  }
};
