import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { CardBody, Col, FormGroup, Input, Row, Container } from "reactstrap";
import env from "env";
import content from "../content";
import useCulture from "hooks/useCulture";
import FilterEntries from "components/common/FilterEntries";
import TextPopup from "components/common/TextModal";
import CostCenterTable from "components/common/CostCenterTable";
import commenContent from "components/common/content";

function DepositeForm({ control, errors, setValue, sweetAlert }) {
  const culture = useCulture();

  const [accountModal, setAccountModal] = useState(false);
  const [costCenterModal, setCostCenterModal] = useState(false);

  const handleCancel = () => {
    setAccountModal(false);
    setCostCenterModal(false);
  };
  return (
    <CardBody>
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="currency">
              {content.currency[culture]}
            </label>
            <Controller
              control={control}
              name="currency"
              rules={{ required: `This field is required` }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="currency"
                  type="select"
                  className={errors.currency && "error"}
                  onChange={({ target: { value } }) => onChange(+value)}
                  onWheel={(e) => e.target.blur()}
                  value={value || "disabled"}
                >
                  <option disabled value="disabled">
                    -- {content.selectOption[culture]} --
                  </option>
                  {env?.currency?.map((item, index) => (
                    <option value={index + 1} key={index}>
                      {item[culture]}
                    </option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="treasuryAccount">
              {content.treasury[culture]}
            </label>
            <Controller
              control={control}
              name="treasuryAccount"
              rules={{
                required: true,
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="treasury"
                  type="text"
                  placeholder={content.treasury[culture]}
                  className={errors.treasuryAccount && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setAccountModal(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="costCenter">
              {commenContent.costCenter[culture]}
            </label>
            <Controller
              control={control}
              name="costCenter"
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="costCenter"
                  placeholder={`-- ${commenContent.costCenter[culture]} --`}
                  type="text"
                  className={errors.costCenter && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCostCenterModal(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="value">
              {content.value[culture]}
            </label>
            <Controller
              control={control}
              name="value"
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="value"
                  type="number"
                  value={value || ""}
                  className={errors.value && "error"}
                  onChange={({ target: { value } }) => onChange(+value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="statement">
              {content.statement[culture]}
            </label>
            <Controller
              control={control}
              name="statement"
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="statement"
                  type="textarea"
                  value={value || ""}
                  className={errors.statement && "error"}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <TextPopup
          modal={accountModal}
          text="Next"
          handleCancel={handleCancel}
          fn={() => {}}
          color="primary"
          noBtn
        >
          <Container>
            <FilterEntries
              accountClassification={2}
              setValue={setValue}
              sweetAlert={sweetAlert}
              fieldName="treasury"
              setFieldName={() => setAccountModal(false)}
            />
          </Container>
        </TextPopup>
        <TextPopup
          modal={costCenterModal}
          text={commenContent.next[culture]}
          handleCancel={handleCancel}
          fn={() => {}}
          color="primary"
          noBtn
        >
          <Container>
            <CostCenterTable
              // setCountry={setCountry}
              setModal={setCostCenterModal}
              setValue={setValue}
              sweetAlert={sweetAlert}
            />
          </Container>
        </TextPopup>
      </Row>
    </CardBody>
  );
}

export default DepositeForm;
