import client from "./client";

const endpoint = "/v1";

const getAllBulks = (shipmentType, page, docsPerPage) =>
  client.get(
    `${endpoint}/${shipmentType}Bulk?Page=${page}&PostsPerPage=${docsPerPage}`
  );

const bulkDetails = (shipmentType, id) =>
  client.get(`${endpoint}/${shipmentType}Bulk/GetById?id=${id}`);

const getCustomers = (shipmentType, id, pageNo, docsPerPage) =>
  client.get(
    `${endpoint}/${shipmentType}/GetCustmoreBulk?id=${id}&Page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const uploadImg = (shipmentType, id, imgs) => {
  const data = new FormData();

  for (let i = 0; i < imgs.length; i++) {
    data.append("Image", imgs[i]);
  }

  return client.put(`${endpoint}/${shipmentType}/ImgBulk/${id}`, data);
};

const getClients = (shipmentType, id, pageNo, docsPerPage) =>
  client.get(
    `${endpoint}/${shipmentType}bulk/GetClientBulk?Page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const createBulk = (shipmentType, schema) =>
  client.post(`${endpoint}/${shipmentType}bulk`, schema);

const findBulkByCode = (shipmentType, id, orderNumber, page, docsPerPage) => {
  return client.get(
    `${endpoint}/${shipmentType}/FindBulkClientByCode?number=${orderNumber}&id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`
  );
};

const updateBulkArrivalDate = (shipmentType, id, date) =>
  client.put(`${endpoint}/${shipmentType}/UpdatBulk/${id}?date=${date}`);

const arrivedBulk = (shipmentType, id, treasuryId) =>
  client.put(
    `${endpoint}/${shipmentType}/Arraived/${id}/1abd4487-3b20-4824-bd84-b7c93b312f38`
  );

const getByColumn = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}/${shipmentType}bulk/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const linkOrder = (shipmentType, schema) =>
  client.put(`${endpoint}/${shipmentType}bulk/linkorder`, schema);

const getOrdersOnBulk = (
  shipmentType,
  bulkPckgNum,
  countryCode,
  pageNo,
  docsPerPage,
  OrderNumber
) =>
  client.get(
    `${endpoint}/${shipmentType}bulk/GetOrdersOnBulk?number=${bulkPckgNum}&OrderNumber=${OrderNumber}&countryCode=${countryCode}&Page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const deleteOrders = (shipmentType, id, schema) =>
  client.put(`${endpoint}/${shipmentType}bulk/deleteOrders/${id}`, schema);

const updateStation = (shipmentType, id, date) =>
  client.put(
    `${endpoint}/${shipmentType}bulk/updateStation/${id}?date=${date}`
  );

export default {
  getAllBulks,
  bulkDetails,
  uploadImg,
  getCustomers,
  getClients,
  createBulk,
  findBulkByCode,
  updateBulkArrivalDate,
  arrivedBulk,
  getByColumn,
  linkOrder,
  getOrdersOnBulk,
  deleteOrders,
  updateStation,
};
