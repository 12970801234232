import client from "./client";

const endpoint = "/v1/News";

const getNews = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const createNews = (schema) => {
  const data = new FormData();
  data.append("title", schema?.title);
  // data.append("isActive", schema?.isActive);
  data.append("descraption", schema?.descraption);
  data.append("image", schema.image);
  return client.post(`${endpoint}`, data);
};

const updateNews = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const deactivate = (id) => client.put(`${endpoint}/status/${id}`);

export default {
  getNews,
  createNews,
  updateNews,
  deactivate
};
