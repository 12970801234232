import client from "./client";

const endpoint = "/v1/user";

const preload = (page, docsPerPage) =>
  client.get(`${endpoint}/preload?Page=${page}&PostsPerPage=${docsPerPage}`);

const generatePass = (schema) => client.post(`${endpoint}`, schema);

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getGenerateUsers = (page, docsPerPage) =>
  client.get(
    `${endpoint}/GenerateurUsers?Page=${page}&PostsPerPage=${docsPerPage}`
  );

const resetPassword = (id) => client.put(`${endpoint}/restpassword/${id}`);

const generateUser = id => client.put(`${endpoint}/generateurUsers?id=${id}`);

export default {
  preload,
  generatePass,
  getByColumn,
  getGenerateUsers,
  resetPassword,
  generateUser,
};
