import { useContext, useState } from "react";
import { AdvanceSearchContext } from "../context/AdvanceSearchContext";

const useAdvanceSearch = (pageName, editResponse = () => {}) => {
  const ctx = useContext(AdvanceSearchContext);
  const checkPrev = () => ctx.pageName === pageName;
  const [key, setKey] = useState(checkPrev() ? +ctx.key : 0);
  const [pageNo, setPageNo] = useState(checkPrev() ? +ctx.pageNo : 1);
  const [docsPerPage, setDocsPerPage] = useState(
    checkPrev() ? +ctx.docsPerPage : 10
  );
  const [header, setHeader] = useState(checkPrev() ? ctx.header : []);
  const [column, setColumn] = useState(checkPrev() ? ctx.column : []);

  const [openPopup, setOpenPopup] = useState(false);
  const [searchField, setSearchField] = useState(
    checkPrev() ? ctx.searchField : []
  );

  const [colIndex, setColIndex] = useState(checkPrev() ? ctx.colIndex : 0);

  const [isRemove, setIsRemove] = useState(false);

  const register = (obj) => {
    ctx.setItems(obj);
  };

  const resetAdvanceFilter = () => {
    setKey(0);
    setPageNo(1);
    setDocsPerPage(10);
    setHeader([]);
    setColumn([]);
    setSearchField([]);
    register({
      key: 0,
      pageNo: 1,
      docsPerPage: 10,
      header: [],
      column: [],
      searchField: [],
      colIndex: 0,
      visitedRowId: "",
    });
  };

  const changeHandler = (text) => {
    setSearchField((prevVal) => {
      const newVal = [...prevVal];
      if (newVal[colIndex] !== undefined) newVal[colIndex] = text;
      else newVal.push(text);
      return newVal;
    });
  };

  // This function triggers when the user click on header in the table
  const onHeaderClick = (selectedHeader, selectedColumn) => {
    const index = header.findIndex((item) => item === selectedHeader);

    const headers = [...header];
    const columns = [...column];
    // 1) Open the popup
    setOpenPopup(true);

    if (index > -1) {
      register({ colIndex: index });
      return setColIndex(index);
    }
    headers.push(selectedHeader);
    columns.push(selectedColumn.split("Text")[0]);

    // 2) Store the header name to use it for search
    setHeader(headers);
    // 3) Store column name to display it in the popup
    /*
      if the header the first item is 'actions' that means we should decreace 1 from the columns[index]
      because then the headers will have more item than the columns by 1
    */
    setColumn(columns);

    setColIndex(columns.length - 1);

    register({
      column: columns,
      header: headers,
      pageName,
      searchField,
      colIndex: columns.length - 1,
    });
  };

  const handleColumnName = (column) => {
    if (column === "branch.name" || column === "currentBranch.name")
      return "branchName";
    if (column.includes("Text")) return column.split("Text")[0];
    if (column.includes("."))
      return column.split(".")[column.split(".").length - 1];
    return column;
  };

  const handleValue = (contet) => {
    if (contet === "true") return true;
    if (contet === "false") return false;
    return contet;
  }

  const searchHandler = async (api, ...params) => {
    const schema = {};
    if (searchField.length) {
      searchField.forEach((content, index) => {
        if (content) schema[handleColumnName(column[index])] = handleValue(content);
      });
    }

    const res = await api.request(schema, ...params);
    if (res.status === 200) {
      res.data?.data?.forEach(editResponse);
      return res;
    }
  };

  const storeId = (rowId) => {
    register({ visitedRowId: rowId });
  };

  const handleRemove = (index = -1, refresh = true) => {
    if (index < 0) return;
    const headers = [...header];
    const columns = [...column];
    const searched = [...searchField];
    headers.splice(index, 1);
    columns.splice(index, 1);
    searched.splice(index, 1);
    setHeader(headers);
    setColumn(columns);
    setSearchField(searched);
    if (refresh) setIsRemove(true);
    register({ header: headers, column: columns, searchField: searched });
  };

  return {
    header,
    column,
    openPopup,
    setOpenPopup,
    key,
    setKey,
    pageNo,
    colIndex,
    searchField,
    setPageNo,
    register,
    docsPerPage,
    setDocsPerPage,
    onHeaderClick,
    searchHandler,
    setSearchField,
    changeHandler,
    isRemove,
    setIsRemove,
    handleRemove,
    storeId,
    resetAdvanceFilter,
    checkPrev
  };
};

export default useAdvanceSearch;
