export default {
  page: {
    ar: "تفاصيل التجميعة",
    en: "Bulk details",
  },
  selected: {
    ar: "اختير",
    en: "Selected",
  },
  customers: {
    ar: "الزباين",
    en: "Customer",
  },
  arrivalDate: {
    ar: "تاريخ الوصول ( تحديث اسبوعي )",
    en: "Arrival date ( weekly update )",
  },
  arrived: {
    ar: "تم الوصول",
    en: "Arrived",
  },
  updateDate: {
    ar: "تحديث التاريخ",
    en: "Update date",
  },
  arrivedPopup: {
    ar: "هل أنت متأكد أنك تريد تغيير حالة الشحنة إلى تم الايصال ؟",
    en: "Are you sure you want to change shipment state to arrived ?",
  },
  datePopup: {
    ar: "هل أنت متأكد أنك تريد تحديث تاريخ الوصول إلى",
    en: "Are you sure you want to update the arrival date to",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف",
    en: "Are you sure you want to Delete",
  },
  orders: {
    ar: "الطلبات ؟",
    en: "Orders ?",
  },
  trackNumber: {
    ar: "رقم الشاحنة",
    en: "Track Number",
  },
  trackLine: {
    ar: "مسار الشاحنة",
    en: "Track Line",
  },
  station: {
    ar: "المحطه",
    en: "Station",
  },
  currentStation: {
    ar: "المحطه الحالية",
    en: "Current station",
  },
  nextStation: {
    ar: "المحطه القادمة",
    en: "Next station",
  },
  unknown: {
    ar: "غير معرف",
    en: "Unkonw",
  },
  stationDate: {
    ar: "تاريخ الوصول للمحطه",
    en: "Date of arrival at the station",
  },
  nextStationDate: {
    ar: "تاريخ الوصول للمحطه القادمة",
    en: "Date of arrival at the next station",
  },
};
