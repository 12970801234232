export default {
  isApproved: {
    ar: "هل تمت الموافقة",
    en: "Is Approved"
  },
  aircargo: {
    ar: "الفواتير الجوية",
    en: "Invoice air"
  },
  lclcargo: {
    ar: "الفواتير LCL",
    en: "Invoice LCL"
  },
  fcl: {
    ar: "الفواتير FCL",
    en: "Invoice FCL"
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذه الفاتورة ؟",
    en: "Are you sure you want to delete this invoice ?"
  },
  approvePopup: {
    ar: "هل انت متأكد من انك تريد الموافقة على هذه الفاتورة ؟",
    en: "Are you sure you want to approve this invoice ?"
  },
  valueDollar: {
    ar: "القيمة بالدولار",
    en: "Value dollar"
  },
  valueYuan: {
    ar: "القيمة بالايوانة",
    en: "Value yuan"
  },
  rcDollar: {
    ar: "عمولة الحوالات دولار",
    en: "Remittance Commission Dollar"
  },
  rcDinar: {
    ar: "عمولة الحوالات دينار",
    en: "Remittance Commission Dinar"
  },
  detials: {
    ar: 'تفاصيل الفاتورة',
    en: "Invoice details"
  },
  wrongContact: {
    ar: "جهة اتصال خاطئة",
    en: "wrong contact"
  },
  swiftImage: {
    ar: "صورة سريعة",
    en: "Swift image"
  },
  swiftAttachments: {
    ar: "مرفقات سريعة",
    en: "Swift attachments"
  },
  swiftPopup: {
    ar: "هل أنت متأكد أنك تريد الموافقة على هذا بدون صورة ؟",
    en: "Are you sure you want to approve this without an image ?"
  },
  wrongContactPopup: {
    ar: "هل أنت متأكد من أن هذه جهة اتصال خاطئة؟",
    en: "Are you sure this is a wrong contact ?"
  },
  sellerPopup: {
    ar: "هل أنت متأكد أنك تريد إرسال إشعار للاتصال بالتاجر ؟",
    en: " Are you sure you want to send a notification to contact the seller ?"
  }
}