export default {
  pageName: {
    ar: "بيانات المستخدم",
    en: "User Details"
  },
  username: {
    ar: "اسم المستخدم",
    en: "Username"
  },
  email: {
    ar: "البريد الالكتروني",
    en: "Email"
  },
  companyName: {
    ar: "اسم الشركة",
    en: "Company name"
  },
  userType: {
    ar: "نوع المستخدم",
    en: "User type"
  }
}