import React from "react";
import classes from "./PrintTable.module.css";
import { UncontrolledTooltip } from "reactstrap";

const PrintTable = (props) => {
  const nestedObj = (object, columns) => {
    // 1) get all the keys as an array
    const keys = columns.split(".");
    // 2) loop throw the keys with the object parameters as an default value
    const nestedVal = keys.reduce(
      (obj, prop) => (obj[prop] ? obj[prop] : " "),
      object
    );
    // 3) return the nested value
    return nestedVal;
  };

  const data = props.data || [];

  const headers = [...props.headers] || [];
  const index = headers.findIndex((item) => item.toLowerCase() === "actions");
  if (index > -1) headers.splice(index, 1);

  return (
    <table className={classes.printTable}>
      <thead>
        <tr>
          {headers?.map((header) => (
            <th key={header} scope="col">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {props.columns?.map((column, index) => (
              <td data-label="Account" key={index} className={classes.cell}>
                {column.includes(".") ? nestedObj(item, column) : item[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PrintTable;
