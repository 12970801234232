import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router";
import { Row, Col, FormGroup, Input } from "reactstrap";
import content from "../content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const EditPackagesInBulk = ({
  control,
  errors,
  watch,
  setValue,
  isEditPrice,
}) => {
  const { shipmentType } = useParams();
  const isAir = shipmentType === "1" ? true : false;
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  // const resize = watch(isAir ? "resizeWeight" : "resizeVolume");
  const resize =
    watch("resizeWeight") ||
    watch("resizeVolume") ||
    watch("resizeNumberOfPkgs")
      ? true
      : false;

  return (
    <Row>
      {isEditPrice && (
        <>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="editPrice">
                {commenContent.editPrice[culture]}
              </label>
              <Controller
                control={control}
                name="editPrice"
                rules={{
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  },
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="name"
                    type="text"
                    className={errors.editPrice && "error"}
                    value={value || ""}
                    onChange={({ target: { value } }) => {
                      if (!value) setValue("editPriceNote", "");
                      setValue(isAir ? "resizeWeight" : "resizeVolume", 0);
                      onChange(value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Col>
          {watch("editPrice") > 0 && (
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="agregation">
                  {content.editPriceNote[culture]}
                </label>
                <Controller
                  control={control}
                  name="editPriceNote"
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="name"
                      type="textarea"
                      className={errors.editPriceNote && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          )}
        </>
      )}
      {!isEditPrice && (
        <>
          {" "}
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="resize">
                {isAir
                  ? content.resizeWeight[culture]
                  : content.resizeVolume[culture]}
              </label>
              <Controller
                control={control}
                name={isAir ? "resizeWeight" : "resizeVolume"}
                rules={{
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  },
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="resize"
                    type="text"
                    className={
                      errors[isAir ? "resizeWeight" : "resizeVolume"] && "error"
                    }
                    value={value || ""}
                    onChange={({ target: { value } }) => {
                      setValue("editPrice", 0);
                      onChange(value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Col>
          {resize && (
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="resizeNumberOfPkgs"
                >
                  {content.resizeNumberOfPkg[culture]}
                </label>
                <Controller
                  control={control}
                  name="resizeNumberOfPkgs"
                  rules={
                    resize && {
                      required: "resizeNumberOfPkgs field is required",
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      },
                    }
                  }
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="resizeNumberOfPkgs"
                      type="text"
                      className={errors.resizeNumberOfPkgs && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default EditPackagesInBulk;
