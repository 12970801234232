import React, { Fragment, useEffect, useState } from "react";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import bulks from "api/bulks";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useAbility from "hooks/useAbility";

const DeleteCustomerPopup = ({
  deleteBulk,
  setDeleteBulk,
  shipmentType,
  bulkPckgNum,
  countryCode,
  sweetAlert,
  bulkId,
  canDelete = false,
}) => {
  // Apis
  const getOrdersOnBulkApi = useApi(bulks.getOrdersOnBulk);

  // States
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [orderNumber, setOrderNumber] = useState("");
  const [loading, setLoading] = useState(true);

  const checkAbility = useAbility(
    `${shipmentType === "aircargo" ? "AirCargo" : "LCL"}Bulk`
  );

  const headers = ["actions", "Name", "Code", "Order Number", "No pkg"];
  const columns = [
    "customer.customerName",
    "customer.customerCode",
    "orderNumber",
    "numberOfPkgs",
  ];

  if (shipmentType === "aircargo") {
    headers.push("weight");
    columns.push("weight");
  }

  if (shipmentType === "lcl") {
    headers.push("volume");
    columns.push("volume");
  }

  const editRespone = (row) => {
    if (row.restId !== null) {
      if (row.restId === bulkId) {
        row.weight = row.restlWeight;
        row.volume = row.restVolume;
        row.numberOfPkgs = row.restNumberOfPkgs || row.restNoOfPkgs;
      } else {
        row.weight = row.weightFromRest;
        row.volume = row.volumeFromRest;
        row.numberOfPkgs = row.numberOfPkgsFromRest;
      }
    }
    if (row.restId === null && row.followUpStatus === 6) {
      row.weight = row.weightFromRest;
      row.volume = row.volumeFromRest;
      row.numberOfPkgs = row.numberOfPkgsFromRest;
    }
  
    row.orderNumber = `${row.country?.countryCode}-${row.orderNumber}${shipmentType.toLowerCase() === "aircargo" ? "AC" : "LC"}`;
  };

  const getAllOrders = async (event) => {
    event && event.preventDefault();
    const response = await getOrdersOnBulkApi.request(
      shipmentType,
      bulkPckgNum,
      countryCode,
      pageNo,
      docsPerPage,
      orderNumber
    );
    if (response.ok) {
      response.data.data.forEach((item) => editRespone(item));
      setData(response.data.data);
    }
    setLoading(false);
  };

  const renderDelete = (obj) => {
    return (
      !deleteBulk.find((row) => row.id === obj.id) &&
      checkAbility("delete") &&
      canDelete
    );
  };

  // this function render 'x' icon
  // but this will only call if multiple is false
  const renderCancel = (obj) => {
    return deleteBulk.find((bulk) => bulk.id === obj.id);
  };

  const handleUnselect = (obj) => {
    setDeleteBulk((bulk) => bulk.filter((row) => row.id !== obj.id));
  };

  useEffect(() => {
    setLoading(true);
    getAllOrders();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
      getOrdersOnBulkApi.error &&
      getOrdersOnBulkApi.data &&
      sweetAlert(getOrdersOnBulkApi.data, true),
    [getOrdersOnBulkApi.data]
  );

  const handleDelete = (obj) => {
    const weight = obj.followUpStatus === 6 ? obj.weightFromRest : obj.weight;
    const volume = obj.followUpStatus === 6 ? obj.volumeFromRest : obj.volume;

    setDeleteBulk((bulks) => [
      ...bulks,
      {
        orderNumber: obj.orderNumber,
        id: obj.id,
        numberOfPkgs: obj.numberOfPkgs,
        weight,
        volume
      },
    ]);
  };
  return (
    <div className="popup-bigger">
      {loading && <Spinner gate="#29bb64" bg="#fff" />}
      <div>
        <div className="pd-sides-1">
          <Row>
            <Fragment>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="client">
                    Order number
                  </label>
                  <Input
                    id="client"
                    type="text"
                    onChange={(e) => setOrderNumber(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && getAllOrders()}
                  />
                </FormGroup>
              </Col>
              <span style={{ paddingTop: 7 }}>
                <Button
                  color="success"
                  type="button"
                  className="mt-4"
                  onClick={getAllOrders}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-search" />
                  </span>
                  <span>Search</span>
                </Button>
              </span>
            </Fragment>
          </Row>
        </div>
        <ul className="popup-customer-list pd-sides-1">
          {deleteBulk.map((bulk) => (
            <li key={bulk.id}>
              <i
                className="fas fa-times hover-danger"
                onClick={handleUnselect.bind(null, bulk)}
              />
              {bulk.orderNumber}
            </li>
          ))}
        </ul>
        <Pagination
          dataLength={data?.length || 0}
          pageNo={pageNo}
          setPageNo={setPageNo}
          docsPerPage={docsPerPage}
          setDocsPerPage={setDocsPerPage}
        >
          <Table
            data={data}
            setData={setData}
            headers={headers}
            columns={columns}
            // clients={clients}
            renderCancel={renderCancel}
            handleDelete={handleDelete}
            renderDelete={renderDelete}
            handleUnselect={handleUnselect}
            isPrint={false}
          >
            <span className="m-1" fun="handleDelete" condition="renderDelete">
              <div id="delete" className="table-action cursor-pointer">
                <i className="fas fa-trash fa-lg hover-danger"></i>
              </div>
            </span>
            <span
              className="me-1"
              fun="handleUnselect"
              condition="renderCancel"
            >
              <div id="remove" className="table-action cursor-pointer">
                <i className="fas fa-times fa-lg hover-danger padding-half" />
              </div>
            </span>
          </Table>
        </Pagination>
      </div>
    </div>
  );
};

export default DeleteCustomerPopup;
