import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import FilePicker from "components/common/FilePicker";
import template from "./templateApi";
import useApi from "hooks/useApi";
import useAlert from "hooks/useAlert";
import Spinner from "../../components/Spinner";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Environment = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const [cards, setCards] = useState([0]);
  const [spinner, setSpinner] = useState(false);

  const templateApi = useApi(template.getPortfolio);
  const environmentApi = useApi(template.postEnvironmentApi);

  const { alert, sweetAlert } = useAlert();

  const handleAddRow = () => {
    const newCards = [...cards, cards.length];
    setCards(newCards);
  };

  const handleDeleteRow = (index) => {
    const newCards = [...cards];
    newCards.splice(index, 1);
    setCards(newCards);
  };

  // const preload = async () => {
  //   const res = await templateApi.request();

  //   if (res.status === 200) {
  //     const resData = res.data.data?.cards;

  //     const cardArrays = [];

  //     for (let i = 0; i < resData.length; i++) {
  //       cardArrays.push(i);
  //     }

  //     setCards(cardArrays);

  //     resData.forEach((item, index) => {
  //       setValue(`cardName-${index}`, item.name || "");
  //       setValue(`cardContent-${index}`, item.content || "");
  //       setValue(`cardArName-${index}`, item.arName || "");
  //       setValue(`cardArContent-${index}`, item.arContent || "");
  //     });
  //   }
  // };

  const submit = async (data) => {
    setSpinner(true);

    const formData = new FormData();

    for (let i = 0; i < data.environment.length; i++) {
      formData.append("environment", data.environment[i])
    }
    // for (const key in data) {
    //   formData.append("environment", data[key][0]);
    // }

    const res = await environmentApi.request(formData);
    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  // useEffect(() => {
  //   preload();
  // }, []);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  const imgChecker = () => {
    for (let i = 0; i < cards.length; i++) {
      if (!watch(`environment`)) return true;
    }
    return false;
  };

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {environmentApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.environment[culture]}</h3>
            </div>
          </CardHeader>
        </Card>
        <form onSubmit={handleSubmit(submit)}>
          <Card>
            <CardBody>
              {/* {cards.length > 1 && (
                <Button
                  color="danger"
                  type="button"
                  className="mb-4"
                  style={{ marginLeft: "auto", display: "block" }}
                  onClick={() => handleDeleteRow(item)}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-trash" />
                  </span>
                </Button>
              )} */}
              <Row>
                <Col xs="12">
                  <label className="form-control-label" htmlFor="arSubText">
                    {content.cardPic[culture]}
                  </label>
                  <FilePicker
                    images={watch(`environment`) || {}}
                    setImages={(img) => setValue(`environment`, img)}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Button color="info" type="button" className="me-1" onClick={handleAddRow}>
                <span>{content.addImage[culture]}</span>
              </Button>
              <Button
                color="success"
                type="submit"
                className={imgChecker() ? "disabled" : ""}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </CardBody>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default Environment;
