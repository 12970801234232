import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import FilePicker from "components/common/FilePicker";
import template from "./templateApi";
import useApi from "hooks/useApi";
import Spinner from "../../components/Spinner";
import useAlert from "hooks/useAlert";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Header = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  
  const [spinner, setSpinner] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const templateApi = useApi(template.getPortfolio);
  const headerApi = useApi(template.postHeader);

  const { alert, sweetAlert } = useAlert();

  const preload = async () => {
    const res = await templateApi.request();

    if (res.status === 200) {
      setValue("header", res.data.data?.header || "");
      setValue("arHeader", res.data.data?.arHeader || "");
      setValue("subHeader", res.data.data?.subHeader || "");
      setValue("arSubHeader", res.data.data?.arSubHeader || "");
    }
  };

  const submitHeader = async (data) => {
    setSpinner(true);
    const formData = new FormData();
    for (const key in data) {
      if (key === "headerImg") formData.append(key, data[key][0]);
      else formData.append(key, data[key]);
    }

    const res = await headerApi.request(formData);

    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const images = watch("headerImg") || {};

  useEffect(() => {
    preload();
  }, []);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {headerApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.headerContent[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(submitHeader)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      {content.header[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="header"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="name"
                          placeholder={content.header[culture]}
                          type="text"
                          className={errors.header && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                    {content.subText[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="subHeader"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="name"
                          placeholder={content.subText[culture]}
                          type="textArea"
                          className={errors.subText && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4" className="rtl-col">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="arHeader">
                    {content.arHeader[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="arHeader"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="arHeader"
                          placeholder={content.arHeader[culture]}
                          type="text"
                          className={errors.arHeader && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="rtl-col">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="arSubText">
                    {content.arSubText[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="arSubHeader"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="arSubText"
                          placeholder={content.arSubText[culture]}
                          type="textArea"
                          className={errors.arSubText && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <label className="form-control-label" htmlFor="arSubText">
                  {content.bg[culture]}
                  </label>
                  <FilePicker
                    multiple={false}
                    images={images}
                    setImages={(img) => setValue("headerImg", img)}
                  />
                </Col>
              </Row>
              <Button color="success" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Header;
