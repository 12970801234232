import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Col,
  CardHeader,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import { useParams, useLocation } from "react-router";
import { useForm, Controller } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import packagesInBulk from "api/packagesInBulk";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import EditPackagesInBulk from "./components/EditPackagesInBulk";
import useAbility from "hooks/useAbility";
import BulkFilter from "./components/BulkFilter";
import Print from "components/common/Print";
import env from "env";
import content from "./content";
import commenContent from "components/common/content";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import Filter from "./components/Filter";
import usePackageFilter from "./usePackageFilter";
import DepositeForm from "./components/DepositeForm";
import entry from "api/entry";

const PackagesInBulk = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const location = useLocation();
  const { shipmentType } = useParams();

  const isAir = shipmentType === "1" ? true : false;

  const weightOrVolume = isAir
    ? commenContent.weight[culture]
    : commenContent.volume[culture];

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    commenContent.customerCode[culture],
    commenContent.name[culture],
    commenContent.numOfPackages[culture],
    commenContent.commodityType[culture],
    weightOrVolume,
    commenContent.price[culture],
    commenContent.editPrice[culture],
    content.editPriceNote[culture],
    isAir ? content.resizeWeight[culture] : content.resizeVolume[culture],
    content.resizeNumberOfPkgs[culture],
    content.aggregation[culture],
    commenContent.total[culture],
    content.orderPrice[culture],
  ]);
  const [columns, setColumns] = useState([
    "orderNumber",
    "customer.customerCode",
    "customer.customerName",
    "numberOfPkgs",
    "commodityType",
    isAir ? "weight" : "volume",
    "price",
    "editPrice",
    "editPriceNote",
    [isAir ? "resizeWeight" : "resizeVolume"],
    "resizeNumberOfPkgs",
    "agregation",
    "totalPrice",
    "orderPrice",
  ]);

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [bulkNumber, setBulkNumber] = useState();
  const [bulkId, setBulkId] = useState("");
  const [printData, setPrintData] = useState([]);
  const [printRow, setPrintRow] = useState(false);
  const [printTable, setPrintTable] = useState(false);
  const [isPaidModal, setIsPaidModal] = useState(false);
  const [resizeModal, setResizeModal] = useState(false);
  const [depositeModal, setDepositeModal] = useState(false);

  const checkAbility = useAbility("PkgsBulks");
  const { alert, sweetAlert } = useAlert();

  const packagesApi = useApi(packagesInBulk.packages);
  const updatePackageApi = useApi(packagesInBulk.updatePackage);
  const printCustomerApi = useApi(packagesInBulk.printCustomer);
  const paidApi = useApi(packagesInBulk.paid);
  const cargoCreateAndConfirmApi = useApi(entry.cargoCreateAndConfirm);

  const {
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors, isDirty: editIsDirty },
    control: editControl,
    setValue: editSetValue,
    watch: editWatch,
    reset: editReset,
  } = useForm();

  const {
    handleSubmit: printHandleSubmit,
    setValue: printSetValue,
    watch: printWatch,
    reset: printReset,
  } = useForm();

  const {
    handleSubmit: depositeHandleSubmit,
    setValue: depositeSetValue,
    watch: depositeWatch,
    reset: depositeReset,
    formState: { errors: depositeError },
    control: depositeControl,
  } = useForm();

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const editResponse = (row) => {
    if (row.restId !== null) {
      if (row.restId === bulkId) {
        row.weight = row.restlWeight;
        row.volume = row.restVolume;
        row.numberOfPkgs = row.restNumberOfPkgs || row.restNoOfPkgs;
        row.price = row.restPrice;
        row.totalPrice = row.restTotalPrice;
        row.agregation = row.restAgregation;
      } else {
        row.weight = row.weightFromRest;
        row.volume = row.volumeFromRest;
        row.numberOfPkgs = row.numberOfPkgsFromRest;
      }
    }
    if (row.restId === null && row.followUpStatus === 6) {
      row.weight = row.weightFromRest;
      row.volume = row.volumeFromRest;
      row.numberOfPkgs = row.numberOfPkgsFromRest;
    }

    row.price = row.price?.toFixed(2);
    row.totalPrice = row.totalPrice?.toFixed(2);
    row.agregation = row.agregation?.toFixed(2);

    row.orderNumber = `${row.country?.countryCode}-${row.orderNumber}${
      isAir ? "AC" : "LC"
    }`;

    row.commodityType = env.commidityType[row.commodityType - 1];
    return row;
  };

  const getOrdersInBulk = async () => {
    const res = await packagesApi.request(
      shipmentType,
      bulkNumber,
      watch("countryCode"),
      pageNo,
      docsPerPage
    );
    if (res.status === 200) {
      res.data.data.forEach((row) => editResponse(row));
      setData(res.data.data);
    }
  };

  const editOrder = async (formData) => {
    setEditModal(false);
    setResizeModal(false);
    setSpinner(true);
    const schema = {};
    // Convert every attribute to a number and store it in the schema
    Object.keys(formData).forEach((key) => {
      if (key === "editPriceNote") return (schema[key] = formData[key]);
      if (formData[key]) schema[key] = +formData[key];
    });

    const res = await updatePackageApi.request(shipmentType, rowId, schema);

    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      editResponse(res.data.data);
      newData[index] = res.data.data;
      setData(newData);
      sweetAlert("Done");
    }
    editReset();
    setSpinner(false);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setBulkModal(false);
    setPrintModal(false);
    setPrintTable(false);
    setPrintRow(false);
    setIsPaidModal(false);
    setResizeModal(false);
    setDepositeModal(false);
    editReset();
    printReset();
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editReset();
    editSetValue("editPrice", obj.editPrice);
    // editSetValue("agregation", obj.agregation);
    // editSetValue("resizeWeight", obj.resizeWeight);
    // editSetValue("resizeNumberOfPkgs", obj.resizeNumberOfPkgs);
    setEditModal(true);
  };

  const handleResize = (obj) => {
    setRowId(obj.id);
    editReset();
    editSetValue("resizeWeight", obj.resizeWeight);
    editSetValue("resizeNumberOfPkgs", obj.resizeNumberOfPkgs);
    setResizeModal(true);
  };

  const handlePrint = (obj) => {
    setPrintModal(true);
    printSetValue("customerCode", obj.customer?.customerCode);
  };

  const renderIsPaid = (obj) =>
    !obj.isPaid && checkAbility("paid", "PkgsBulks");

  const handleIsPaid = (obj) => {
    setRowId(obj.id);
    setIsPaidModal(true);
  };

  const handleDeposite = (obj) => {
    setRowId(obj.id);
    setDepositeModal(true);
  };

  const handlePrintData = async () => {
    setPrintModal(false);
    setSpinner(true);
    const shippingType = isAir ? "air" : "lcl";
    const res = await printCustomerApi.request(
      shippingType,
      bulkId,
      customerCode || ""
    );
    if (res.status === 200) {
      const subField = isAir ? "airCargos" : "lclCargos";
      res.data.data = res.data?.data[subField] || [];
      // Normal condition
      res.data.data.forEach(editResponse);
      // and Adding some extra condition
      res.data.data.forEach((item) => {
        item.price = item.editPrice > 0 ? item.editPrice : item.price;
        item.volume =
          item.resizeVolume > 0 ? (
            <>
              {item.resizeVolume}
              <sub className="text-danger">(resize)</sub>
            </>
          ) : (
            item.volume
          );
        item.weight =
          item.resizeWeight > 0 ? (
            <>
              {item.resizeWeight}
              <sub className="text-danger">(resize)</sub>
            </>
          ) : (
            item.weight
          );
      });
      setPrintData(res.data.data);
    }
    setSpinner(false);
  };

  const payInvoice = async () => {
    setSpinner(true);
    setIsPaidModal(false);
    const res = await paidApi.request(shipmentType, rowId);
    if (res.status === 200) {
      const newData = [...data];
      const row = newData.find((item) => item.id === rowId);
      row.isPaid = true;
      newData[0].debit = newData[0].debit - +row.totalPrice;
      newData[0].credit = newData[0].credit + +row.totalPrice;
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };
  const editRenderer = () => checkAbility("edit");

  const isSameCustomer = printData.every(
    (item) => item.customer?.id === printData[0]?.customer?.id
  );

  const printBody = [
    {
      text: "رقم الرحلة Bulk NO.",
      value: watch("bulkPckgNumShown"),
    },
    {
      text: "قادمة من Shipped from",
      value: watch("country"),
    },
    isSameCustomer &&
      printData.length && {
        text: "رقم هاتف الزبون",
        value: printData[0].customer.phone,
      },
  ];

  const totla = printData
    .reduce(
      (prev, next) =>
        prev +
        (+next.agregation
          ? Number(next.agregation)
          : Number(next.totalPrice) || 0),
      0
    )
    ?.toFixed(2);

  const printFooter = [
    {
      text: "الاجمالي",
      value: "$" + totla,
    },
  ];

  const createDeposite = async (formData) => {
    setSpinner(true);
    setDepositeModal(false);
    const customerId = data.find((row) => row.id === rowId)?.customerId ?? "";
    const schema = { ...formData, customerId };
    const res = await cargoCreateAndConfirmApi.request(schema);
    if (res.status === 200) sweetAlert(commenContent.done[culture]);
    setRowId("");
    depositeReset();
    setSpinner(false);
  };

  const customerCode = printWatch("customerCode");

  const filterHandler = usePackageFilter(
    setData,
    isAir ? "air" : "lcl",
    getOrdersInBulk
  );

  useEffect(() => {
    if (bulkNumber) getOrdersInBulk();
  }, [bulkNumber, pageNo, docsPerPage]);

  useEffect(() => {
    if (printTable) handleCancel();
    if (printRow) handleCancel();
  }, [printTable, printRow]);

  useEffect(() => {
    if (!printData.length) return;

    if (bulkId && customerCode) {
      return setPrintRow(true);
    }

    setPrintTable(true);
  }, [printData]);

  useEffect(() => {
    setData([]);
    setBulkNumber("");
    setBulkId("");
    setValue("bulks", "");
    setValue("numberOfPkgs", "");
    setValue(isAir ? "weight" : "volume", "");
    setValue("bulkPckgNumShown", "");
  }, [location]);

  if (packagesApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {packagesApi.errorAlert}
      {updatePackageApi.errorAlert}
      {printCustomerApi.errorAlert}
      {cargoCreateAndConfirmApi.errorAlert}
      {paidApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader
        parents={[
          `${content.packagesInBulk[culture]}${" "}
        ${isAir ? (culture === "ar" ? "الجوية" : "Air") : "LCL"}`,
        ]}
      />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.packagesInBulk[culture]}{" "}
                {isAir ? (culture === "ar" ? "الجوية" : "Air") : "LCL"}
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <Print
              headers={headers}
              printBody={printBody}
              printFooter={printFooter}
              data={printData}
              columns={columns}
              click={printRow}
              none
            />
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="bulks">
                    {content.bulks[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="bulkPckgNumShown"
                    rules={{ required: "Bulks feild is required" }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="country"
                        type="text"
                        className={errors.country && "error"}
                        value={value || ""}
                        placeholder={`-- ${commenContent.selectOption[culture]} --`}
                        onChange={({ target: { value } }) => onChange(value)}
                        onClick={() => setBulkModal(true)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="full">
                    {content.theAmountPaid[culture]}
                  </label>
                  <Input
                    id="full"
                    type="text"
                    value={data[0]?.credit || 0}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="paid">
                    {content.theRemainingAmount[culture]} (حسب الذين تم تسعيرهم)
                  </label>
                  <Input
                    id="paid"
                    type="text"
                    value={data[0]?.debit || 0}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="bulks">
                    Number of package
                  </label>
                  <Controller
                    control={control}
                    name="numberOfPkgs"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="country"
                        type="text"
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        readOnly
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="bulks">
                    {isAir
                      ? commenContent.weight[culture]
                      : commenContent.volume[culture]}
                  </label>
                  <Controller
                    control={control}
                    name={isAir ? "weight" : "volume"}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="country"
                        type="text"
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        readOnly
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
            max
          >
            <Filter filterHandler={filterHandler} bulkId={bulkId} />
            <Print
              headers={headers}
              printBody={printBody}
              printFooter={printFooter}
              click={printTable}
              data={printData}
              columns={columns}
              onClick={() => handlePrintData()}
            />
            <Table
              pageName={`package-in-bulk-${isAir ? "Air" : "LCL"}`}
              isPrint={false}
              headers={headers}
              columns={columns}
              setHeaders={setHeaders}
              setColumns={setColumns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              handleEdit={handleEdit}
              handleResize={handleResize}
              handlePrint={handlePrint}
              editRenderer={editRenderer}
              handleIsPaid={handleIsPaid}
              renderIsPaid={renderIsPaid}
              handleDeposite={handleDeposite}
            >
              <span fun="handleEdit" className="me-1" condition="editRenderer">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info" />
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commenContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                fun="handleResize"
                className="me-1"
                condition="editRenderer"
              >
                <div id="resize" className="table-action cursor-pointer">
                  <i className="fas fa-expand fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="resize">
                  {content.resize[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handlePrint" className="me-1">
                <div id="print1" className="table-action cursor-pointer">
                  <i className="fas fa-print fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="print1">
                  {commenContent.print[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleIsPaid"
                condition="renderIsPaid"
              >
                <div id="pay" className="table-action cursor-pointer">
                  {/* <i className="fas fa-plus fa-lg hover-success" /> */}
                  <i className="fas fa-dollar-sign fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="pay">
                  {commenContent.isPaid[culture]}
                </UncontrolledTooltip>
              </span>
              {/* <span
                className="me-1"
                fun="handleDeposite"
                condition="renderIsPaid"
              >
                <div id="deposite" className="table-action cursor-pointer">
                  <i class="fas fa-hand-holding-usd fa-lg hover-danger" />
                </div>
                <UncontrolledTooltip delay={0} target="deposite">
                  {content.deposite[culture]}
                </UncontrolledTooltip>
              </span> */}
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal || resizeModal}
        text={commenContent.yes[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editOrder)}
        color="primary"
        disabled={!editIsDirty}
      >
        <Container>
          <EditPackagesInBulk
            isEditPrice={editModal}
            control={editControl}
            errors={editErrors}
            watch={editWatch}
            setValue={editSetValue}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={bulkModal}
        text={commenContent.yes[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        name={content.selectBulks[culture]}
        noBtn
      >
        <BulkFilter
          setBulkNumber={setBulkNumber}
          setBulkModal={setBulkModal}
          setValue={setValue}
          setBulkId={setBulkId}
        />
      </TextPopup>
      <TextPopup
        modal={printModal}
        handleCancel={handleCancel}
        fn={printHandleSubmit(handlePrintData)}
        color="primary"
        text={commenContent.continue[culture]}
        name={
          data.find((item) => item.customer?.customerCode === customerCode)
            ?.customer?.customerName
        }
      >
        <CardBody>
          <h2>
            {content.printCustomer[culture]}{" "}
            <span className="text-success">{customerCode}</span> data
          </h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={isPaidModal}
        handleCancel={handleCancel}
        fn={payInvoice}
        color="primary"
        text={commenContent.continue[culture]}
        name={data.find((item) => item.id === rowId)?.customer?.customerName}
      >
        <CardBody>
          <h2>
            {content.invoiceToPaid[culture]}{" "}
            {/* <span className="text-success">{customerCode}</span> */}
          </h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={depositeModal}
        handleCancel={handleCancel}
        fn={depositeHandleSubmit(createDeposite)}
        color="primary"
        text={commenContent.continue[culture]}
        name={data.find((item) => item.id === rowId)?.customer?.customerName}
      >
        <DepositeForm
          control={depositeControl}
          errors={depositeError}
          setValue={depositeSetValue}
          sweetAlert={sweetAlert}
        />
      </TextPopup>
    </div>
  );
};

export default PackagesInBulk;
