import client from './client';

const endpoint = '/v1/branch';

const preload = (page, docPerPage) => client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const branches = (page, docPerPage) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const createBranch = schema => client.post(`${endpoint}`, schema);

const findByName = (name, page, docPerPage) => client.get(`${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`);

const getByColumn = (col, val, page, docsPerPage) => (
  client.get(`${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

const deleteBranch = id => client.delete(`${endpoint}/${id}`);

const updateBranch = (id, schema) => client.put(`${endpoint}/${id}`, schema);

export default {
  preload,
  branches,
  createBranch,
  findByName,
  getByColumn,
  deleteBranch,
  updateBranch
}