import React from "react";
import { Controller } from "react-hook-form";
import { Row, Col, FormGroup, Input } from "reactstrap";
import commonContent from "components/common/content";
import content from "../content";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const EditCountry = ({ control, errors }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  return (
    <Row>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            {commonContent.name[culture]}
          </label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "name field is required",
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="name"
                placeholder="Name"
                type="text"
                className={errors.name && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="code">
            {commonContent.code[culture]}
          </label>
          <Controller
            control={control}
            name="code"
            rules={{
              required: "code field is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "only english numbers allowed",
              },
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="code"
                placeholder="Code"
                type="text"
                className={errors.code && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="areaName">
            {content.areaName[culture]}
          </label>
          <Controller
            control={control}
            name="areaName"
            rules={{
              required: "code field is required",
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="areaName"
                placeholder="Area name"
                type="text"
                className={errors.areaName && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default EditCountry;
