export default {
  orderId: {
    ar: "معرٌف الطلب",
    en: "Order Id",
  },
  customerCode: {
    ar: "كود الزبون",
    en: "Customer Code",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer Name",
  },
  rmbAmount: {
    ar: "المقدار بالعملة الصينية",
    en: "RMP Amount"
  },
  usdAmount: {
    ar: "المقدار بالعملة الامريكية",
    en: "Usd Amount"
  },
  spCode: {
    ar: "كود الsp",
    en: "spCode"
  },
  shaheenPay: {
    ar: "شاهين باي",
    en: "Shaheen Pay"
  },
  shaheenSP: {
    ar: "شاهين SP",
    en: "Shaheen SP"
  },
  currency: {
    ar: "العملة",
    en: "Currency"
  },
  yuanValue: {
    ar: "قيمة اليوان",
    en: "Yuan value"
  },
  feeDollar: {
    ar: "الرسوم بالدولار",
    en: "feeDollar"
  },
  feeDiner: {
    ar: "الرسوم بالدينار",
    en: "feeDiner"
  },
  paymentInfo: {
    ar: "معلومات الدفع",
    en: "Payment Info"
  },
  additionalPay: {
    ar: "الدفع الاضافي",
    en: "Additional Pay"
  },
  initiated: {
    ar: "بدأت",
    en: "Initiated"
  },
  waitingApproving: {
    ar: "في انتظار الموافقة",
    en: "Waiting approving"
  },
  transferred: {
    ar: "نقلت",
    en: "Transferred"
  },
  broker: {
    ar: "السمسار",
    en: "Broker"
  },
  air: {
    ar: "جوي",
    en: "Air"
  },
  lcl: {
    ar: "LCL",
    en: "LCL"
  },
  fcl: {
    ar: "FCL",
    en: "FCL"
  },
  cancelUpdate: {
    ar: "الغاء تحديث الحالة",
    en: "Cancel update state"
  },
  brokerRate: {
    ar: "تسعيرة البروكر",
    en: "Broker rate"
  }
};
