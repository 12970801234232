import useCulture from "hooks/useCulture";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import content from "../BulkDetails/content";
import env from "env";
import { Controller, useForm } from "react-hook-form";
import useApi from "hooks/useApi";
import bulks from "api/bulks";
import { useParams } from "react-router-dom";
import commonContent from "components/common/content";

function BulkStation({ data, setData, setSpinner, sweetAlert, shipmentType }) {
  const { id } = useParams();
  const culture = useCulture();
  const updateStationApi = useApi(bulks.updateStation);

  const { currentStation } = data;

  const stationNumber = currentStation?.stationNumber ?? 0;

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const handleUpdateStation = async (schema) => {
    setSpinner(true);
    const res = await updateStationApi.request(shipmentType, id, schema.date);
    if (res.status === 200) {
      setData(res.data.data);
      sweetAlert(commonContent.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const stationEnum =
    shipmentType.toLowerCase() === "aircargo"
      ? "stationNumberAir"
      : "stationNumberLcl";

  const showNextStation = stationNumber < env[stationEnum].length;

  return (
    <form onSubmit={handleSubmit(handleUpdateStation)}>
      {updateStationApi.errorAlert}
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h3 className={`mb-0 text-md-left`}>{content.station[culture]}</h3>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="station-number">
                  {content.currentStation[culture]}
                </label>
                <Input
                  id="station-number"
                  readOnly
                  value={
                    stationNumber > 0
                      ? env[stationEnum][stationNumber - 1]?.[culture]
                      : content.unknown[culture]
                  }
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="station-date">
                  {content.stationDate[culture]}
                </label>
                <Input
                  id="station-date"
                  readOnly
                  type="date"
                  value={currentStation?.date?.split("T")[0]}
                />
              </FormGroup>
            </Col>
            {showNextStation && (
              <>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="station-number"
                    >
                      {content.nextStation[culture]}
                    </label>
                    <Input
                      id="station-number"
                      readOnly
                      value={env[stationEnum][stationNumber]?.[culture]}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="station-date"
                    >
                      {content.nextStationDate[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="date"
                      rules={{ required: true }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="next-station-date"
                          type="date"
                          value={value ?? ""}
                          onChange={onChange}
                          className={errors.date ? "error" : ""}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          {showNextStation && (
            <Button color="success" type="submit">
              {commonContent.save[culture]}
            </Button>
          )}
        </CardBody>
      </Card>
    </form>
  );
}

export default BulkStation;
