import { create } from "apisauce";

const apiClient = create({
  // baseURL: "http://localhost:5000/api/v1/portfolio",
  baseURL: "https://shaheen.ggw.ly/api/v1/portfolio",
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await localStorage.getItem(btoa("token"));
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});

const getPortfolio = () => apiClient.get(`/`);

const postHeader = (body) => apiClient.patch(`/header`, body);

const postAbout = (body) => apiClient.patch(`/about`, body);

const postPlans = (body) => apiClient.patch(`/plans`, body);

const postService = (body) => apiClient.patch(`/service`, body);

const postEnvironmentApi = (body) => apiClient.patch(`/environment`, body);

export default {
  getPortfolio,
  postHeader,
  postAbout,
  postPlans,
  postService,
  postEnvironmentApi
}