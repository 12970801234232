export default {
  minimumCharge: {
    ar: "الحد الأدنى للحشن",
    en: "Minimum Charge",
  },
  airMinimumPrice: {
    ar: "أقل سعر لشحن الجوي",
    en: "Air minimum price",
  },
  lclMinimumPrice: {
    ar: "أقل سعر لشحن البحري",
    en: "LCL Minimum price",
  },
  minimumWightKg: {
    ar: "أقل وزن بالكيلوجرام",
    en: "Minimum weight kg",
  },
  minimumCBM: {
    ar: "أقل حجم CBM",
    en: "Minimum CBM",
  },
  deactivate: {
    ar: "تعطيل",
    en: "Deactivate",
  },
  activate: {
    ar: "تفعيل",
    en: "Activate",
  },
  activatePopup: {
    ar: "في حالة تفعيل هذه الاسعار سيتم الغاء تفعيل الأسعار السابقة , هل انت متاكد من انك تريد الاستمرار ؟",
    en: "If these prices are activated, the previous prices will be deactivated. Are you sure you want to continue?",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذة التسعيرة ؟",
    en: "Are you sure you want to delete these prices ?"
  },
  shippingType: {
    ar: "نوع الشحن",
    en: "Shipping type"
  }
};
