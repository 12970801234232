import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Row, Col, FormGroup, Input, Container } from "reactstrap";
import commonContent from "components/common/content";
import content from "../content";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import FilePicker from "components/common/FilePicker";
import CountryTable from "components/common/CountryTable";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";

const EditWarehouse = ({
  control,
  errors,
  editImage,
  setEditImage,
  setValue,
}) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const { alert, sweetAlert } = useAlert();
  const [countryTable, setCountryTable] = useState(false);
  return (
    <>
      {alert}
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="title">
              {commonContent.title[culture]}
            </label>
            <Controller
              control={control}
              name="title"
              rules={{
                required: "title field is required",
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="title"
                  placeholder={commonContent.title[culture]}
                  type="text"
                  className={errors.title && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="name">
              {commonContent.country[culture]}
            </label>
            <Controller
              control={control}
              name="country"
              rules={{
                required: "name field is required",
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="name"
                  placeholder={commonContent.country[culture]}
                  type="text"
                  className={errors.name && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setCountryTable(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="city">
              {content.city[culture]}
            </label>
            <Controller
              control={control}
              name="city"
              rules={{
                required: "city field is required",
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="code"
                  placeholder={content.city[culture]}
                  type="text"
                  className={errors.city && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="note">
              {content.note[culture]}
            </label>
            <Controller
              control={control}
              name="note"
              rules={{
                required: "note field is required",
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="note"
                  placeholder={content.note[culture]}
                  type="textarea"
                  className={errors.note && "error"}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col>
          <FilePicker
            images={editImage}
            setImages={setEditImage}
            multiple={false}
          />
        </Col>
      </Row>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={() => setCountryTable(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </>
  );
};

export default EditWarehouse;
