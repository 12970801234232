import { useState, useCallback, useContext, useEffect } from "react";
import { PaginationContext } from "context/PaginationContext";

const usePagination = (pageName) => {
  const ctx = useContext(PaginationContext);

  const prevPageCheck = useCallback(() => {
    if (ctx.prevPage === pageName) return true;
    return false;
  }, [pageName]);

  const [pageNo, setPageNo] = useState(prevPageCheck() ? ctx.pageNumber : 1);
  const [docsPerPage, setDocsPerPage] = useState(
    prevPageCheck() ? ctx.docsPerPage : 10
  );
  const [search, setSearch] = useState(
    prevPageCheck() ? ctx.searchedContent : ""
  );

  return {
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    search,
    setSearch,
    prevPageCheck,
  };
};

export default usePagination;
