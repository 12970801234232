import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import Tables from "./CustomTable";
import commenContent from "components/common/content";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const SpTable = ({ api }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { id } = useParams();
  const findSpApi = useApi(api.findSp);

  const [headers, setHeaders] = useState([
    commenContent.spCode[culture],
    commenContent.rmbAmount[culture],
    commenContent.usdAmount[culture],
    commenContent.rate[culture],
    commenContent.date[culture],
    commenContent.bankAccount[culture],
  ]);

  const [columns, setColumns] = useState([
    "spCode",
    "rmB_Amount",
    "usD_Amount",
    "rate",
    "createdOn",
    "bankAccount",
  ]);
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState(false);

  const editResponse = (row) => {
    row.createdOn = row.createdOn.split("T")[0];
  }

  const handleShow = async () => {
    setClicked(true);
    const res = await findSpApi.request(id);
    if (res.status === 200) {
      res.data.data.forEach(editResponse)
      setData(res.data.data);
    }
  };

  if (findSpApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {findSpApi.errorAlert}
      <Card className="relative">
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h3 className={`mb-0 text-md-left`}>SPs</h3>
            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={handleShow}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-eye" />
              </span>
              <span>{commenContent.show[culture]} Sps</span>
            </Button>
          </div>
        </CardHeader>
        {clicked && data.length ? (
          <Tables
            data={data}
            headers={headers}
            columns={columns}
            setColumns={setColumns}
            setHeaders={setHeaders}
            pageName="sps"
          />
        ) : clicked && !data.length ? (
          <CardBody>
            No sps found
          </CardBody>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default SpTable;
