export default {
  logout: {
    ar: 'تسجيل الخروج',
    en: 'Logout'
  },
  welcome: {
    ar: 'مرحبا !',
    en: 'Welcome !'
  },
  myprofile: {
    ar: 'ملفي الشخصي',
    en: 'My profile'
  },
  settings: {
    ar: 'الإعدادات',
    en: 'Settings'
  },
  activity: {
    ar: 'النشاطات',
    en: 'Activity'
  },
  support: {
    ar: 'الدعم',
    en: 'Support'
  },
  search: {
    ar: "Search Commodity#",
    en: "#Search Commodity"
  },
  noOrderFound: {
    ar: "لم يتم العثور على اي شحنه بهذا الرقم",
    en: "No cargo was found with this number"
  },
}