import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Container, Input, Row, Col } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import TextPopup from "components/common/TextModal";
import PrintBody from "./PrintBody";
import classes from "./PrintBody.module.css";
import useAlert from "hooks/useAlert";
import PrintTable from "./PrintTable";
import { useEffect } from "react";
import getCulture from "utils/getCulture";

const content = {
  docsTitle: {
    ar: "ادخل عنوان للمستند",
    en: "Select title for the printed document"
  },
  print: {
    ar: "طباعة",
    en: "Print"
  }
};

const Print = ({
  headers,
  columns,
  data,
  isPrint = true,
  printBody,
  printFooter = [],
  click = false,
  onClick,
  none = false,
}) => {
  
  const rout = useRouteMatch()
  const culture = getCulture(rout.url);

  const componentRef = useRef();

  const [title, setTitle] = useState("");
  const [printModal, setPrintModal] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const handlePrint = () => {
    setPrintModal(false);
    printDocument();
  };

  const printDocument = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleModal = () => {

    if (headers.length <= 9) return setPrintModal(true);
    return sweetAlert("Printed table should have 8 columns or less", true);
  };

  const handleCancel = () => {
    setTitle("");
    setPrintModal(false);
  };

  const isNone = { display: none ? "none" : "block" };
  useEffect(() => {
    if (click) handleModal();
  }, [click]);

  if (!isPrint) return <></>;

  return (
    <>
      {alert}
      <div className={classes.print} style={isNone}>
        {isPrint && (
          <div className="text-right">
            <Button
              onClick={onClick || handleModal}
              color="success"
              type="button"
              size="sm"
              className="mb-3 me-1"
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-print" />
              </span>
              <span>{content.print[culture]}</span>
            </Button>
          </div>
        )}
        <span ref={componentRef} className={classes.theWrapper}>
          <PrintBody title={title} printBody={printBody} />

          <span className={classes.tableWrapper}>
            <PrintTable headers={headers} columns={columns} data={data} />
          </span>
          <div className={classes.belowTable}>
            {printFooter.map((item) => (
              <div key={item.text}>
                <p>{item.value}</p>
                <p style={{ fontWeight: "bold" }}>{item.text}</p>
              </div>
            ))}
          </div>
          <div className={classes.accepted}>
            <span>الموظف المسؤول</span>
          </div>
          <footer>
            <div>Terms & Conditions:</div>
          </footer>
        </span>
        <TextPopup
          modal={printModal}
          text={content.print[culture]}
          handleCancel={handleCancel}
          fn={handlePrint}
          color="primary"
          name={content.docsTitle[culture]}
        >
          <Container>
            <Row>
              <Col xs="8">
                <Input type="text" onChange={(e) => setTitle(e.target.value)} />
              </Col>
            </Row>
          </Container>
        </TextPopup>
      </div>
    </>
  );
};

export default Print;
