export default {
    shippingType: {
        ar: "نوع الشحن",
        en: "Shipping Type"
    },
    priceRange: {
        ar: "حدود السعر",
        en: "Price Range"
    },
    priceMedicalRange: {
        ar:"حدود السعر الطبي",
        en: "Price Medical Range"
    },
    calculator: {
        ar: "الاحتساب",
        en: "Calculator"
    },
    priceValue: {
        ar: "قيمة السعر",
        en: "Price Value"
    },
    exchange: {
        ar: "التصريف",
        en: "Exchange"
    }

}