import React, { useState, useEffect, useContext } from "react";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
//content
import content from "./content";

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";

// BK
import AdvanceTable from "components/common/AdvanceTable";
import useApi from "../../../hooks/useApi";
import lclApi from "api/LCLBK";
import Spinner from "../../../components/Spinner";
import env from "env";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import EditLCL from "./components/EditLCL";
import { useForm, Controller } from "react-hook-form";
import PopupTable from "components/common/PopupTableUsed";
import useAlert from "hooks/useAlert";
import useAbility from "hooks/useAbility";
import FilePicker from "components/common/FilePicker";
import CountryTable from "components/common/CountryTable";
import BulkTable from "components/common/BulkTable";
import bulks from "api/bulks";
import useAdvanceSearch from "hooks/useAdvanceSearch";
import AdvanceSearchModal from "components/common/AdvanceSearchModal";
import ChangeCargoType from "components/common/ChangeCargoType";
import useCheckRequest from "hooks/useCheckRequest";
import invoice from "api/invoice";

function LCL() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    content.orderBulk[culture],
    commenContent.customerCode[culture],
    commenContent.customer[culture],
    commenContent.volume[culture],
    commenContent.numOfPackages[culture],
    commenContent.followUpStatus[culture],
    content.country[culture],
    content.date[culture],
    content.recieveDate[culture],
    content.stateDate[culture],
    content.exportDate[culture],
    commenContent.lastModifiedOn[culture],
    commenContent.commodity[culture],
    commenContent.branch[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "orderBulk",
    "customer.customerCode",
    "customer.customerName",
    "volume",
    "numberOfPkgs",
    "followUpStatusText",
    "country.name",
    "createdOn",
    "recieveDate",
    "statusDate",
    "exportDate",
    "lastModifiedOn",
    "commodity",
    "branch.name",
  ]);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const checkAbility = useAbility("LCL");
  const bulkCheckAbility = useAbility("LCLBulk");

  // Apis
  const getLCLPreload = useApi(lclApi.LCLPreload);
  const createLCL = useApi(lclApi.createLCL);
  const updateLCL = useApi(lclApi.updateLCL);
  const approveLCL = useApi(lclApi.approveLCL);
  const uploadImgLCL = useApi(lclApi.uploadImgLCL);
  const getOrderByCode = useApi(lclApi.getOrderByCode);
  const getByColumn = useApi(lclApi.recivedAdvanceSearch);
  const linkOrderApi = useApi(bulks.linkOrder);
  const changeCargoApi = useApi(invoice.changeCargoType);

  // States
  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editSchema, setEditSchema] = useState({});
  const [editImage, setEditImage] = useState({});
  const [formIsEdited, setFormIsEdited] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const [customer, setCustomer] = useState({});
  const [images, setImages] = useState({});
  const [bulkModal, setBulkModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [changeTypeModal, setChangeTypeModal] = useState(false);

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "recivedLcl";
  const editResponse = (res) => {
    res.createdOn = res.createdOn?.split("T")[0];
    res.recieveDate = res.recieveDate?.split("T")[0];
    res.statusDate = res.statusDate?.split("T")[0];
    res.exportDate = res.exportDate?.split("T")[0];

    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === res.followUpStatus
    );

    res.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    res.orderNumber = `${res.country?.countryCode}-${res.orderNumber}LC`;

    if (res.followUpStatus === 6) {
      res.numberOfPkgs = res.restNoOfPkgs;
      res.volume = res.restVolume;
    }

    res.orderBulk = (
      <span>
        {res.lclBulks?.map((item) => (
          <Link to={`/${culture}-admin/bulk/lcl/${item.id}`} className="me-1">
            B-{item.bulkPckgNum}
            {item.country?.countryCode}
          </Link>
        ))}
      </span>
    );

    return res;
  };

  const {
    key,
    setKey,
    openPopup,
    setOpenPopup,
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    register,
    onHeaderClick,
    searchHandler,
    setSearchField,
    header,
    isRemove,
    setIsRemove,
    handleRemove,
  } = useAdvanceSearch(pageName, editResponse);

  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const {
    handleSubmit: editHandleSubmit,
    formState: editFormState,
    control: editControl,
    setValue: editSetValue,
  } = useForm();
  const {
    handleSubmit: bulkHandleSubmit,
    setValue: bulkSetValue,
    watch: bulkWatch,
  } = useForm();
  const {
    handleSubmit: changeTypeSubmit,
    setValue: changeTypeSetValue,
    formState: { errors: changeTypeErrors },
    control: changeTypeControl,
    reset: changeTypeReset,
  } = useForm();

  const getLCL = async () => {
    const res = await getLCLPreload.request(pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data.data = res.data.data?.data?.map((res) => editResponse(res));

      setData(res.data.data);

      // if the prev page is not the same as this one clear the context
      // if (!prevPageCheck()) paginationCtx.resetPagination();
    }
  };

  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = new FormData();
    // const imgs = imgRef.current.files;

    // schema.append('orderId', '');
    schema.append("customerId", customer.id);
    schema.append("followUpStatus", 1);

    delete formData.country;
    delete formData.client;

    Object.keys(formData).forEach((key) => {
      schema.append(key, formData[key]);
    });

    for (let i = 0; i < images.length; i++) {
      schema.append("Image", images[i]);
    }

    const res = await createLCL.request(schema);
    if (res.status === 200) {
      setImages({});

      getLCL();
      reset();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleUpload = async (id) => {
    const res = await uploadImgLCL.request(id, editImage);
    return res?.ok || false;
  };

  const editLCL = async (formData) => {
    setEditModal(false);
    setSpinner(true);

    const rowId = { ...formData }.id;
    delete formData.id;
    delete formData.country;

    // Assing 'uploadSucceed' varibale with treu default value
    let uploadSucceed = true;

    // if the 'editSchema' has 'imgs' attribute and the 'imgs' attribute has a value
    // 1) upload the imgs
    // 2) override the 'uploadSucceed' varibale
    if (Object.keys(editImage).length)
      uploadSucceed = await handleUpload(rowId);

    // if the upload fails don't continue to the next request
    if (!uploadSucceed) return setSpinner(false);
    // if no other filed edited don't continue to the next request
    if (!editFormState.isDirty) {
      sweetAlert("Done");
      return setSpinner(false);
    }

    const res = await updateLCL.request(rowId, formData);

    if (res.status === 200) {
      const newData = { ...data };
      const editedIndex = newData.data.findIndex((item) => item.id === rowId);

      res.data.data = editResponse(res.data.data);
      newData.data[editedIndex] = res.data.data;

      setData(newData);
      setEditImage({});
      sweetAlert(commenContent.done[culture]);
    }

    setSpinner(false);
  };

  const handleApprove = async (obj) => {
    setSpinner(true);
    const res = await approveLCL.request(obj.lclId);

    if (res.status === 200) {
      const newData = { ...data };
      const editedIndex = newData.data.findIndex(
        (item) => item.lclId === obj.lclId
      );
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });
      // Replace the normal status with the text from env variable
      res.data.data.followUpStatusText =
        env.status.en[res.data.data.followUpStatus - 1];
      // Replace the normal country with the replacement text from env variable
      res.data.data.countryText = env.country[res.data.data.country - 1];

      // Append id filed to use it in the table component
      res.data.data.id = res.data.data.lclId;

      newData.data[editedIndex] = res.data.data;

      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }

    setSpinner(false);
  };

  const updateData = (arr) => {
    setData((data) => ({
      ...data,
      data: arr,
    }));
  };

  const handleEdit = (obj) => {
    setEditImage({});
    editSetValue("id", obj.id);
    editSetValue("volume", obj.volume);
    editSetValue("numberOfPkgs", obj.numberOfPkgs);
    editSetValue("commodityType", obj.commodityType);
    editSetValue("commodity", obj.commodity);
    editSetValue("country", obj.country.name);
    editSetValue("countryId", obj.country.id);
    editSetValue("client", obj.customer?.customerName);
    editSetValue("customerId", obj.customer?.id);
    setEditModal(true);
  };

  const handleCancel = () => {
    setEditModal(false);
    setEditSchema({ imgs: {} });
    setFormIsEdited(false);
    setTableModal(false);
    setCountryTable(false);
    setBulkModal(false);
  };

  const handleBulk = (obj) => {
    const volume = obj.followUpStatus === 6 ? obj.restlVolume : obj.volume;
    const numberOfPkgs =
      obj.followUpStatus === 6 ? obj.restNumberOfPkgs : obj.numberOfPkgs;

    bulkSetValue("id", obj.id);
    bulkSetValue("volume", volume);
    bulkSetValue("numberOfPkgs", numberOfPkgs);
    setBulkModal(true);
  };

  const linkOrder = async (formData) => {
    setBulkModal(false);
    setLinkModal(false);
    setSpinner(true);

    const schema = {
      id: formData.bulkId,
      orders: [
        {
          id: formData.id,
          volume: formData.volume,
          numberOfPkgs: formData.numberOfPkgs,
        },
      ],
      volume: formData.volume,
    };

    const res = await linkOrderApi.request("lcl", schema);

    if (res.status === 200) {
      const index = data.data.findIndex((item) => item.id === formData.id);
      // const newData = { ...data };
      // editResponse(res.data.data);
      // newData.data[index].lclBulkCargos.push(res.data.data);
      // setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const renderEdit = (obj) =>
    checkAbility("edit") &&
    (obj.followUpStatus === 5 || obj.followUpStatus === 4);

  const renderLinkBulk = () => bulkCheckAbility("linkOrder"); // &&
  // (obj.lclBulkCargos?.length === 0 ||
  //   (obj.lclBulkCargos?.length === 1 && obj.followUpStatus !== 6));

  const handleSearch = async () => {
    const res = await searchHandler(getByColumn, pageNo, docsPerPage);
    if (res.ok) {
      updateData(res.data.data);
      register({ key: 1, pageNo, docsPerPage, pageName });
    }
  };

  const renderChangeType = (obj) => obj.followUpStatus < 6;

  const handleChangeType = (obj) => {
    setChangeTypeModal(true);
    changeTypeSetValue("id", obj.id);
  };

  const changeCargoHandler = async (data) => {
    setSpinner(true);
    setChangeTypeModal(false);
    const res = await changeCargoApi.request(
      data.id,
      { weight: data.weight },
      "aircargo"
    );
    if (res.status === 200) {
      setData((oldData) => ({
        ...oldData,
        data: oldData.data.filter((item) => item.id !== data.id),
      }));
      sweetAlert(commenContent.done[culture]);
      changeTypeReset();
    }
    setSpinner(false);
  };
  // const isSelect = searchCtx.isSelect;

  const closeAdvanceSearch = (index) => {
    handleRemove(index, false);
    setOpenPopup(false);
  }

  useCheckRequest({
    pageNo,
    isRemove,
    setIsRemove,
    requests: [getLCL, handleSearch],
    setPageNo,
    setDocsPerPage,
    docsPerPage,
    key,
    register,
  });

  // useEffect(() => {
  //   if (!isRemove) return;
  //   const requests = [getLCL, handleSearch];
  //   requests[key]();
  //   setIsRemove(false);
  // }, [isRemove])
  // useEffect(() => {
  //   if (isSelect) {
  //     setPageNo(searchCtx.pageNo);
  //     setDocsPerPage(searchCtx.docsPerPage);
  //     register({ isSelect: false });
  //     return;
  //   }
  //   const requests = [getLCL, handleSearch];
  //   requests[key]();
  // }, [pageNo, docsPerPage, key]);

  return (
    <div>
      {alert}
      {getLCLPreload.errorAlert}
      {createLCL.errorAlert}
      {updateLCL.errorAlert}
      {approveLCL.errorAlert}
      {getOrderByCode.errorAlert}
      {uploadImgLCL.errorAlert}
      {linkOrderApi.errorAlert}
      {changeCargoApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getLCLPreload.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.lcl[culture]]} />
          <Container className="mt--6" fluid>
            {checkAbility("create") && (
              <Card>
                <CardHeader>
                  <h3 className="mb-0">{content.lcl[culture]}</h3>
                </CardHeader>
                <CardBody>
                  <form onSubmit={handleSubmit(handleCreate)}>
                    <Row>
                      <Col md="4" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="client"
                          >
                            {commenContent.customer[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="client"
                            rules={{
                              required: `${content.client[culture]} field is required`,
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="client"
                                placeholder={content.client[culture]}
                                type="text"
                                className={errors.client && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onClick={() => setTableModal(true)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="country"
                          >
                            {content.country[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="country"
                            rules={{ required: "Country feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="country"
                                type="text"
                                className={errors.country && "error"}
                                value={value}
                                placeholder={`-- ${commenContent.selectAnOption[culture]} --`}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onClick={() => setCountryTable(true)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="volume"
                          >
                            {commenContent.volume[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="volume"
                            rules={{
                              required: `${content.volume[culture]} field is required`,
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="volume"
                                placeholder={commenContent.volume[culture]}
                                type="number"
                                className={errors.volume && "error"}
                                value={value || ""}
                                onWheel={(e) => e.target.blur()}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="numberOfPkgs"
                          >
                            {commenContent.numOfPackages[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="numberOfPkgs"
                            rules={{
                              required: `${content.noOfPkgs[culture]} field is required`,
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="numberOfPkgs"
                                placeholder={
                                  commenContent.numOfPackages[culture]
                                }
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                className={errors.numberOfPkgs && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="branch"
                          >
                            {commenContent.branch[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="branchId"
                            rules={{ required: "branch feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="branch"
                                type="select"
                                className={errors.branchId && "error"}
                                value={value || "disabled"}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              >
                                <option disabled value="disabled">
                                  -- {commenContent.selectAnOption[culture]} --
                                </option>
                                {data.branch?.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Input>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="commidityType"
                          >
                            {commenContent.commodityType[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="commodityType"
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="commidityType"
                                type="select"
                                defaultValue="disabled"
                                onChange={({ target: { value } }) =>
                                  onChange(+value)
                                }
                              >
                                <option disabled value="disabled">
                                  -- {commenContent.selectAnOption[culture]} --
                                </option>
                                {env.commidityType.map((status, i) => (
                                  <option key={i} value={i + 1}>
                                    {status}
                                  </option>
                                ))}
                              </Input>
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="commodity"
                          >
                            {commenContent.commodity[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="commodity"
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="commodity"
                                type="textarea"
                                style={{ resize: "none" }}
                                value={value}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      <FilePicker images={images} setImages={setImages} />
                    </div>
                    <Button color="success" type="submit">
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{commenContent.create[culture]}</span>
                    </Button>
                  </form>
                </CardBody>
              </Card>
            )}
            <Card>
              <Pagination
                dataLength={data.data?.length}
                pageNo={pageNo}
                setPageNo={setPageNo}
                docsPerPage={docsPerPage}
                setDocsPerPage={setDocsPerPage}
              >
                <AdvanceTable
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  data={data.data}
                  setData={(sortedData) =>
                    setData((d) => ({ ...d, data: sortedData }))
                  }
                  handleEdit={handleEdit}
                  handleApprove={handleApprove}
                  renderEdit={renderEdit}
                  renderLinkBulk={renderLinkBulk}
                  handleBulk={handleBulk}
                  handleChangeType={handleChangeType}
                  renderChangeType={renderChangeType}
                  searchedHeaders={header}
                  onHeaderClick={onHeaderClick}
                  handleRemove={handleRemove}
                >
                  <span to={`/${culture}-admin/lcl`}>
                    <div id="details1" className="table-action cursor-pointer">
                      <i className="far fa-eye fa-lg hover-success"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="details1">
                      {commenContent.showDetails[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span
                    className="me-1"
                    fun="handleEdit"
                    condition="renderEdit"
                  >
                    <div id="edit1" className="table-action cursor-pointer">
                      <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="edit1">
                      {commenContent.edit[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span
                    fun="handleChangeType"
                    className="me-1"
                    render="renderChangeType"
                  >
                    <div
                      id="change-type"
                      className="table-action cursor-pointer"
                    >
                      <i className="fas fa-exchange-alt fa-lg hover-warning" />
                    </div>
                    <UncontrolledTooltip delay={0} target="change-type">
                      {commenContent.changeToAir[culture]}
                    </UncontrolledTooltip>
                  </span>
                  {/* <span fun="handleBulk" condition="renderLinkBulk">
                    <div id="bulk" className="table-action cursor-pointer">
                      <i className="fas fa-shipping-fast hover-primary fa-lg" />
                    </div>
                    <UncontrolledTooltip delay={0} target="bulk">
                      Bulk this shipment
                    </UncontrolledTooltip>
                  </span> */}
                </AdvanceTable>
              </Pagination>
              <TextPopup
                modal={editModal}
                text={commenContent.save[culture]}
                handleCancel={handleCancel}
                fn={editHandleSubmit(editLCL)}
                disabled={
                  !editFormState.isDirty && !Object.keys(editImage).length
                }
                color="success"
              >
                <Container>
                  <EditLCL
                    control={editControl}
                    errors={editFormState.errors}
                    setValue={editSetValue}
                    editImage={editImage}
                    setEditImage={setEditImage}
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={tableModal}
                text="Next"
                handleCancel={handleCancel}
                fn={() => {}}
                color="primary"
                noBtn
              >
                <Container>
                  <PopupTable
                    setCustomer={setCustomer}
                    setTableModal={setTableModal}
                    setValue={setValue}
                    sweetAlert={sweetAlert}
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={countryTable}
                text="Next"
                handleCancel={handleCancel}
                fn={() => {}}
                color="primary"
                noBtn
              >
                <Container>
                  <CountryTable
                    setCountryTable={setCountryTable}
                    setValue={setValue}
                    sweetAlert={sweetAlert}
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={bulkModal}
                text="Yes"
                handleCancel={handleCancel}
                fn={() => setLinkModal(true)}
                color="primary"
                noBtn
              >
                <Container>
                  <BulkTable
                    setBulkTable={setBulkModal}
                    setLinkModal={setLinkModal}
                    setValue={bulkSetValue}
                    sweetAlert={sweetAlert}
                    shipmentType="lcl"
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={linkModal}
                text="continue"
                handleCancel={() => setLinkModal(false)}
                fn={bulkHandleSubmit(linkOrder)}
                color="success"
              >
                <Container>
                  <h2>
                    You're about to link this order{" "}
                    {
                      data.data?.find((item) => item.id == bulkWatch("id"))
                        ?.orderNumber
                    }
                    with this bulk {bulkWatch("numberOfPkgs")}
                  </h2>
                </Container>
              </TextPopup>
              <TextPopup
                modal={changeTypeModal}
                text={commenContent.change[culture]}
                name={commenContent.changeToLcl[culture]}
                handleCancel={() => setChangeTypeModal(false)}
                fn={changeTypeSubmit(changeCargoHandler)}
                color="success"
              >
                <ChangeCargoType
                  control={changeTypeControl}
                  errors={changeTypeErrors}
                  cargoType="lcl"
                />
              </TextPopup>
              {openPopup && (
                <AdvanceSearchModal
                  setKey={setKey}
                  openPopup={openPopup}
                  pageName={pageName}
                  getByColumn={getByColumn}
                  updateData={updateData}
                  setParentPageNo={setPageNo}
                  setParentDocsPerPage={setDocsPerPage}
                  setParentSearchField={setSearchField}
                  handleClose={closeAdvanceSearch}
                  editResponse={editResponse}
                  extraField={{
                    header: content.orderId[culture],
                    column: "orderNumber",
                  }}
                />
              )}
            </Card>
          </Container>
        </>
      )}
    </div>
  );
}

export default LCL;
