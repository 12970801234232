import React, { useState, useEffect } from "react";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  Container,
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
//content
import content from "./components/content";

//common
import commenContent from "components/common/content";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";

import TextPopup from "components/common/TextModal";
import EditNews from "./components/EditNews";

// BK
import Table from "components/common/CustomTable";
import useApi from "../../hooks/useApi";
import newsApi from "api/newsBK";
import Spinner from "../../components/Spinner";
import { useForm, Controller } from "react-hook-form";
import Pagination from "components/common/Pagination";
import FilePicker from "components/common/FilePicker";
import useAlert from "hooks/useAlert";
import useAbility from "hooks/useAbility";

function News() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  // Apis
  const getNewsPreload = useApi(newsApi.getNews);
  const createNews = useApi(newsApi.createNews);
  const updateNews = useApi(newsApi.updateNews);
  const deactivateApi = useApi(newsApi.deactivate);

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editSchema, setEditSchema] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [viewModal, setViewModal] = useState(false);
  const [img, setImg] = useState();
  const [images, setImages] = useState({});
  const [rowId, setRowId] = useState("");
  const [deactivateModal, setDeactivateModal] = useState(false);

  const checkAbility = useAbility("News");

  const { alert, sweetAlert } = useAlert();

  // Use form hook
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const getNews = async () => {
    const res = await getNewsPreload.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach((post) => {
        // This code will split any date by checking it's key
        Object.keys(post).forEach((key) => {
          if (key.includes("date") || key.includes("Date")) {
            post[key] = post[key] ? post[key].split("T")[0] : "";
          }
        });
      });
      setData(res.data.data);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const [headers, setHeaders] = useState([
    content.actions[culture],
    content.title[culture],
    content.date[culture],
    content.description[culture],
  ]);

  const [columns, setColumns] = useState(["title", "date", "descraption"]);

  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = {
      ...formData,
      image: images[0],
      isActive: true,
      path: "",
    };
    const res = await createNews.request(schema);

    if (res.status === 200) {
      const newData = [...data];
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });
      newData.unshift(res.data.data);
      setData(newData);
      setImages({});
      reset();
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const editNews = async () => {
    setSpinner(true);
    setEditModal(false);
    const schema = {
      isActive: editSchema.isActive,
      title: editSchema.title,
      path: editSchema.path,
      date: editSchema.date,
      image: editSchema.image,
      descraption: editSchema.descraption,
    };

    const res = await updateNews.request(editSchema.id, schema);

    if (res.status === 200) {
      const newData = [...data];
      const editedIndex = newData.findIndex(
        (item) => item.id === editSchema.id
      );
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });

      newData[editedIndex] = res.data.data;

      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
    setEditModal(false);
  };

  const handleEdit = (obj) => {
    setEditSchema(obj);
    setEditModal(true);
  };

  const handleView = (obj) => {
    setImg(obj.path);
    setViewModal(true);
  };

  const handleDeactivate = (obj) => {
    setRowId(obj.id);
    setDeactivateModal(true);
  };

  const handleCancel = () => {
    setEditModal(false);
    setViewModal(false);
    setDeactivateModal(false);
    setEditSchema({});
  };

  const renderView = obj => obj.path;

  const renderEdit = () => checkAbility("edit");

  const renderDeactivate = (obj) => obj.isActive && checkAbility("status");

  const deactivateHandler = async () => {
    setDeactivateModal(false);
    setSpinner(true);
    const res = await deactivateApi.request(rowId);
    if (res.status === 200) {
      setData((prevData) => {
        const newData = [...prevData];
        const updatedRow = newData.find(item => item.id === rowId);
        updatedRow.isActive = false;
        return newData;
      })
      sweetAlert(commenContent.done[culture])
    }
    setSpinner(false);
    setRowId("");
  }

  useEffect(() => {
    getNews();
  }, [pageNo, docsPerPage]);

  return (
    <div>
      {alert}
      {createNews.errorAlert}
      {getNewsPreload.errorAlert}
      {updateNews.errorAlert}
      {deactivateApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getNewsPreload.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={["News"]} />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <h3>{content.news[culture]}</h3>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(handleCreate)}>
                  <Row>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="title">
                          {content.title[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="title"
                          rules={{
                            required: `${content.title[culture]} field is required`,
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="title"
                              placeholder={content.title[culture]}
                              type="text"
                              className={errors.title && "error"}
                              ref={ref}
                              value={value || ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.description[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="descraption"
                          rules={{
                            required: `${content.description[culture]} field is required`,
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder={content.description[culture]}
                              type="textarea"
                              className={errors.descraption && "error"}
                              ref={ref}
                              value={value || ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.image[culture]}
                        </label>
                        {/* <Form> */}
                        <FilePicker
                          multiple={false}
                          images={images}
                          setImages={setImages}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={`text-md-right`} lg="12" md="12" xs="12">
                      <Button
                        color="success"
                        type="submit"
                      >
                        {content.create[culture]}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
            <Card>
              <Pagination
                fun={getNews}
                pageNo={pageNo}
                setPageNo={setPageNo}
                docsPerPage={docsPerPage}
                setDocsPerPage={setDocsPerPage}
                dataLength={data.length}
              >
                <Table
                  pageName="news-settings"
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  data={data}
                  setData={setData}
                  renderView={renderView}
                  renderEdit={renderEdit}
                  renderDeactivate={renderDeactivate}
                  handleEdit={handleEdit}
                  handleView={handleView}
                  handleDeactivate={handleDeactivate}
                >
                  <span className="me-1" fun="handleView" condition="renderView">
                    <div id="details1" className="table-action cursor-pointer">
                      <i className="far fa-eye fa-lg hover-success"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="details1">
                      {commenContent.view[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span className="me-1" fun="handleEdit" condition="renderEdit">
                    <div id="edit1" className="table-action cursor-pointer">
                      <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="edit1">
                      {commenContent.edit[culture]}
                    </UncontrolledTooltip>
                  </span>
                  <span
                    fun="handleDeactivate"
                    className="me-1"
                    condition="renderDeactivate"
                  >
                    <div
                      id="deactivate"
                      className="table-action cursor-pointer"
                    >
                      <i className="fas fa-ban fa-lg hover-warning" />
                    </div>
                    <UncontrolledTooltip delay={0} target="deactivate">
                      {content.deactivate[culture]}
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
              <TextPopup
                modal={editModal}
                text="Update"
                handleCancel={handleCancel}
                fn={editNews}
                color="success"
              >
                <Container>
                  <EditNews
                    editSchema={editSchema}
                    setEditSchema={setEditSchema}
                    culture={culture}
                  />
                </Container>
              </TextPopup>
              <TextPopup modal={viewModal} handleCancel={handleCancel} noBtn>
                <Container>
                  <img
                    src={img}
                    alt="preview image"
                    style={{
                      marginLeft: "8em",
                      width: "400px",
                      height: "400px",
                      margin: "0 auto",
                      display: "block",
                      objectFit: "contain",
                    }}
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={deactivateModal}
                handleCancel={handleCancel}
                name={data.find(row => row.id === rowId)?.title}
                text={commenContent.continue[culture]}
                fn={deactivateHandler}
                color="warning"
              >
                <Container>
                  <h2>{content.deactivateModal[culture]}</h2>
                </Container>
              </TextPopup>
            </Card>
          </Container>
        </>
      )}
    </div>
  );
}

export default News;
