import React, { useState, useCallback, useEffect } from "react";
import {
  UncontrolledTooltip,
  Row,
  Col,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import Table from "./CustomTable";
import TextPopup from "./TextModal";
import Pagination from "./Pagination";
// import env from "env";
import useAdvanceSearch from "../../hooks/useAdvanceSearch";
import content from "./content";
import useCulture from "hooks/useCulture";
import env from "env";

const AdvanceSearchModal = ({
  setKey,
  pageName,
  openPopup,
  handleClose,
  getByColumn,
  updateData,
  extraField,
  setParentPageNo,
  setParentSearchField,
  setParentDocsPerPage,
  editResponse,
  extraParams = [],
}) => {
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);

  const culture = useCulture();

  const {
    register,
    header,
    column,
    changeHandler,
    searchHandler,
    searchField,
    colIndex,
    handleRemove,
  } = useAdvanceSearch(pageName, editResponse);

  const handleShowColumn = () => {
    const enumFields = ["followUpStatus", "isApproved"];
    if (enumFields.includes(column[colIndex])) return `${column[colIndex]}Text`;
    return column[colIndex];
  }
  const headers = ["actions", header[colIndex]];
  const columns = [handleShowColumn()];

  // extra field is an object with column and header props
  // which allow you to add another column to the popup table
  // if the extraField has a column and it's not the selected one show it
  if (
    extraField &&
    extraField.column &&
    column[colIndex] !== extraField.column
  ) {
    headers.push(extraField.header);
    columns.push(extraField.column);
  }

  const handleSearch = async () => {
    const res = await searchHandler(
      getByColumn,
      pageNo,
      docsPerPage,
      ...extraParams
    );
    if (res?.status === 200) setData(res.data.data);
  };

  const handleSelect = () => {
    const params = {
      key: 1,
      page: pageName,
      pageNo,
      docsPerPage,
      isSelect: true,
      searchField,
    };
    setKey(1);
    setParentPageNo(pageNo);
    setParentDocsPerPage(docsPerPage);
    setParentSearchField(searchField);
    register(params);
    updateData(data);
    handleClose();
  };

  const closeHanler = () => {
    handleClose(colIndex);
  };

  useEffect(() => {
    if (searchField[colIndex]) handleSearch();
  }, [pageNo, docsPerPage]);

  return (
    <>
      {getByColumn.errorAlert}
      <TextPopup
        modal={openPopup}
        handleCancel={closeHanler}
        fn={() => {}}
        color="success"
        noBtn
      >
        <div className="padding-around pb-0">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="search-field">
                  {header[colIndex]}
                </label>
                {column[colIndex] === "followUpStatus" ? (
                  <Input
                    id="search-field"
                    placeholder="search"
                    type="select"
                    value={searchField[colIndex] || ""}
                    onChange={(e) =>
                      changeHandler(+e.target.value, column[colIndex])
                    }
                    onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                  >
                    <option value="disabled">
                      -- {content.selectAnOption[culture]} --
                    </option>
                    {env.followUpStatus.map((followup) => (
                      <option value={followup.key} key={followup.key}>
                        {followup[culture]}
                      </option>
                    ))}
                  </Input>
                ) : column[colIndex] === "isApproved" ? (
                  <Input
                    id="search-field"
                    type="select"
                    value={searchField[colIndex] || ""}
                    onChange={(e) =>
                      changeHandler(e.target.value, column[colIndex])
                    }
                    onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                  >
                    <option value="disabled">
                      -- {content.selectAnOption[culture]} --
                    </option>
                    <option value={true}>{content.approved[culture]}</option>
                    <option value={false}>{content.notApproved[culture]}</option>
                  </Input>
                ) : (
                  <Input
                    id="search-field"
                    placeholder="search"
                    type="text"
                    value={searchField[colIndex] || ""}
                    onChange={(e) =>
                      changeHandler(e.target.value, column[colIndex])
                    }
                    onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                  />
                )}
              </FormGroup>
            </Col>
            <span style={{ paddingTop: 7 }}>
              <Button
                color="success"
                type="button"
                className="mt-4"
                onClick={handleSearch}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-search" />
                </span>
                <span>Search</span>
              </Button>
            </span>
          </Row>
        </div>
        <Pagination
          dataLength={data.length}
          pageNo={pageNo}
          setPageNo={setPageNo}
          docsPerPage={docsPerPage}
          setDocsPerPage={setDocsPerPage}
        >
          <Table
            data={data}
            setData={setData}
            headers={headers}
            columns={columns}
            // handleSort={handleSort}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
          >
            <span className="me-1" fun="handleSelect">
              <div id="check" className="table-action cursor-pointer">
                <i className="far fa-check-circle hover-success fa-lg" />
              </div>
              <UncontrolledTooltip delay={0} target="check">
                check
              </UncontrolledTooltip>
            </span>
          </Table>
        </Pagination>
      </TextPopup>
    </>
  );
};

export default AdvanceSearchModal;
