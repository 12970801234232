import React, { useMemo} from "react";
import { Controller } from "react-hook-form";
import { Row, Col, FormGroup, Input } from 'reactstrap';

const EditCountry = ({ control, errors }) => {

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            Name
          </label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "name field is required",
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="name"
                placeholder="Name"
                type="text"
                disabled
                className={errors.name && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label" htmlFor="code">
            Code
          </label>
          <Controller
            control={control}
            name="countryCode"
            rules={{
              required: "code field is required",
              pattern: {
                value: /^[a-zA-Z]+$/,
                message: "only english character allowed",
              },
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="code"
                placeholder="Code"
                type="text"
                className={errors.countryCode && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default EditCountry;
