import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import env from "env";
import { useForm, Controller } from "react-hook-form";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import CustomerPopup from "../components/CustomerPopup";
import { useLocation, useParams } from "react-router";
import bulks from "api/bulks";
import useAlert from "hooks/useAlert";
import SearchModal from "components/common/SearchModal";
import useSearchPopup from "hooks/useSearchPopup";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import CountryTable from "components/common/CountryTable";
import useAbility from "hooks/useAbility";
import content from "../../Airshipment/content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import usePrevRequest from "hooks/usePrevRequest";
import CostCenterTable from "components/common/CostCenterTable";

function BulkActions() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const { shipmentType } = useParams();

  const weightOrVolume =
    shipmentType.toLowerCase() === "aircargo" ? "weight" : "volume";

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.code[culture],
    commenContent.country[culture],
    commenContent.shippingDate[culture],
    content.followUpStatus[culture],
    content.numberOfPackages[culture],
    commenContent[weightOrVolume][culture],
    commenContent.costCenter[culture],
    commenContent.currency[culture],
  ]);

  const [columns, setColumns] = useState([
    "bulkPckgNum",
    "country.name",
    "createdOn",
    "followUpStatusText",
    "numberOfPkgs",
    weightOrVolume,
    "costCenter.name",
    "enumEntryText",
  ]);

  // Params

  const checkAbility = useAbility(
    shipmentType.toLowerCase() === "aircargo" ? "AirCargoBulk" : "LCLBulk"
  );

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // Apis
  const getAllBulks = useApi(bulks.getAllBulks);
  const createBulk = useApi(bulks.createBulk);
  const uploadImages = useApi(bulks.uploadImg);
  const getByColumn = useApi(bulks.getByColumn);

  // states
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [clients, setClients] = useState([]);
  const [tableModal, setTableModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const [costCenterModal, setCostCenterModal] = useState(false);

  const paginationCtx = useContext(PaginationContext);
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = `bluk-${shipmentType}-${
    shipmentType.toLowerCase() === "aircargo" ? "AC" : "LC"
  }`;
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const editResponse = (post) => {
    post.createdOn = post.createdOn.split("T")[0];

    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === post.followUpStatus
    );
    post.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    post.enumEntryText = env.enumEntry?.[culture];

    post.bulkTypeText = env.bulkType[post.bulkType - 1];
    post.bulkPckgNum = `B-${post.bulkPckgNum}${post.country?.countryCode}`;

    return post;
  };

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const handleCancel = () => {
    setTableModal(false);
    setCountryTable(false);
    setCostCenterModal(false);
  };

  const allBulksPreload = async () => {
    const res = await getAllBulks.request(shipmentType, pageNo, docsPerPage);

    if (res.ok) {
      const editedResponse = res.data.data.map((post) => editResponse(post));
      res.data.data = editedResponse;
      setData(res.data.data);

      // if the prev page is not the same as this one clear the context
      if (!prevPageCheck()) paginationCtx.resetPagination();
    }
  };

  const handleCreate = async (formData) => {
    setSpinner(true);

    const schema = {
      ...formData,
    };

    delete schema.country;

    const res = await createBulk.request(shipmentType, schema);

    if (res.ok) {
      // if (images.length) {
      //   await handleUploadImages(res.data.data.bulkId);
      // }
      reset();
      allBulksPreload();
      // setData(data => ({
      //   ...data,
      //   posts: [
      //     res.data.data,
      //     ...data.posts
      //   ]
      // }))
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleDisabled = () => {
    const clientsCopy = [...clients];

    for (let i = 0; i < clientsCopy.length; i++) {
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualWeight <= 0)
        return true;
      if (clientsCopy[i].actualNoPackg > 0 && clientsCopy[i].actualVolume <= 0)
        return true;
    }

    return false;
  };

  const updateData = (arr) => {
    setData(arr);
  };

  // useEffect(() => {
  //   // if search popup has a values and it's the same page stored in the context
  //   // don't run these request
  //   // and go to the other useEffect
  //   if (searchPopup.isSelected && searchPopup.searchField && prevPageCheck())
  //     return;
  //   if (
  //     paginationCtx.searchedColumn &&
  //     paginationCtx.searchedContent &&
  //     prevPageCheck()
  //   )
  //     return;

  //   // else
  //   searchPopup.setIsSelected(false);
  //   allBulksPreload();
  // }, [pageNo, docsPerPage, location]);

  // useEffect(() => {
  //   if (
  //     searchPopup.isSelected &&
  //     searchPopup.searchField &&
  //     searchPopup.openPopup
  //   ) {
  //     searchPopup.onSearch(shipmentType, () => {});
  //   } else if (searchPopup.isSelected && searchPopup.searchField) {
  //     searchPopup.onSearch(shipmentType, (data) => {
  //       updateData(data);
  //     });
  //   } else if (
  //     paginationCtx.searchedColumn &&
  //     paginationCtx.searchedContent &&
  //     prevPageCheck()
  //   ) {
  //     // if there's a popup search info in the context set it in the popup search
  //     // then set 'setIsSelected' to true so the search popup request will fire
  //     searchPopup.setColumn(paginationCtx.searchedColumn);
  //     searchPopup.setSearchField(paginationCtx.searchedContent);
  //     searchPopup.setPageNo(paginationCtx.pageNumber);
  //     searchPopup.setDocsPerPage(paginationCtx.docsPerPage);
  //     searchPopup.setIsSelected(true);
  //   } else {
  //     searchPopup.setIsSelected(false);
  //   }
  // }, [
  //   searchPopup.pageNo,
  //   searchPopup.docsPerPage,
  //   location,
  //   searchPopup.isSelected,
  // ]);

  usePrevRequest(
    allBulksPreload,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck,
    "",
    "",
    shipmentType
  );

  return (
    <div>
      {alert}
      {getAllBulks.errorAlert}
      {createBulk.errorAlert}
      {uploadImages.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllBulks.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.bulk[culture]]} />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <h3>{content.bulk[culture]}</h3>
              </CardHeader>
              <CardBody>
                {checkAbility("create") && (
                  <form onSubmit={handleSubmit(handleCreate)}>
                    <Row>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="country"
                          >
                            {commenContent.country[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="country"
                            rules={{ required: "Country feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="country"
                                placeholder={`-- ${commenContent.selectOption[culture]} --`}
                                type="text"
                                className={errors.country && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onClick={() => setCountryTable(true)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {shipmentType.toLowerCase() === "lcl" ? (
                        <>
                          <Col md="6" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="trackNumber"
                              >
                                {content.trackNumber[culture]}
                              </label>
                              <Controller
                                control={control}
                                name="trackNumber"
                                rules={{
                                  required: "Track number feild is required",
                                }}
                                render={({
                                  field: { ref, onChange, value, ...field },
                                }) => (
                                  <Input
                                    {...field}
                                    id="trackNumber"
                                    placeholder={content.trackNumber[culture]}
                                    type="text"
                                    className={errors.trackNumber && "error"}
                                    value={value || ""}
                                    onChange={({ target: { value } }) =>
                                      onChange(value)
                                    }
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="trackNumber"
                              >
                                {content.trackLine[culture]}
                              </label>
                              <Controller
                                control={control}
                                name="trackLine"
                                rules={{
                                  required: "Track line feild is required",
                                }}
                                render={({
                                  field: { ref, onChange, value, ...field },
                                }) => (
                                  <Input
                                    {...field}
                                    id="trackLine"
                                    placeholder={content.trackLine[culture]}
                                    type="select"
                                    className={errors.trackLine && "error"}
                                    value={+value || "disabled"}
                                    onChange={({ target: { value } }) =>
                                      onChange(+value)
                                    }
                                  >
                                    <option disabled value="disabled">
                                      {`-- ${commenContent.selectOption[culture]} --`}
                                    </option>
                                    {env.trackLine.map((item, index) => (
                                      <option key={item} value={index + 1}>
                                        {item}
                                      </option>
                                    ))}
                                  </Input>
                                )}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6" lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="size"
                              >
                                {commenContent.size[culture]}
                              </label>
                              <Controller
                                control={control}
                                name="size"
                                rules={{ required: "Size feild is required" }}
                                render={({
                                  field: { ref, onChange, value, ...field },
                                }) => (
                                  <Input
                                    {...field}
                                    id="size"
                                    placeholder={commenContent.size[culture]}
                                    type="select"
                                    className={errors.size && "error"}
                                    value={value || "disabled"}
                                    onChange={({ target: { value } }) =>
                                      onChange(+value)
                                    }
                                  >
                                    <option disabled value="disabled">
                                      {`-- ${commenContent.selectOption[culture]} --`}
                                    </option>
                                    {env.size.map((size, index) => (
                                      <option key={size} value={index + 1}>
                                        {size}
                                      </option>
                                    ))}
                                  </Input>
                                )}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="costCenter"
                          >
                            {commenContent.costCenter[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="costCenter"
                            rules={{ required: "costCenter feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="costCenter"
                                placeholder={`-- ${commenContent.costCenter[culture]} --`}
                                type="text"
                                className={errors.costCenter && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onClick={() => setCostCenterModal(true)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="entryCurrency"
                          >
                            {commenContent.currency[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="entryCurrency"
                            rules={{ required: "currency feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="currency"
                                placeholder={`-- ${commenContent.currency[culture]} --`}
                                type="select"
                                className={errors.entryCurrency && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(+value)
                                }
                              >
                                <option value="" disabled>
                                  -- {commenContent.selectOption[culture]} --
                                </option>
                                {env.entryCurrency.map(
                                  (currency, index) => (
                                    <option key={index} value={index + 1}>
                                      {currency[culture]}
                                    </option>
                                  )
                                )}
                              </Input>
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="success" type="submit">
                      <span className="btn-inner--icon me-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{commenContent.create[culture]}</span>
                    </Button>
                  </form>
                )}
              </CardBody>
            </Card>
            <Card className="card-plain">
              <Pagination
                dataLength={data.length}
                // when the user search using the popup and select a row
                // after then only use the pagination states from searchPopup hook
                pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
                setPageNo={
                  searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
                }
                docsPerPage={
                  searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
                }
                setDocsPerPage={
                  searchPopup.isSelected
                    ? searchPopup.setDocsPerPage
                    : setDocsPerPage
                }
              >
                <Table
                  pageName={pageName}
                  headers={headers}
                  columns={columns}
                  setHeaders={setHeaders}
                  setColumns={setColumns}
                  // when the user search using the popup and select a row
                  // after then only use the hookData states from searchPopup hook
                  data={data}
                  setData={setData}
                  onHeaderClick={searchPopup.handleHeaderClicked}
                  visitedRowId={paginationCtx.visitedRowId}
                  // when the user was searching useing the popup and select a row
                  // store the popup pagination in the context
                  // otherwise store the normal pagination in the context
                  setPagination={
                    searchPopup.isSelected
                      ? (rowId) =>
                          paginationCtx.setPagination(
                            searchPopup.pageNo,
                            searchPopup.docsPerPage,
                            rowId,
                            pageName
                          )
                      : (rowId) =>
                          paginationCtx.setPagination(
                            pageNo,
                            docsPerPage,
                            rowId,
                            pageName
                          )
                  }
                >
                  <span to={`/${culture}-admin/bulk/${shipmentType}`}>
                    <div id="details1" className="table-action cursor-pointer">
                      <i className="far fa-eye fa-lg hover-success"></i>
                    </div>
                    <UncontrolledTooltip delay={0} target="details1">
                      {commenContent.showDetails[culture]}
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
              <TextPopup
                modal={tableModal}
                text={commenContent.next[culture]}
                disabled={handleDisabled()}
                handleCancel={handleCancel}
                fn={handleCancel}
                color="primary"
              >
                <CustomerPopup
                  multiple
                  create
                  shipmentType={shipmentType}
                  setValue={setValue}
                  clients={clients}
                  sweetAlert={sweetAlert}
                  setClients={setClients}
                  setTableModal={setTableModal}
                />
              </TextPopup>
              <TextPopup
                modal={countryTable}
                text={commenContent.next[culture]}
                handleCancel={handleCancel}
                fn={() => {}}
                color="primary"
                noBtn
              >
                <Container>
                  <CountryTable
                    // setCountry={setCountry}
                    setCountryTable={setCountryTable}
                    setValue={setValue}
                    sweetAlert={sweetAlert}
                  />
                </Container>
              </TextPopup>
              <TextPopup
                modal={costCenterModal}
                text={commenContent.next[culture]}
                handleCancel={handleCancel}
                fn={() => {}}
                color="primary"
                noBtn
              >
                <Container>
                  <CostCenterTable
                    // setCountry={setCountry}
                    setModal={setCostCenterModal}
                    setValue={setValue}
                    sweetAlert={sweetAlert}
                  />
                </Container>
              </TextPopup>
              {searchPopup.openPopup && (
                <SearchModal
                  data={data}
                  setData={setData}
                  updateData={updateData}
                  searchPopup={searchPopup}
                  editResponse={editResponse}
                  popupSearchCtx={paginationCtx.popupSearch}
                  extraField={{
                    column: "bulkPckgNum",
                    header: commenContent.code[culture],
                  }}
                  page="Bulk"
                />
              )}
            </Card>
          </Container>
        </>
      )}
    </div>
  );
}

export default BulkActions;
