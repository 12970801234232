import React, { useReducer, useState, useEffect, useMemo } from "react";
import content from "./content"//router
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Form,
} from "reactstrap";
import { useHistory, useParams } from "react-router";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import { useForm, Controller } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import Table from "./components/Table";
import UserTable from "./components/UserTable";
import user from "api/user";
import abilityReducer from "./components/AbilityReducer";
import useAbility from "hooks/useAbility";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const initialState = {
  initialAbility: [
    {
      claimName: "Country",
      claimValue: "getByPopupCoulum",
    },
    {
      claimName: "Customer",
      claimValue: "getByPopupCoulum",
    },
  ],
  userAbility: [
    {
      claimName: "Country",
      claimValue: "getByPopupCoulum",
    },
    {
      claimName: "Customer",
      claimValue: "getByPopupCoulum",
    },
  ],
};

const AbilityForm = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const history = useHistory();
  const { id } = useParams();

  const checkAbility = useAbility("User");
  const { alert, sweetAlert } = useAlert();
  const [spinner, setSpinner] = useState(false);
  const [userState, dispatchAbility] = useReducer(abilityReducer, initialState);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const saveApi = useApi(user.addRole);

  const findUserByIdApi = useApi(user.getById);

  const handleSave = async (data) => {
    setSpinner(true);
    // This is the initial abilities (Popup abilities)
    const userAbility = [...userState.userAbility]

    // remove any hard coded property
    // then i will added them manually 
    // so they won't repeat
    for (let i = userAbility.length - 1; i >= 0; i--) {
      if (
        userAbility[i].claimValue === "getByPopupCoulum"||
        userAbility[i].claimValue === "getUserCountryCoulum"
      ) {
        userAbility.splice(i, 1);
      }
    }

    const popupAbilities = [
      {
        claimName: "Country",
        claimValue: "getByPopupCoulum",
      },
      {
        claimName: "Country",
        claimValue: "getUserCountryCoulum",
      },
      {
        claimName: "Customer",
        claimValue: "getByPopupCoulum",
      },
    ];

    // add the hard coded property 
    const schema = {
      userId: data.userId,
      rolesModel: [...userAbility, ...popupAbilities],
    };


    const res = await saveApi.request(schema, id);

    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      history.push(`/en-admin/users`);
    }
    setSpinner(false);
  };

  const getUserById = async () => {
    const res = await findUserByIdApi.request(id);

    if (res.status === 200) {
      setValue("userId", res.data.data.id);
      setValue("user", res.data.data.fullName);
      dispatchAbility({
        type: "PRELOAD_ABILITIES",
        abilities: res.data.data.claims,
      });
    }
  };

  const abilityChanged = () => {
    const { userAbility, initialAbility } = userState;

    if (userAbility.length !== initialAbility.length) return true;

    // This will get the diffrent between the user abilities and the changed abilities
    const results = userAbility.filter(
      ({ claimName: name1, claimValue: value1 }) =>
        !initialAbility.some(
          ({ claimName: name2, claimValue: value2 }) =>
            name1 === name2 && value1 === value2
        )
    );

    if (results.length) return true;

    return false;
  };

  useEffect(() => {
    getUserById();
  }, []);

  if (findUserByIdApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  if (!checkAbility("edit")) return <></>;

  return (
    <>
      {alert}
      {saveApi.errorAlert}
      {findUserByIdApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.ability[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0">{content.ability[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleSave)}>
              <Row>
                <Col md="4" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="user">
                      {content.user[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="user"
                      rules={{
                        required: `user field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="user"
                          placeholder={`-- ${content.selectUser[culture]} --`}
                          type="text"
                          className={errors.user && "error"}
                          value={value || ""}
                          onWheel={(e) => e.target.blur()}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col className="align-self-center mt-1">
                  <Button
                    color="success"
                    type="submit"
                    disabled={!abilityChanged()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>{commenContent.save[culture]}</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <Table
            abilities={userState.userAbility}
            dispatchAbility={dispatchAbility}
          />
        </Card>
      </Container>
    </>
  );
};

export default AbilityForm;
