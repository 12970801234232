import React from "react";
import { FormGroup, Input, Container, Row, Col } from "reactstrap";
import content from "../content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

function EditPopup({ editSchema, setEditSchema }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const handleChange = (key, val) => {
    setEditSchema({
      ...editSchema,
      [key]: val,
    });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.priceValue[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder="price"
                type="number"
                value={editSchema.priceValue || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  handleChange("priceValue", Number(e.target.value))
                }
              />
            </FormGroup>
          </Col>
          {/* {editSchema?.priceExChange ?
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example3cols1Input"
                >
                  Exchange
                </label>
                <Input
                  id="example3cols1Input"
                  placeholder="price"
                  type="number"
                  value={editSchema.priceExChange || ""}
                  onWheel={e => e.target.blur()}
                  onChange={(e) => handleChange("priceExChange", Number(e.target.value))}
                />
              </FormGroup>
            </Col>
          : ""} */}
        </Row>
      </Container>
    </div>
  );
}

export default EditPopup;
