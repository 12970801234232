export default {
  tiket: {
    ar: "بطاقة الشكوة",
    en: "Ticket",
  },
  customerServices: {
    ar: "خدمات المستخدمين",
    en: "Customer Services",
  },
  all: {
    ar: "الكل",
    en: "All",
  },
  opened: {
    ar: "مفتوحة",
    en: "Opened",
  },
  resolved: {
    ar: "تم حلها",
    en: "Resolved",
  },
  solved: {
    ar: "تم حلها",
    en: "Solved",
  },
  closed: {
    ar: "تم إغلاقها",
    en: "Closed",
  },
  oldticket: {
    ar: "بطاقة الشكوى القديمة",
    en: "Old Ticket",
  },
  reOpen: {
    ar: "إعادة الفتح",
    en: "Re-open",
  },
  tecketType: {
    ar: "حالة الشكوة",
    en: "Ticket type",
  },
  reOpenOption: {
    ar: "هل انت متأكد من إعادة فتح هذه الشكوة ؟",
    en: "Are you sure you want to reopen this ticket ?",
  },
  assignHolder: {
    ar: "تعيين مالك",
    en: "Assign holder"
  },
  subject: {
    ar: "الموضوع",
    en: "Subject"
  },
  ticketDetails: {
    ar: "تفاصيل الشكوة",
    en: "Ticket details"
  },
  description: {
    ar: "الوصف",
    en: "Description"
  },
  comment: {
    ar: "التعليقات",
    en: "Comment"
  },
  internal: {
    ar: "تعليقات داخلية",
    en: "Internal comments"
  },
  external: {
    ar: "تعليقات خارجية",
    en: "External comments"
  },
  allTicket: {
    ar: "جميع الشكاوي",
    en: "All tickets"
  },
  ticketState: {
    ar: "حالة المشكلة",
    en: "Ticket State"
  },
  ticketHolders: {
    ar: "المالكين للمشكلة",
    en: "Ticket Holders"
  },
  solveTicketPopup: {
    ar: "هل انت متأكد من ان هذه المشكلة قد تم حلها؟",
    en: "Are you sure this Ticket is solved ?"
  },
  deleteTicketPopup: {
    ar: "هل انت متأكد من حذف هذه التذكرة؟",
    en: "Are you sure to delete this ticket ?"
  },
  closeTicketPopup: {
    ar: "هل انت متأكد من انك تريد إغلاق هذه المشكلة ؟",
    en: "Are you sure you want to close this Ticket ?"
  },
  reopenTicketPopup: {
    ar: "هل أنت متأكد أنك تريد إعادة فتح على هذه المشكلة ؟",
    en: "Are you sure you want to reopen on this Ticket ?"
  }
};
