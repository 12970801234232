import { createContext, useReducer } from "react";

// initial State
const initialState = {
  prevPage: '',
  pageNumber: 1,
  docsPerPage: 10,
  sortColumn: "",
  isAscending: "",
  visitedRowId: '',
  searchedContent: '',
  searchedColumn: '',
  setSearch: () => {},
  setPagination: () => {},
  resetPagination: () => {}
};

// Creating a context
export const PaginationContext = createContext(initialState);

// Reducer for the context
const paginationReducer = (state, action) => {

  // This action assign pagination values to the reducer
  if (action.type === 'SET_PAGE') {
    return {
      ...state,
      prevPage: action.prevPage,
      pageNumber: action.pageNumber,
      docsPerPage: action.docsPerPage,
      visitedRowId: action.visitedRowId,
      sortColumn: action.sortColumn,
      isAscending: action.isAscending
    }
  }

  // This action assign searched value to 'searchedContent' state
  if (action.type === 'SEARCH') {
    return {
      ...state,
      searchedContent: action.searchedContent,
      searchedColumn: '',
    }
  } 
  
  // This request handle the popup search
  if (action.type === 'POPUP_SEARCH') {
    return {
      ...state,
      searchedColumn: action.searchedColumn,
      searchedContent: action.searchedContent,
    }
  }
  
  if (action.type === "SORT") {
    // if the current sorted column is the same as the old one
    // only change the ascending

    if (action.sortColumn.includes("-") && state.isAscending === "1")
      return {
        ...state,
        isAscending: "2",
      };

    return {
      ...state,
      sortColumn: action.sortColumn,
      isAscending: "1",
    };
  }

  // This Reset the reducer
  if (action.type === 'RESET') {
    return {
      prevPage: '',
      pageNumber: 1,
      docsPerPage: 10,
      visitedRowId: '',
      searchedColumn: '',
      searchedContent: '',
      sortColumn: "",
      isAscending: ""
    }
  }

  return state;
}

// Context Provider
const PaginationProvider = props => {

  const [pageState, dispatchPage] = useReducer(paginationReducer, initialState);

  const setPagination = (pageNo, docsPerPage, rowId, prevPage, sortColumn, isAscending) => {

    // This triggers 'SET_PAGE' action in the recducer
    dispatchPage({
      type: 'SET_PAGE',
      prevPage: prevPage,
      pageNumber: pageNo,
      docsPerPage: docsPerPage,
      visitedRowId: rowId,
      sortColumn,
      isAscending
    })
  }

  // This triggers 'SEARCH' action in the recducer
  const setSearch = searchContent => {
    dispatchPage({
      type: 'SEARCH',
      searchedContent: searchContent
    })
  }

  const popupSearch = (searchedColumn, searchedContent) => {
    dispatchPage({
      type: 'POPUP_SEARCH',
      searchedColumn,
      searchedContent
    })
  }

  // This triggers 'RESET' action in the recducer
  const resetPagination = () => {

    dispatchPage({ type: 'RESET' })
  }

  const handleSortColumn = (column) => {
    dispatchPage({
      type: "SORT",
      sortColumn: column
    })
  }

  const pageContext = {
    prevPage: pageState.prevPage,
    pageNumber: pageState.pageNumber,
    docsPerPage: pageState.docsPerPage,
    visitedRowId: pageState.visitedRowId,
    searchedContent: pageState.searchedContent,
    searchedColumn: pageState.searchedColumn,
    sortColumn: pageState.sortColumn,
    isAscending: pageState.isAscending,
    setSearch,
    setPagination,
    popupSearch,
    resetPagination,
    handleSortColumn
  }

  return (
    <PaginationContext.Provider value={pageContext}>
      {props.children}
    </PaginationContext.Provider>
  )
}

export default PaginationProvider;