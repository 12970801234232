import client from "./client";

const endpoint = "/v1/sp";

// Air apis
const getAllPays = (shipmentType, page, docsPerPage) =>
  client.get(
    `${endpoint}${shipmentType}/pay?Page=${page}&postPage=${docsPerPage}`
  );

const getByColumnPayAir = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}${shipmentType}/getbycoulumPayAir?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getByColumnPayLcl = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}${shipmentType}/getbycoulumPayLcl?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getByColumnPay = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}${shipmentType}/getbycoulumPay${shipmentType}?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

// Sp apis
const getByColumn = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}${shipmentType}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const payInvoice = (schema, shipmentType) =>
  client.post(`${endpoint}${shipmentType}`, schema);

const spShaheen = (shipmentType, pageNo, docsPerPage, route = "") =>
  client.get(
    `${endpoint}${shipmentType}${route}?page=${pageNo}&postPage=${docsPerPage}`
  );

const amount = (id, schema, shipmentType) =>
  client.put(`${endpoint}${shipmentType}/amount/${id}`, schema);

const cancelInvoice = (id, shipmentType) =>
  client.put(`${endpoint}${shipmentType}/cancel/${id}`);

const updateInvoice = (id, shipmentType) =>
  client.put(`${endpoint}${shipmentType}/update/${id}`);

const getByOrderNumber = (shipmentType, orderNo) =>
  client.get(`/v1/${shipmentType}/GetByOrder?value=${orderNo}`);

const updateApprovingToTransfering = (schema, shipmentType) =>
  client.put(`${endpoint}${shipmentType}/updateApprovingToTransfering`, schema);

export default {
  getByColumn,
  payInvoice,
  spShaheen,
  amount,
  cancelInvoice,
  updateInvoice,
  getByOrderNumber,
  getAllPays,
  getByColumnPay,
  getByColumnPayAir,
  getByColumnPayLcl,
  updateApprovingToTransfering,
};
