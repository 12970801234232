const useTicketDelete = (
    apiFun,
    setSpinner,
    setModal,
    sweetAlert = () => {}
  ) => {
    const deleteRow = async (id, ...params) => {
      setSpinner(true);
      setModal(false);
      const res = await apiFun.request(id, ...params);
  
      if (res.status === 200) {
        sweetAlert("Done");
      }
      setSpinner(false);
    };
  
    return deleteRow;
  };
  
  export default useTicketDelete;