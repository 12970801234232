import { useState } from "react";

const useSearchPopup = (
  searchRequest,
  headers,
  columns,
  data = [],
  editResponse
) => {
  // const [hookData, setHookData] = useState([]);
  const [header, setHeader] = useState("");
  const [column, setColumn] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [openPopup, setOpenPopup] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [searchField, setSearchField] = useState("");
  // const [sortColumn, setSortColumn] = useState("");
  // const [isAscending, setIsAscending] = useState("");

  // This function triggers when the user click on header in the table
  const handleHeaderClicked = (index, setData) => {
    // 1) Open the popup
    setOpenPopup(true);
    // 2) Store the header name to use it for search
    setHeader(headers[index]);
    // 3) Store column name to display it in the popup
    /*
      if the header the first item is 'actions' that means we should decreace 1 from the columns[index]
      because then the headers will have more item than the columns by 1
    */
   const isAction = ["actions", "العمليات"].includes(headers[0].toLowerCase());

   setColumn(isAction ? columns[index - 1] : columns[index]);
  };

  // if the column name has 'text' in the name that means it's an enum
  // remove 'text'
  const handleColumnName = () => {
    if (column === "branch.name" || column === "currentBranch.name") return "branchName";
    if (column.includes("Text")) return column.split("Text")[0];
    if (column.includes("."))
      return column.split(".")[column.split(".").length - 1];
    return column;
  };

  const onSearch = async (args, fun) => {
    // Request The data
    // 'searchRequest' is an useApi hook

    const res = await searchRequest.request(
      handleColumnName(),
      searchField,
      pageNo,
      docsPerPage,
      // sortColumn,
      // isAscending,
      args
    );
    if (res.status === 200) {
      res.data.data = res.data.data?.map((item) => editResponse(item)) || [];
      fun(res.data.data);
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const initPagination = () => {
    setPageNo(1);
    setDocsPerPage(5);
    setSearchField("");
  };

  // const handleSort = (column) => {
  //   // if the current sorted column is the same as the old one
  //   // only change the ascending
  //   if (column === sortColumn)
  //     return setIsAscending((a) => (a === "1" ? "2" : "1"));

  //   setSortColumn(column);
  //   setIsAscending("1");
  // };

  return {
    // hookData,
    header,
    column,
    setColumn,
    onSearch,
    openPopup,
    isSelected,
    setIsSelected,
    pageNo,
    // setHookData,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    searchField,
    setSearchField,
    handleClose,
    initPagination,
    handleColumnName,
    handleHeaderClicked,
    // sortColumn,
    // isAscending,
    // handleSort,
  };
};

export default useSearchPopup;
