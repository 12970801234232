import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import { useForm, Controller } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import PopupTable from "components/common/PopupTableUsed";
import usersPassword from "api/usersPassword";
import Pagination from "components/common/Pagination";
import useAlert from "hooks/useAlert";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import content from "../Users/content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import useAbility from "hooks/useAbility";
import CustomerNotHaveAccount from "./components/CustomerNotHaveAccount";
import customerApi from "api/customer";

function GeneratePassword() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const checkAbility = useAbility("User");

  const headers = [
    commenContent.actions[culture],
    commenContent.name[culture],
    content.customerName[culture],
    commenContent.customerCode[culture],
    commenContent.datecreated[culture],
  ];
  const columns = ["userName", "customerName", "customerCode", "createdOn"];

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [customer, setCustomer] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [rowId, setRowId] = useState("");
  const [resetModal, setResetModal] = useState(false);

  // Apis
  const preload = useApi(customerApi.linkedCustomers);
  const generate = useApi(usersPassword.generateUser);
  const getByColumn = useApi(customerApi.linkedCustomersByCoulum);
  const resetPasswordApi = useApi(usersPassword.resetPassword);

  const editResponse = (res) => {
    res.id = res.applicationUserId;
    res.userName = res.applicationUser.userName;
    res.createdOn = res.applicationUser?.createdOn?.split("T")[0];
    return res;
  };

  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm();
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const userPasswordPreload = async () => {
    const res = await preload.request(pageNo, docsPerPage);
    if (res.status === 200) {
      const customers = res.data.data;
      res.data.data = customers.map((customer) => editResponse(customer));

      setData(res.data.data);
    }
  };

  const generatePassword = async (formData) => {
    setSpinner(true);
    // const schema = new FormData();
    // schema.append("CustomerId", customer.cusId);
    // schema.append("password", formData.password);
    // const schema = {
    //   CustomerId: user.current,
    //   ...formData,
    // }
    const res = await generate.request(formData.id);
    if (res.status === 200) {
      reset()
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const updateData = (arr) => {
    // const editedData = arr.map((res) => editResponse(res));
    setData(arr);
  };

  useEffect(() => {
    userPasswordPreload();
  }, [pageNo, docsPerPage]);

  useEffect(() => {
    if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch("", (data) => {
        updateData(data);
      });
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage]);

  const handleReset = (obj) => {
    setRowId(obj.id);
    setResetModal(true);
  };

  const resetPassword = async () => {
    setResetModal(false);
    setSpinner(true);
    const res = await resetPasswordApi.request(rowId);
    if (res.status === 200) {
      sweetAlert(`${content.changedTo[culture]} ${res.data.data}`)
      // const customerCode = data.find(customer => customer.id === rowId)?.customerCode;
      // const newPassword = `${new Date().getFullYear()}${customerCode}@Sh`;
      // sweetAlert(`New password: ${newPassword}`);
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setTableModal(false);
    setResetModal(false);
  };

  const renderGenerate = () => checkAbility("generateurUsers");

  useEffect(() => {
    if (generate.data?.success === true) {
      userPasswordPreload();
    }
  }, [generate.data]);

  return (
    <div>
      {alert}
      {preload.errorAlert}
      {generate.errorAlert}
      {resetPasswordApi.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {preload.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={["Generate Password"]} />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">
                    {content.generatePassword[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(generatePassword)}>
                  <Row>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.cleint[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="client"
                          rules={{ required: "Client field is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="example3cols1Input"
                              placeholder={content.cleint[culture]}
                              type="text"
                              className={errors.client && "error"}
                              value={value || ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onClick={() => setTableModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="success" type="submit" disabled={!customer}>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>{commenContent.generate[culture]}</span>
                  </Button>
                </form>
              </CardBody>
            </Card>
            <Card>
              <Pagination
                dataLength={data}
                // when the user search using the popup and select a row
                // after then only use the pagination states from searchPopup hook
                pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
                setPageNo={
                  searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
                }
                docsPerPage={
                  searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
                }
                setDocsPerPage={
                  searchPopup.isSelected
                    ? searchPopup.setDocsPerPage
                    : setDocsPerPage
                }
              >
                <Table
                  pageName="generate-password"
                  headers={headers}
                  columns={columns}
                  // when the user search using the popup and select a row
                  // after then only use the hookData states from searchPopup hook
                  data={data}
                  setData={setData}
                  handleReset={handleReset}
                  onHeaderClick={searchPopup.handleHeaderClicked}
                  renderGenerate={renderGenerate}
                >
                  <span
                    className="me-1"
                    fun="handleReset"
                    condition="renderGenerate"
                  >
                    <div
                      id="reset-password"
                      className="table-action cursor-pointer"
                    >
                      <i className="fas fa-redo-alt fa-lg hover-info" />
                    </div>
                    <UncontrolledTooltip delay={0} target="reset-password">
                      Reset password
                    </UncontrolledTooltip>
                  </span>
                </Table>
              </Pagination>
            </Card>
          </Container>
          <TextPopup
            modal={tableModal}
            text="Next"
            handleCancel={handleCancel}
            fn={() => {}}
            color="primary"
            noBtn
          >
            <Container>
              <CustomerNotHaveAccount 
                setTableModal={setTableModal}
                setValue={setValue}
                sweetAlert={sweetAlert}
              />
            </Container>
          </TextPopup>
          <TextPopup
            modal={resetModal}
            text="reset"
            handleCancel={handleCancel}
            fn={resetPassword}
            color="primary"
            name={data.find((user) => user.id === rowId)?.fullName}
          >
            <Container>
              <h3>Are you sure you want to reset this user's password ?</h3>
            </Container>
          </TextPopup>
          {searchPopup.openPopup && (
            <SearchModal
              data={data}
              setData={setData}
              updateData={updateData}
              searchPopup={searchPopup}
              editResponse={editResponse}
              // popupSearchCtx={paginationCtx.popupSearch}
              page="generatePassword"
            />
          )}
        </>
      )}
    </div>
  );
}

export default GeneratePassword;
