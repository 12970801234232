import React, { useState, useEffect } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import template from "./templateApi";
import useApi from "hooks/useApi";
import Spinner from "../../components/Spinner";
import useAlert from "hooks/useAlert";
import { useParams, useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Plans = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const [cards, setCards] = useState([1]);
  const [subText, setSubText] = useState([1]);
  const [spinner, setSpinner] = useState(false);

  const templateApi = useApi(template.getPortfolio);
  const plansApi = useApi(template.postPlans);

  const { alert, sweetAlert } = useAlert();

  const { shipmentType } = useParams();
  const location = useLocation();

  const field =
    shipmentType.toLowerCase() === "air" ? "priceTableAir" : "priceTableOcean";

  const handleAddRow = () => {
    const newCards = [...cards, cards.length + 1];
    setCards(newCards);
  };

  const handleRemove = (index) => {
    const newCards = [...cards];
    newCards.splice(index, 1);
    setCards(newCards);
  };

  const handleAddText = () => {
    const newText = [...subText, subText.length + 1];
    setSubText(newText);
  };

  const handleDeleteText = () => {
    const newText = [...subText];
    newText.pop();
    setSubText(newText);
  };

  const preload = async () => {
    const res = await templateApi.request();

    if (res.status === 200) {
      const resData = res.data.data;

      const cardArrays = [];
      const subTextArray = [];

      for (let i = 1; i <= resData[field]?.length; i++) {
        cardArrays.push(i);
      }
      for (let i = 1; i <= resData[field]?.[0]?.subText?.length; i++) {
        subTextArray.push(i);
      }

      setCards(cardArrays);
      setSubText(subTextArray);

      resData[field].forEach((item, index) => {
        setValue(`title-${index + 1}`, item.title || "");
        setValue(`price-${index + 1}`, item.price || "");
        setValue(`arTitle-${index + 1}`, item.arTitle || "");
        setValue(`unit-${index + 1}`, item.unit || "");
        setValue(`arUnit-${index + 1}`, item.arUnit || "");
        item.subText?.forEach((text, i) => {
          setValue(`subText-${index + 1}-${i + 1}`, text);
        });
        item.arSubText?.forEach((text, i) => {
          setValue(`arSubText-${index + 1}-${i + 1}`, text);
        });
      });
    }
  };

  const submit = async (data) => {
    setSpinner(true);

    const structureData = [];
    for (const key in data) {
      const propName = key.split("-")[0];
      const index = +key.split("-")[1] - 1;
      if (key.match(/title/gi) || key.match(/price/gi) || key.match(/unit/gi)) {
        if (structureData[index]) {
          structureData[index] = {
            ...structureData[index],
            [propName]: data[key],
          };
        } else {
          structureData.push({
            [propName]: data[key],
          });
        }
      }
    }

    for (const key in data) {
      const propName = key.split("-")[0];
      const index = +key.split("-")[1] - 1;
      if (key.match(/subText/gi)) {
        if (structureData[index]) {
          if (structureData[index]?.[propName]) {
            structureData[index] = {
              ...structureData[index],
              [propName]: [...structureData[index][propName], data[key]],
            };
          } else {
            structureData[index][propName] = [data[key]];
          }
        }
      }
    }

    const res = await plansApi.request({ [field]: structureData });

    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  useEffect(() => {
    reset();
    preload();
  }, [location]);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {plansApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {shipmentType.toLowerCase() === "air"
                  ? content.airPlan[culture]
                  : content.seaPlan[culture]}
              </h3>
            </div>
          </CardHeader>
        </Card>
        <form onSubmit={handleSubmit(submit)}>
          {cards.map((item, index) => (
            <Card key={item}>
              <CardBody>
                <Row className="justify-content-between">
                  <Col xs="11">
                    <p>
                      {shipmentType.toLowerCase() === "air" ? "Air" : "Ocean"} (
                      {item})
                    </p>
                  </Col>
                  <Col xs="1">
                    {item > 1 && (
                      <Button
                        color="danger"
                        type="button"
                        size="sm"
                        onClick={() => handleRemove(index)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Title - {item}
                          </label>
                          <Controller
                            control={control}
                            name={`title-${item}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="name"
                                placeholder="Name"
                                type="text"
                                className={errors[`title-${item}`] && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Price - {item}
                          </label>
                          <Controller
                            control={control}
                            name={`price-${item}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="name"
                                placeholder="Name"
                                type="number"
                                className={errors[`price-${item}`] && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="unit">
                            Unit - {item}
                          </label>
                          <Controller
                            control={control}
                            name={`unit-${item}`}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="name"
                                placeholder="unit"
                                type="text"
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {subText.map((no) => (
                        <Col md="12" key={`subText-${item}-${no}`}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor={`subText-${item}-${no}`}
                            >
                              Sub text - {no}
                            </label>
                            <Controller
                              control={control}
                              name={`subText-${item}-${no}`}
                              rules={{
                                required: "name field is required",
                              }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id={`subText-${item}-${no}`}
                                  placeholder="Name"
                                  type="textArea"
                                  className={
                                    errors[`subText-${item}-${no}`] && "error"
                                  }
                                  value={value || ""}
                                  onChange={({ target: { value } }) =>
                                    onChange(value)
                                  }
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col md="6" className="rtl-col">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`price-${item}`}
                          >
                            السعر
                          </label>
                          <Controller
                            control={control}
                            name={`price-${item}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id={`price-${item}`}
                                placeholder="السعر"
                                type="text"
                                className={errors[`price-${item}`] && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" className="rtl-col">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`arTitle-${item}`}
                          >
                            العنوان
                          </label>
                          <Controller
                            control={control}
                            name={`arTitle-${item}`}
                            rules={{
                              required: "name field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id={`arHeader-${item}`}
                                placeholder="العنوان"
                                type="text"
                                className={errors[`arTitle-${item}`] && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6" className="rtl-col">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={`arUnit-${item}`}
                          >
                            العنوان
                          </label>
                          <Controller
                            control={control}
                            name={`arUnit-${item}`}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id={`arHeader-${item}`}
                                placeholder="الوحدة"
                                type="text"
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {subText.map((no) => (
                        <Col
                          md="12"
                          className="rtl-col"
                          key={`arSubText-${item}-${no}`}
                        >
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor={`arSubText-${item}-${no}`}
                            >
                              نص فرعي ({no})
                            </label>
                            <Controller
                              control={control}
                              name={`arSubText-${item}-${no}`}
                              rules={{
                                required: "name field is required",
                              }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id={`arSubText-${item}-${no}`}
                                  placeholder={`نص فرعي (${no})`}
                                  type="textArea"
                                  className={
                                    errors[`arSubText-${item}-${no}`] && "error"
                                  }
                                  value={value || ""}
                                  onChange={({ target: { value } }) =>
                                    onChange(value)
                                  }
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Button
                    color="info"
                    type="button"
                    className="me-1"
                    onClick={handleAddText}
                  >
                    <span className="btn-inner--icon m-1">
                      <i className="ni ni-fat-add" />
                    </span>
                  </Button>

                  {subText.length > 1 && (
                    <Button
                      color="warning"
                      type="button"
                      onClick={handleDeleteText}
                    >
                      <span>{content.deleteSubText[culture]}</span>
                    </Button>
                  )}
                </Row>
              </CardBody>
            </Card>
          ))}
          <Card>
            <CardBody>
              <Button
                color="info"
                type="button"
                className="me-1"
                onClick={handleAddRow}
              >
                <span>{content.addCard[culture]}</span>
              </Button>
              <Button color="success" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </CardBody>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default Plans;
