import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button, Container } from "reactstrap";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import user from "api/user";
import commenContent from "components/common/content";
import useCulture from "hooks/useCulture";
import TextPopup from "components/common/TextModal";

const content = {
  popup: {
    ar: "هل انت متأكد من انك تريد تغيير التذكرة لهذا المالك ؟",
    en: "Are you sure you want to change the Ticket holder to this User ?",
  },
};

function UserPopup({
  fn,
  userId,
  setUserId,
  sweetAlert,
}) {
  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [fullName, setFullName] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  const culture = useCulture();

  // Apis
  const users = useApi(user.users);
  const getByColumn = useApi(user.getByColumn);

  const getAllUsers = async () => {
    const res = await users.request(pageNo, docsPerPage);

    if (res.status === 200) setData(res.data.data);
  };

  const findUserByName = async () => {
    if (!fullName.trim()) return getAllUsers();
    const res = await getByColumn.request(
      "fullName",
      fullName,
      pageNo,
      docsPerPage
    );
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    setUserId(obj.id);
  };

  const handleCancel = () => setUserId("");

  const handleSubmit = () => {
    fn(userId);
    setUserId("");
  }

  useEffect(() => {
    if (fullName.trim().length) getAllUsers();
    else findUserByName();
  }, [pageNo, docsPerPage]);

  useEffect(
    () => users.error && users.data && sweetAlert(users.data, true),
    [users.data]
  );

  useEffect(
    () =>
      getByColumn.error &&
      getByColumn.data &&
      sweetAlert(getByColumn.data, true),
    [getByColumn.data]
  );

  if (getByColumn.loading || users.loading)
    return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="customer-code">
                {commenContent.fullName[culture]}
              </label>
              <Input
                id="customer-code"
                placeholder="search"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findUserByName()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findUserByName}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-search" />
              </span>
              <span>{commenContent.search[culture]}</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={data.length}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={[
            commenContent.actions[culture],
            commenContent.fullName[culture],
          ]}
          columns={["fullName"]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info"></i>
            </div>
          </span>
        </Table>
      </Pagination>
      <TextPopup
        modal={!!userId}
        handleCancel={handleCancel}
        fn={handleSubmit}
        color="primary"
        name={data.find((item) => item.id === userId)?.fullName}
        text="Continue"
      >
        <Container>
          <h2>{content.popup[culture]}</h2>
        </Container>
      </TextPopup>
    </div>
  );
}

export default UserPopup;
