import client from "./accountingClient";

const endpoint = "/v1/entry";

const cargoCreateAndConfirm = (schema) =>
  client.post(`${endpoint}/cargoCreateAndConfirm`, schema);

export default {
  cargoCreateAndConfirm,
};
