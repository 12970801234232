import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import countryApi from "api/country";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import content from "./content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const CountryTable = (props) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const {
    setCountryTable,
    setValue,
    sweetAlert,
    // those when multiple country is selected
    multiple = false,
    selectCountry = () => {},
    isCountryExist = () => {},
    countries = []
  } = props;

  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [client, setClient] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const findByName = useApi(multiple ? countryApi.getUserCountryCoulum : countryApi.getByPopupCoulum);

  const findCountryByName = async () => {
    const res = await findByName.request("name", client, pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    if (multiple) return selectCountry(obj.name);
    setCountryTable(false);
    setValue("country", obj.name);
    setValue("countryId", obj.id);
  };

  const handleUnselect = countryName => {
    selectCountry(countryName);
  }

  const renderSelect = (obj) => !isCountryExist(obj.name);
  const renderRemove = (obj) => isCountryExist(obj.name);

  useEffect(() => {
    findCountryByName();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
      findByName.error && findByName.data && sweetAlert(findByName.data, true),
    [findByName.data]
  );

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.country[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.search[culture]}
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findCountryByName()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findCountryByName}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span>{content.search[culture]}</span>
            </Button>
          </span>
        </Row>
      </div>
      <ul className="popup-customer-list p-1">
        {countries.map((country) => (
          <li key={country}>
            <i
              className="fas fa-times hover-danger"
              onClick={handleUnselect.bind(null, country)}
            />
            {country}
          </li>
        ))}
      </ul>
      <Pagination
        dataLength={data?.length || 0}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={[content.actions[culture], content.name[culture]]}
          columns={["name"]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          renderSelect={renderSelect}
          renderRemove={renderRemove}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect" condition="renderSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info" />
            </div>
          </span>
          <span className="m-2" fun="handleSelect" condition="renderRemove">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-times fa-lg hover-danger" />
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
};

export default CountryTable;
