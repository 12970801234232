import { useEffect, useContext, useState } from "react";
import { AdvanceSearchContext } from "context/AdvanceSearchContext";

const useCheckRequest = ({
  pageNo,
  isRemove,
  setIsRemove,
  requests,
  setPageNo,
  setDocsPerPage,
  docsPerPage,
  key,
  register,
  depandancies = [],
  resetAdvanceFilter = () => {}
}) => {
  const searchCtx = useContext(AdvanceSearchContext);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const isSelect = searchCtx.isSelect;

  useEffect(() => {
    if (isFirstRender) return;
    resetAdvanceFilter();
    if (key === 0) requests[0]();
  }, [...depandancies]);

  useEffect(() => {
    if (!isRemove) return;
    requests[key]();
    setIsRemove(false);
  }, [isRemove]);

  useEffect(() => {
    if (isSelect) {
      setPageNo(searchCtx.pageNo);
      setDocsPerPage(searchCtx.docsPerPage);
      register({ isSelect: false });
      return;
    }
    if (isFirstRender) setIsFirstRender(false);

    requests[key]();
  }, [pageNo, docsPerPage, key]);
};

export default useCheckRequest;
