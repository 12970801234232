import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import shipment from "api/shipment";
import useApi from "hooks/useApi";
import { useParams } from "react-router";
import shaheenPay from "api/shaheenPay";
import content from "../content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const InfoPopup = ({
  control,
  errors,
  watch,
  orderNumber,
  setValue,
  brokers,
}) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const rmbAmount = watch("rmB_Amount");
  const rate = watch("rate");
  const params = useParams();

  const shipmentType = params.shipmentType === "1" ? "aircargo" : "lcl";

  const getShipmentApi = useApi(shaheenPay.getByOrderNumber);

  const getShipment = async () => {
    const res = await getShipmentApi.request(shipmentType, orderNumber);
    if (res.status === 200) {
      setValue("bankAccount", res.data.data[0]?.bankAccount);
      setValue("customerName", res.data.data[0]?.customer?.customerName);
      setValue("customerCode", res.data.data[0]?.customer?.customerCode);
    }
  };

  useEffect(() => {
    // getShipment();
  }, []);

  useEffect(() => {
    if (rate) {
      const amount = (rmbAmount && rate && rmbAmount / rate)?.toFixed(2) || 0;
      setValue("usD_Amount", amount);
    } else {
      setValue("usD_Amount", "");
    }
  }, [rate]);

  return (
    <Row>
      {getShipmentApi.errorAlert}
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="spCode">
            {content.spCode[culture]}
          </label>
          <Controller
            control={control}
            name="spCode"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="spCode"
                type="text"
                disabled
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="customerName">
            {content.customerName[culture]}
          </label>
          <Controller
            control={control}
            name="customerName"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="customerName"
                type="text"
                disabled
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="customerCode">
            {content.customerCode[culture]}
          </label>
          <Controller
            control={control}
            name="customerCode"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="customerCode"
                type="text"
                disabled
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="RMB_amount">
            {content.rmbAmount[culture]}
          </label>
          <Controller
            control={control}
            name="rmB_Amount"
            rules={{ required: "rmB_Amount field is required" }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="RMB_amount"
                type="number"
                disabled
                className={errors.rmB_Amount && "error"}
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      {/* <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="brokerRate">
            Broker Rate
          </label>
          <Controller
            control={control}
            name="brokerRate"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="brokerRate"
                type="number"
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col> */}
      <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="Rate">
            {commenContent.rate[culture]}
          </label>
          <Controller
            control={control}
            name="rate"
            rules={{ required: "Rate field is required" }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="Rate"
                type="number"
                className={errors.rate && "error"}
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="amount">
            {content.usdAmount[culture]}
          </label>
          <Controller
            control={control}
            name="usD_Amount"
            rules={{ required: "amount field is required" }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="amount"
                type="number"
                className={errors.usD_Amount && "error"}
                value={value || 0}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
                readOnly
              />
            )}
          />
        </FormGroup>
      </Col>
      {/* <Col xs="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="bank account">
            {content.broker[culture]}
          </label>
          <Controller
            control={control}
            name="brokerId"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="bank account"
                type="select"
                value={value || "disabled"}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              >
                <option disabled value="disabled">
                {`-- ${commenContent.selectOption[culture]} --`}
                </option>
                {brokers.map((broker) => (
                  <option key={broker.id} value={broker.id}>
                    {broker.name}
                  </option>
                ))}
              </Input>
            )}
          />
        </FormGroup>
      </Col> */}
      <Col xs="12">
        <FormGroup>
          <label className="form-control-label" htmlFor="bank account">
            {commenContent.bankAccount[culture]}
          </label>
          <Controller
            control={control}
            name="bankAccount"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="bank account"
                type="textarea"
                value={value || ""}
                onWheel={(e) => e.target.blur()}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default InfoPopup;
