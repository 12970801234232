import React from "react";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import { Button } from "reactstrap";
// import * as XLSX from 'xlsx';
import XLSX from "xlsx-js-style";

const content = {
  tableExport: {
    ar: "تصدير الجدول",
    en: "Table export",
  },
};

const ExcelGenerater = (props) => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const { excellData, excellHeaders, excellColumns, pageName = "report" } = props;

  const nestedObj = (object, columns) => {
    // 1) get all the keys as an array
    const keys = columns.split(".");
    // 2) loop throw the keys with the object parameters as an default value
    const nestedVal = keys.reduce(
      (obj, prop) => (obj[prop] ? obj[prop] : " "),
      object
    );
    // 3) return the nested value
    return nestedVal;
  };

  const headers = excellHeaders.filter(
    (item) => item.toLowerCase() !== "actions"
  );

  const handleExport = () => {

    const data = Array(excellData.length);
    excellData.forEach((item, index) => {
      let obj = {};
      headers.forEach((header, i) => {

        if (excellColumns[i].includes(".")) {
          obj = { ...obj, [header]: nestedObj(item, excellColumns[i]) };
        } else {
          obj = { ...obj, [header]: item[excellColumns[i]] };
        }
      });

      data[index] = obj;
    });

    var wscols = [];

    excellColumns.forEach(() => wscols.push({ width: 25 }));

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "myFile");

    // Define headers
    const letters = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
      "K1",
      "L1",
      "M1",
      "N1",
      "O1",
      "P1",
      "Q1",
      "R1",
      "S1",
      "T1",
      "U1",
      "V1"
    ];

    // style the headers with bg color and font bold
    for (let i = 0; i < headers.length; i++) {
      ws[letters[i]].s = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FF939393" },
        },
        alignment:{ wrapText: true },
        font: {
          name: "Times New Roman",
          sz: 16,
          color: { rgb: "#FF000000" },
          bold: false,
          italic: false,
          underline: false,
        },
      };
    }

    // append width for the columns
    ws["!cols"] = wscols;

    XLSX.writeFile(wb, `${pageName}-${Date.now()}.xlsx`);
  };

  return (
    <Button
      color="success"
      outline
      size="sm"
      type="button"
      className="cml-2"
      onClick={handleExport}
    >
      <span className="btn-inner--icon me-1">
        <i className="fas fa-file-pdf" />
      </span>
      <span className="btn-inner--text">{content.tableExport[culture]}</span>
    </Button>
  );
};

export default ExcelGenerater;
