import useCulture from "hooks/useCulture";
import React from "react";
import { Controller } from "react-hook-form";
import { CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import commenContent from "./content";

const ChangeCargoType = ({ shipmentType, control, errors, cargoType = "air" }) => {
  const culture = useCulture();

  const otherTypeField = cargoType === "aircargo" ? "volume" : "weight";

  return (
    <CardBody>
      <Row>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="weight">
              {commenContent[otherTypeField][culture]}
            </label>
            <Controller
              control={control}
              name={otherTypeField}
              rules={{ required: "weight field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="weight"
                  type="number"
                  value={value || ""}
                  className={errors[otherTypeField] && "error"}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(+value)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  );
};

export default ChangeCargoType;
