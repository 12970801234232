import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import FilePicker from "components/common/FilePicker";
import template from "./templateApi";
import useApi from "hooks/useApi";
import Spinner from "../../components/Spinner";
import useAlert from "hooks/useAlert";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Info = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [spinner, setSpinner] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const templateApi = useApi(template.getPortfolio);
  const headerApi = useApi(template.postHeader);

  const { alert, sweetAlert } = useAlert();

  const preload = async () => {
    const res = await templateApi.request();

    if (res.status === 200) {
      setValue("phone", res.data.data?.phone || "");
      setValue("email", res.data.data?.email || "");
      setValue("location", res.data.data?.location || "");
      setValue("arLocation", res.data.data?.arLocation || "");
    }
  };

  const submitHeader = async (data) => {
    setSpinner(true);

    const res = await headerApi.request(data);

    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  useEffect(() => {
    preload();
  }, []);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {headerApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.info[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(submitHeader)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="phone">
                      {content.info[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="phone"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="name"
                          placeholder={content.phone[culture]}
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      {content.email[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="email"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="email"
                          placeholder={content.email[culture]}
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="location">
                      Location
                    </label>
                    <Controller
                      control={control}
                      name="location"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="location"
                          placeholder="location"
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" className="rtl-col">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="ar-location">
                      الموقع
                    </label>
                    <Controller
                      control={control}
                      name="arLocation"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="ar-location"
                          placeholder="الموقع"
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="success" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Info;
