import client from "./client";

const endpoint = "/v1";

const getFUS = (shipmentType, page, docsPerPage) =>
  client.get(
    `${endpoint}/${shipmentType}/FollwoUpIndex?Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getByColumn = (col, val, page, docsPerPage, shipmentType) =>
  client.get(
    `${endpoint}/${shipmentType}/GetByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const advanceSearch = (schema, page, docsPerPage, shipmentType) =>
  client.put(
    `${endpoint}/${shipmentType}/AdvanceFilter?Page=${page}&PostsPerPage=${docsPerPage}`,
    schema
  );

export default {
  getFUS,
  getByColumn,
  advanceSearch,
};
