import client from "./client";

const endpoint = "/v1/warehouse";

const preload = (page, docPerPage) =>
  client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const getAll = (page, docPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const getOne = (id) => client.get(`${endpoint}/getById?id=${id}`);

const createOne = (schema) => client.post(`${endpoint}`, schema);

const findByName = (name, page, docPerPage) =>
  client.get(
    `${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`
  );

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const deleteOne = (id) => client.delete(`${endpoint}/${id}`);

const updateOne = (id, schema) => client.put(`${endpoint}/${id}`, schema);

export default {
  preload,
  getAll,
  getOne,
  createOne,
  updateOne,
  deleteOne,
  findByName,
  getByColumn,
};
