/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


// router 
import {
    Link,
    useRouteMatch,
    useHistory
  } from "react-router-dom";

  // get culture from utils 
import getCulture from "utils/getCulture";

// core components
import AuthHeader from "components/Headers/AuthHeader";

// content 
import content from "./content";
import { useForm, Controller } from "react-hook-form";
import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import auth from "api/auth";
import ReactBSAlert from "react-bootstrap-sweetalert";

function Register() {
  const history = useHistory();
  let rout = useRouteMatch()
  let culture = getCulture(rout.url) ;
  const [alert, setAlert] = useState(null);
  const [showPassword, setShowpassword] = useState(false);
  const [showConfermPassword, setConfermShowpassword] = useState(false);
 
  // Apis
  const signup = useApi(auth.dashboardSignup);

  const { handleSubmit, formState: { errors }, control, watch, register } = useForm({
    defaultValues: {
      userType: 1,
      DocType: 1,
      culture: 1
    }
  });

  const password = useRef({});
  // Store the password in the ref so i can compare it with confirm password field
  password.current = watch("password", "");
  
  const handleSignup = async formData => {
    await signup.request(formData);
  }

  useEffect(() => {
    if (localStorage.getItem(btoa('token'))) {
      history.replace(`/${culture}-admin/dashboard`)
    }
    return () => {}
  }, [])

  useEffect(() => {

    if (signup.data?.status === "success") {
      history.replace("/en-auth/login")
    }  
  }, [signup.data])

  const sweetAlert = (text, warning = false) => {
    setAlert(
      <ReactBSAlert
        show={true}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText="Ok"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    ); 
  }

  useEffect(() => (signup.error && signup.data) && sweetAlert(signup.data ,true), [signup.data])

  return (
    <>
      {signup.loading ? <Spinner opacity /> : null}
      {alert}
     <div  style={{ marginBottom: "-6.5%", paddingTop: 1 }} />
      <AuthHeader
        title={content.title[culture]}
        lead={content.lead[culture]}
      />
      <Container className="mt--8 pb-5 signup">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
            <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small style={{ fontSize: "20px"} }>{content.signupTitle[culture]}</small>
                </div>
                <div  style={{ marginTop: "20px" }} className="btn-wrapper text-center">
                  {
                    culture == "ar" ? 
                    <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  > 
                  <span style={{color: "#000"} }className="btn-inner--text">Gateway Ly</span>
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        style={{ width: 50}}
                        src={
                          require("assets/img/brand/gw.png").default
                        }
                      />
                    </span>
                   
                  </Button> 
                  :
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  > 
                  
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        style={{ width: 50}}
                        src={
                          require("assets/img/brand/gw.png").default
                        }
                      />
                    </span>
                    <span style={{color: "#000"} }className="btn-inner--text">Gateway Ly</span>
                   
                  </Button>

                  }

                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>{content.plsFill[culture]}</small>
                </div>
                <Form role="form" onSubmit={handleSubmit(handleSignup)} autoComplete="off">
                <FormGroup
                    className={classnames("mb-3")}
                  >
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl ${errors.username && "error"}`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="username"
                        rules={{ 
                          required: "Username field is required",
                          pattern: {
                            value: /^[a-zA-Z0-9!@#$%^&*()-+=_.-]*$/,
                            message: "invalid username"
                          }
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={content.userName[culture]}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                <FormGroup
                    className={classnames("mb-3")}
                  >
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl ${errors.fullName && "error"}`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="fullName"
                        rules={{required: "full name field is required"}}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder="full name"
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative mb- ${errors.email && "error"}`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="email"
                        rules={{ 
                          required: "Email field is required",
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                          }
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            placeholder={content.email[culture]}
                            type="text"
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup
                      className={`input-group-merge input-group-alternative ${errors.password && "error"}`}
                    >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password"
                        rules={{ 
                          required: "Password field is required", 
                          minLength: {
                            value: 8,
                            message: "Password at least should be 8 characters"
                          }
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            placeholder={content.password[culture]}
                            type={ showPassword ? "text" : "password"}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                      <Button color="secondary" outline type="button" onClick={() => setShowpassword(!showPassword)} >
                      <i className={ showPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup
                      className={`input-group-merge input-group-alternative ${errors.confirmPassword && "error"}`}
                    >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="confirmPassword"
                        rules={{ 
                          required: "Password field is required", 
                          minLength: {
                            value: 8,
                            message: "Password at least should be 8 characters"
                          },
                          validate: value => value === password.current || "The password do not match"
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            placeholder={content.confermPassword[culture]}
                            type={ showConfermPassword ? "text" : "password"}
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                      <Button color="secondary" outline type="button" onClick={() => setConfermShowpassword(!showConfermPassword)} >
                      <i className={ showConfermPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-building"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="companyName"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="text"
                            placeholder={"Company name"}
                            autoComplete="off"
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl ${errors.WhatsappPhone && "error"}`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fab fa-whatsapp"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="WhatsappPhone"
                        autoComplete="off"
                        rules={{ 
                          required: "Whats app phone number field is required",
                          pattern: {
                            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "invalid phone number"
                          }
                        }}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="text"
                            autoComplete="off"
                            placeholder="Whatsapp number"
                            onChange={({ target: { value }}) => onChange(value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl ${errors.Files && "error"}`}
                    >
                      <InputGroupAddon addonType="prepend">
                      </InputGroupAddon>
                      <input
                        type="file"
                        placeholder={"Whatsapp number"}
                        {...register("Files", { required: true })}
                        multiple
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl`}
                    >
                      <Controller
                        control={control}
                        name="userType"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="select"
                            value={value}
                            onChange={({ target: { value }}) => onChange(value)}
                          >
                            <option value={1}>شخصي</option>
                            <option value={2}>شركات</option>
                          </Input>
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl`}
                    >
                      <Controller
                        control={control}
                        name="DocType"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="select"
                            onChange={({ target: { value }}) => onChange(value)}
                          >
                            <option value={1}>وثائق شخصية</option>
                            <option value={2}>وثائق شركة</option>
                          </Input>
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup 
                      className={`input-group-merge input-group-alternative rtl`}
                    >
                      <Controller
                        control={control}
                        name="culture"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <Input
                            {...field}
                            type="select"
                            onChange={({ target: { value }}) => onChange(value)}
                          >
                            <option value={1}>العربية</option>
                            <option value={2}>English</option>
                          </Input>
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-4" color="success" type="submit">
                      {content.create[culture]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">

              <Col className="text-center" xs="6">
                <Link style={{ color: "#BFC8D0"}} to={ culture == "ar" ? "/ar-auth/login" : "/en-auth/login"} ><small>{content.login[culture]}</small> </Link>
              </Col>              

              <Col className="text-center" xs="6">
               { culture == "ar" ?
                <Link style={{ color: "#2DCE89"}} to="/en-auth/register" >English</Link>
                :
                <Link style={{ color: "#2DCE89"}} to="/ar-auth/register" >العربية	&nbsp; 	&nbsp;</Link>
              }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
