import React from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import content from "../content";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import commenContent from "components/common/content";

const EditInvoice = ({ control, errors }) => {
  // Use form hook
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  return (
    <div>
      <Row>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="value">
              {content.valueDollar[culture]}
            </label>
            <Controller
              control={control}
              name="value"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="value"
                  type="number"
                  min={0}
                  className={errors.value && "error"}
                  value={value}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="yuan">
              {content.valueYuan[culture]}
            </label>
            <Controller
              control={control}
              name="valueIwan"
              // rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="yuan"
                  type="number"
                  min={0}
                  // className={errors.value && "error"}
                  value={value}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="dollar">
              {content.rcDollar[culture]}
            </label>
            <Controller
              control={control}
              name="remittanceCommissionDollar"
              rules={{ required: "RCD field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="dollar"
                  type="number"
                  min={0}
                  className={errors.client && "error"}
                  value={value}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="dinar">
              {content.rcDinar[culture]}
            </label>
            <Controller
              control={control}
              name="remittanceCommissionDinar"
              rules={{ required: "Client field is required" }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="dinar"
                  type="number"
                  min={0}
                  className={errors.dinar && "error"}
                  value={value}
                  onWheel={(e) => e.target.blur()}
                  onChange={({ target: { value } }) => onChange(Number(value))}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="bankAccount">
            {commenContent.bankAccount[culture]}
            </label>
            <Controller
              control={control}
              name="bankAccount"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="bankAccount"
                  type="text"
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default EditInvoice;
