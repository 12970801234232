import React from "react";
import { Controller } from "react-hook-form";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import commonContent from "components/common/content";
import env from "env";

const EditCountry = ({ control, errors, branches }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  return (
    <Row>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            {commonContent.name[culture]}
          </label>
          <Controller
            control={control}
            name="customerName"
            rules={{
              required: "name field is required",
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="name"
                placeholder={commonContent.name[culture]}
                type="text"
                className={errors.customerName && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="phone">
            {commonContent.phone[culture]}
          </label>
          <Controller
            control={control}
            name="phone"
            rules={{
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                message: "only english character allowed",
              },
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="code"
                placeholder={commonContent.phone[culture]}
                type="text"
                className={errors.phone && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="priceType">
            {commonContent.type[culture]}
          </label>
          <Controller
            control={control}
            name="priceType"
            rules={{ required: true }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="priceType"
                type="select"
                className={errors.priceType && "error"}
                value={value || "disabled"}
                onChange={({ target: { value } }) => onChange(+value)}
              >
                <option disabled value="disabled">
                  -- {commonContent.selectAnOption[culture]} --
                </option>
                {env.customerType.map((type, i) => (
                  <option value={i + 1}>{type[culture]}</option>
                ))}
              </Input>
            )}
          />
        </FormGroup>
      </Col>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="currentBranchId">
            {commonContent.branch[culture]}
          </label>
          <Controller
            control={control}
            name="currentBranchId"
            rules={{
              required: true,
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="currentBranchId"
                type="select"
                className={errors.currentBranchId && "error"}
                value={value || "disabled"}
                onChange={({ target: { value } }) => onChange(value)}
              >
                <option disabled value="disabled">
                  -- {commonContent.selectAnOption[culture]} --
                </option>
                {branches?.map((branch) => (
                  <option value={branch.id} key={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </Input>
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default EditCountry;
