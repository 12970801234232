import React, { useState } from "react";
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

const NavPills = ({ links, navPills, setNavPills }) => {
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setNavPills(index);
  };

  return (
    <Nav
      className="nav-fill flex-column flex-sm-row"
      id="tabs-text"
      pills
      role="tablist"
    >
      {links.map((item, index) => (
        <NavItem key={item}>
          <NavLink
            aria-selected={navPills === index}
            className={classnames("mb-sm-3 mb-md-0 nav-tab", {
              active: navPills === index,
            })}
            onClick={(e) => toggleNavs(e, index)}
            href="#pablo"
            role="tab"
          >
            {item}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default NavPills;
