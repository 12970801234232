import client from "./client";

const endpoint = "/v1/LCL";

const LCLPreload = (page, docPerPage) =>
  client.get(`${endpoint}/Preload?Page=${page}&PostsPerPage=${docPerPage}`);

const createLCL = (schema) => client.post(`${endpoint}`, schema);

const updateLCL = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getLCL = (id) => client.get(`${endpoint}/GetById?id=${id}`);

const approveLCL = (id) => client.put(`${endpoint}/Approve/${id}`);

const uploadImgLCL = (id, imgs) => {
  const data = new FormData();
  for (const key in imgs) {
    data.append("Image", imgs[key]);
  }

  return client.put(`${endpoint}/img/${id}`, data);
};

const getOrderByCode = (id, page, docsPerPage) =>
  client.get(
    `${endpoint}/GetOrderByCode?id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const findSp = (id) => client.get(`${endpoint}/findSpLcl/${id}`);

const advanceSearch = (schema, page, docsPerPage) =>
  client.put(
    `${endpoint}/AdvanceFilter?Page=${page}&PostsPerPage=${docsPerPage}`, schema
  );

const recivedAdvanceSearch = (schema, page, docsPerPage) =>
  client.put(
    `${endpoint}/RecivedAdvanceFilter?Page=${page}&PostsPerPage=${docsPerPage}`, schema
  );

export default {
  approveLCL,
  LCLPreload,
  createLCL,
  updateLCL,
  getLCL,
  uploadImgLCL,
  getOrderByCode,
  getByColumn,
  findSp,
  advanceSearch,
  recivedAdvanceSearch,
};
