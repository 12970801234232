import packagesInBulk from "api/packagesInBulk";

export default {
  resizeWeight: {
    ar: "تغيير الوزن",
    en: "Resize Weight",
  },
  resizeVolume: {
    ar: "تغيير الحجم",
    en: "Resize Volume",
  },
  resizeNumberOfPkgs: {
    ar: "تغيير عدد الحزم",
    en: "resizeNumberOfPkgs",
  },
  resizeNumberOfPkg: {
    ar: "تغيير رقم الحزمة",
    en: "Resize number of package",
  },
  aggregation: {
    ar: "التجميعة",
    en: "aggregation",
  },
  packagesInBulk: {
    ar: "البضائع في التجميعة",
    en: "PackagesIn Bulk",
  },
  bulks: {
    ar: "التجميعات",
    en: "Bulks",
  },
  theAmountPaid: {
    ar: "المبلغ المدفوع",
    en: "The amount paid",
  },
  theRemainingAmount: {
    ar: "المبلغ المتبقي",
    en: "The Remaining amount",
  },
  printCustomer: {
    ar: "انت على وشك طباعة الزبون",
    en: "You're about to print customer",
  },
  invoiceToPaid: {
    ar: "انت على وشك تغيير حالة الفاتوة الى مدفوعة",
    en: "You're about to change this invoice to paid",
  },
  selectBulks: {
    ar: "اختر تجميعة",
    en: "Select Bulks",
  },
  bulkPackageNumber: {
    ar: "رقم التجميعة",
    en: "Bulk Package Number",
  },
  editPriceNote: {
    ar: "ملاحظات تعديل السعر",
    en: "Edit price note",
  },
  orderPrice: {
    ar: "سعر الطلبية",
    en: "Order price",
  },
  resize: {
    ar: "إعادة تحجيم",
    en: "Resize",
  },
  deposite: {
    ar: "ايداع زبون",
    en: "Customer deposite",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  selectOption: {
    ar: "اختر خيار",
    en: "Select an option",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  treasury: {
    ar: "خزينة",
    en: "Treasury",
  },
  value: {
    ar: "القيمة",
    en: "Value"
  }
};
