import { useEffect, useState, useContext } from "react";
import FCLBK from "api/FCLBK";
import useApi from "hooks/useApi";
import usePrevRequest from "hooks/usePrevRequest";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import useFormData from "hooks/useFormData";
import commenContent from "components/common/content";
import useCulture from "hooks/useCulture";
import content from "./components/content";
import useSearchPopup from "hooks/useSearchPopup";
import env from "env";
import useAlert from "hooks/useAlert";

const useFCL = (reset) => {
  const culture = useCulture();

  const [data, setData] = useState({});
  const [image, setImage] = useState({});
  const [spinner, setSpinner] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.orderNumber[culture],
    commenContent.customerCode[culture],
    commenContent.customer[culture],
    commenContent.followUpStatus[culture],
    commenContent.country[culture],
    commenContent.date[culture],
    commenContent.recieveDate[culture],
    commenContent.stateDate[culture],
    commenContent.commodity[culture],
    commenContent.branch[culture],
  ]);

  const [columns, setColumns] = useState([
    "orderNumber",
    "customer.customerCode",
    "customer.customerName",
    "followUpStatusText",
    "country.name",
    "createdOn",
    "recieveDate",
    "statusDate",
    "commodity",
    "branch.name",
  ]);

  const FCLPreloadApi = useApi(FCLBK.FCLPreload);
  const createFCLApi = useApi(FCLBK.createFCL);
  const getByColumn = useApi(FCLBK.getByColumn);

  const formDataGenerator = useFormData();

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "recivedFcl";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const editResponse = (row) => {
    row.createdOn = row.createdOn?.split("T")[0];
    row.recieveDate = row.recieveDate?.split("T")[0];
    row.statusDate = row.statusDate?.split("T")[0];
    // Replace the normal status with the text from env variable
    const thisStatusIndex = env.followUpStatus.findIndex(
      (status) => status.key === row.followUpStatus
    );

    row.followUpStatusText = env.followUpStatus[thisStatusIndex]?.[culture];

    row.orderNumber = `${row.country?.countryCode}-${row.orderNumber}FC`;

    return row;
  };

  // search popup
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  const errorMessage =
    FCLPreloadApi.errorAlert ||
    createFCLApi.errorAlert ||
    getByColumn.errorAlert ||
    "";

  const updateData = (arr) => {
    setData((data) => ({
      ...data,
      data: arr,
    }));
  };

  const preload = async () => {
    const res = await FCLPreloadApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.data.forEach((row) => editResponse(row));
      setData(res.data.data);
    }
  };

  const handleCreate = async (formData) => {
    setSpinner(true);
    const schema = formDataGenerator({ ...formData, image });

    const res = await createFCLApi.request(schema);
    if (res.status === 200) {
      editResponse(res.data.data);
      setData((d) => ({
        ...d,
        data: [res.data.data, ...d.data],
      }));
      sweetAlert(commenContent.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  // usePrevRequest()

  usePrevRequest(
    preload,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck
  );

  return {
    data,
    setData,
    pageName,
    pageNo,
    image,
    headers,
    alert,
    sweetAlert,
    setImage,
    columns,
    spinner,
    setPageNo,
    updateData,
    setHeaders,
    setColumns,
    errorMessage,
    docsPerPage,
    searchPopup,
    handleCreate,
    createFCLApi,
    editResponse,
    FCLPreloadApi,
    setDocsPerPage,
  };
};

export default useFCL;
