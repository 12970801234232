import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import { useForm, Controller } from "react-hook-form";
import Spinner from "../../components/Spinner";
import useApi from "../../hooks/useApi";
import env from "env";
import calculator from "api/calculator";
import useAlert from "hooks/useAlert";
import { useLocation, useParams } from "react-router";
import TextPopup from "components/common/TextModal";
import CountryTable from "components/common/CountryTable";
import useAbility from "hooks/useAbility";
import content from "./content";
import commenContent from "components/common/content";
import EditPopup from "./components/EditPopup";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const Caclulator = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const params = useParams();
  const location = useLocation();

  const checkAbility = useAbility(
    `${params.shipmentType.toLowerCase() === "air" ? "Air" : "LCL"}Price`
  );
  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // states
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const [editSchema, setEditSchema] = useState({});

  // apis
  const getAllCalculators = useApi(calculator.getClaculators);
  const createCalculator = useApi(calculator.createCalculator);
  const deleteCalculator = useApi(calculator.deleteCalculator);
  const updateCalculator = useApi(calculator.updateCalculator);

  // form validation
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.country[culture],
    content.shippingType[culture],
    content.priceRange[culture],
    content.priceMedicalRange[culture],
    commenContent.price[culture],
    commenContent.priceType[culture],
  ]);

  const [columns, setColumns] = useState([
    "country.name",
    "shippingTypeText",
    "priceRangeText",
    "priceRangeMedicalText",
    "priceValue",
    "priceTypeName",
  ]);

  const preload = async () => {
    const res = await getAllCalculators.request(params.shipmentType);
    if (res.status === 200) {
      res.data.data = res.data.data.map((calc) => editResponse(calc));
      setData(res.data.data);
    }
  };

  const createCalc = async (formData) => {
    setSpinner(true);
    const schema = {
      ...formData,
      priceValue: +formData.priceValue,
    };

    delete schema.country;

    if (formData.shippingType === 2) {
      delete schema.priceRange;
    } else {
      delete schema.priceRangeMedical;
    }

    const res = await createCalculator.request(params.shipmentType, schema);

    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      const newData = [res.data.data, ...data];

      setData(newData);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  // This method create new instance with enum string value
  const editResponse = (obj) => {
    obj.priceTypeName = env.priceType[obj.priceType - 1];
    obj.priceName = env.price[obj.price - 1];
    obj.unit = obj.shipmentType === 1 ? "kg" : "cbm";
    obj.shippingTypeText = env.shippingType[obj.shippingType - 1];
    obj.priceRangeText = env.priceRange[obj.priceRange - 1];
    obj.priceRangeMedicalText =
      env.priceRangeMedical[obj.priceRangeMedical - 1];
    return obj;
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const handleEdit = (obj) => {
    setEditSchema({ ...obj });
    setEditModal(true);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
    setCountryTable(false);
    setEditSchema({});
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCalculator.request(params.shipmentType, rowId);

    if (res.status === 200) {
      sweetAlert(commenContent.done[culture]);
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
    }
    setRowId("");
    setSpinner(false);
  };

  const editCalculator = async () => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateCalculator.request(
      params.shipmentType,
      editSchema.id,
      {
        priceValue: editSchema.priceValue,
      }
    );

    if (res.status === 200) {
      
      sweetAlert(commenContent.done[culture]);
      // const newData = data.filter((item) => item.id !== rowId);
      editResponse(res.data.data);
      
      setData(oldData => {
        const newData = [...oldData];
        const index = newData.findIndex(item => item.id === editSchema.id);
        newData[index].priceValue = editSchema.priceValue;
        return newData;
      });
    }
    setRowId("");
    setSpinner(false);
  };

  const deleteRenderer = () => checkAbility("delete");

  useEffect(() => {
    reset();
    preload();
  }, [location]);

  // No field should be rendered in lcl after selecting shipping type
  const shippingType = params.shipmentType == "air" ? watch("shippingType") : 0;

  return (
    <div>
      {alert}
      {getAllCalculators.errorAlert}
      {createCalculator.errorAlert}
      {deleteCalculator.errorAlert}
      {spinner ? <Spinner gate="#29bb64" bg="#fff" opacity /> : null}
      {getAllCalculators.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.calculator[culture]]} />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.calculator[culture]}{" "}
                    {culture === "ar" ? "الجوي" : params.shipmentType}
                  </h3>
                </div>
              </CardHeader>
              {checkAbility("create") && (
                <CardBody>
                  <Form onSubmit={handleSubmit(createCalc)}>
                    <Row>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="shipping-type"
                          >
                            {content.shippingType[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="shippingType"
                            rules={{
                              required: "shipping Type field is required",
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="shipping-type"
                                type="select"
                                className={errors.shippingType && "error"}
                                defaultValue={true}
                                onChange={({ target: { value } }) => {
                                  // setPriceValue(value)
                                  onChange(Number(value));
                                }}
                              >
                                <option value={true} disabled>
                                  {`-- ${commenContent.selectOption[culture]} --`}
                                </option>
                                {env.shippingType.map((item, i) =>
                                  // shipping type 'machine CNC' shouldn't appear in 'Air' calculator drop list
                                  params.shipmentType === "air" && i === 2 ? (
                                    <React.Fragment key={i}></React.Fragment>
                                  ) : (
                                    <option value={i + 1} key={i}>
                                      {item}
                                    </option>
                                  )
                                )}
                              </Input>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {shippingType == 2 && (
                        <Col md="6" lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="price-medical"
                            >
                              {content.priceMedicalRange[culture]}
                            </label>
                            <Controller
                              control={control}
                              name="priceRangeMedical"
                              rules={{
                                required:
                                  "Price range medical field is required",
                              }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id="price-medical"
                                  type="select"
                                  className={
                                    errors.priceRangeMedical && "error"
                                  }
                                  defaultValue={true}
                                  onChange={({ target: { value } }) => {
                                    onChange(Number(value));
                                  }}
                                >
                                  <option value={true} disabled>
                                    {`-- ${commenContent.selectOption[culture]} --`}
                                  </option>
                                  {env.priceRangeMedical.map((item, i) => (
                                    <option value={i + 1} key={i}>
                                      {item}
                                    </option>
                                  ))}
                                </Input>
                              )}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {shippingType > 0 && shippingType !== 2 && (
                        <Col md="6" lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="priceRange"
                            >
                              {content.priceRange[culture]}
                            </label>
                            <Controller
                              control={control}
                              name="priceRange"
                              rules={{
                                required: "Price Range field is required",
                              }}
                              render={({
                                field: { ref, onChange, value, ...field },
                              }) => (
                                <Input
                                  {...field}
                                  id="priceRange"
                                  type="select"
                                  className={errors.priceRange && "error"}
                                  defaultValue={true}
                                  onChange={({ target: { value } }) => {
                                    onChange(Number(value));
                                  }}
                                >
                                  <option value={true} disabled>
                                    {`-- ${commenContent.selectOption[culture]} --`}
                                  </option>
                                  {env.priceRange.map((item, i) => (
                                    <option value={i + 1} key={i}>
                                      {item}
                                    </option>
                                  ))}
                                </Input>
                              )}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="price-type"
                          >
                            {commenContent.priceType[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="priceType"
                            rules={{ required: "Name field is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="price-type"
                                placeholder={commenContent.name[culture]}
                                type="select"
                                className={errors.priceType && "error"}
                                defaultValue={true}
                                onChange={({ target: { value } }) =>
                                  onChange(Number(value))
                                }
                              >
                                <option value={true} disabled>
                                  {`-- ${commenContent.selectOption[culture]} --`}
                                </option>
                                {env.priceType.map((item, i) => (
                                  <option value={i + 1} key={i}>
                                    {item}
                                  </option>
                                ))}
                              </Input>
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5" lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="price-value"
                          >
                            {content.priceValue[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="priceValue"
                            rules={{
                              required: "Price value field is required",
                              pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              },
                            }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="price-value"
                                placeholder={commenContent.price[culture]}
                                type="number"
                                className={errors.priceValue && "error"}
                                value={value || ""}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="1" className="align-self-center mt-1">
                        <Input
                          type="text"
                          disabled
                          value={params.shipmentType == "air" ? "kg" : "cbm"}
                          onChange={() => {}}
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="country"
                          >
                            {commenContent.country[culture]}
                          </label>
                          <Controller
                            control={control}
                            name="country"
                            rules={{ required: "Country feild is required" }}
                            render={({
                              field: { ref, onChange, value, ...field },
                            }) => (
                              <Input
                                {...field}
                                id="country"
                                type="text"
                                className={errors.country && "error"}
                                value={value || ""}
                                placeholder={`-- ${commenContent.selectOption[culture]} --`}
                                onChange={({ target: { value } }) =>
                                  onChange(value)
                                }
                                onClick={() => setCountryTable(true)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="success" type="submit">
                      <span className="btn-inner--icon me-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{commenContent.create[culture]}</span>
                    </Button>
                  </Form>
                </CardBody>
              )}
            </Card>
            <Card>
              <Table
                pageName={`calculater-${params.shipmentType}`}
                headers={headers}
                columns={columns}
                setHeaders={setHeaders}
                setColumns={setColumns}
                data={data}
                setData={setData}
                handleDelete={handleDelete}
                deleteRenderer={deleteRenderer}
                handleEdit={handleEdit}
              >
                <span fun="handleEdit" className="me-1">
                  <div id="edit1" className="table-action cursor-pointer">
                    <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                  </div>
                  <UncontrolledTooltip delay={0} target="edit1">
                    {commenContent.edit[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  className="me-1"
                  fun="handleDelete"
                  condition="deleteRenderer"
                >
                  <div id="delete" className="table-action cursor-pointer">
                    <i className="fas fa-trash hover-danger fa-lg" />
                  </div>
                  <UncontrolledTooltip delay={0} target="delete">
                    {commenContent.delete[culture]}
                  </UncontrolledTooltip>
                </span>
              </Table>
            </Card>
            <TextPopup
              modal={deleteModal}
              text={commenContent.yes[culture]}
              handleCancel={handleCancel}
              fn={deleteRow}
              color="danger"
            >
              <Container>
                <h2>{commenContent.deleteRowPremission[culture]}</h2>
              </Container>
            </TextPopup>
            <TextPopup
              modal={editModal}
              text={commenContent.yes[culture]}
              handleCancel={handleCancel}
              fn={editCalculator}
              color="info"
              name={commenContent.edit[culture]}
            >
              <Container>
                <EditPopup
                  editSchema={editSchema}
                  setEditSchema={setEditSchema}
                />
              </Container>
            </TextPopup>
            <TextPopup
              modal={countryTable}
              text={commenContent.next[culture]}
              handleCancel={handleCancel}
              fn={() => {}}
              color="primary"
              noBtn
            >
              <Container>
                <CountryTable
                  setCountryTable={setCountryTable}
                  setValue={setValue}
                  sweetAlert={sweetAlert}
                />
              </Container>
            </TextPopup>
          </Container>
        </>
      )}
    </div>
  );
};

export default Caclulator;
