import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Container,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import { useForm } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import Pagination from "components/common/Pagination";
import PopupTable from "components/common/PopupTableUsed";
import useAlert from "hooks/useAlert";
import { PaginationContext } from "context/PaginationContext";
import usePagination from "hooks/usePagination";
import NavPills from "components/common/NavPills";
import TicketApi from "../../../api/TicketApi";
import content from "../content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";

const AllTickets = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.subject[culture],
    commenContent.customer[culture],
    commenContent.customerCode[culture],
    commenContent.user[culture],
    commenContent.description[culture],
  ]);

  const [columns, setColumns] = useState([
    "subject",
    "customer.customerName",
    "customer.customerCode",
    "currentHolder.fullName",
    "description",
  ]);

  const [data, setData] = useState([]);
  const [navPills, setNavPills] = useState(0);
  const [tableModal, setTableModal] = useState(false);

  const ticketsApi = useApi(TicketApi.getTickets);
  const getByColumn = useApi(TicketApi.getByColumn);

  const paginationCtx = useContext(PaginationContext);

  const editResponse = (item) => item;

  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "ticket";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } =
    usePagination(pageName);

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const handleCancel = () => {
    setTableModal(false);
  };

  const links = [
    content.all[culture],
    content.opened[culture],
    content.resolved[culture],
    content.closed[culture],
  ];

  const getTickets = async () => {
    const reqLinks = ["/Detailed", "/opened", "/resolved", "/closed"];

    const res = await ticketsApi.request(
      pageNo,
      docsPerPage,
      reqLinks[navPills]
    );

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  useEffect(() => {
    getTickets();
  }, [pageNo, docsPerPage, navPills]);

  if (ticketsApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      {ticketsApi.errorAlert}
      {getByColumn.errorAlert}
      <SimpleHeader parents={[content.customerServices[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.allTicket[culture]}</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              links={links}
              navPills={navPills}
              setNavPills={setNavPills}
            />
          </CardBody>
          <Pagination
            dataLength={data.length}
            // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName={"my-ticket"}
              headers={headers}
              columns={columns}
              setColumns={setColumns}
              setHeaders={setHeaders}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
            >
              <span to={`/${culture}-admin/ticket`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commenContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={tableModal}
        text={commenContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(obj) => setValue("customerId", obj.id)}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={setData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          extraField={{
            header: content.subject[culture],
            column: "subject",
          }}
          extraFilterField={navPills}
        />
      )}
    </>
  );
};

export default AllTickets;
