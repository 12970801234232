import React from "react";

// reactstrap components
import { Card, CardBody, Container, CardHeader } from "reactstrap";

// Gallery
import PhotoAlbum from "react-photo-album";

import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const conent = {
  noImgs: {
    ar: "لا توجد صور",
    en: "No images found",
  },
};

function Gallery({ imgs = [], title }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const photos = imgs.map((img) => ({
    src: img,
    width: 1,
    height: 1,
  }));

  return (
    <>
      <Container fluid>
        <Card className="mb-4">
          {title && (
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>{title}</h3>
              </div>
            </CardHeader>
          )}
          <CardBody className="padd gallery">
            {photos.length ? (
              <PhotoAlbum layout="rows" photos={photos} />
            ) : (
              <h4 className="text-center">{conent.noImgs[culture]}</h4>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Gallery;
