import React, { useEffect, useState, Fragment } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from "hooks/useApi";
import useAbility from "hooks/useAbility";
import { useParams } from "react-router-dom";
import shipment from "api/shipment";
import Spinner from "../../components/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import env from "env";
import Gallery from "components/common/Gallery";
import DocsCard from "components/common/DocsCard";
import useAlert from "hooks/useAlert";
import FilePicker from "components/common/FilePicker";
import Tables from "components/common/CustomTable";
import SpTable from "components/common/SpTable";
// get culture from utils
import getCulture from "utils/getCulture";
// router
import { useRouteMatch } from "react-router-dom";
import commenContent from "components/common/content";
import content from "Screen/Airshipment/content";

function AirshipmentDetail() {
  // params
  const { id } = useParams();
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  const checkAbility = useAbility("AirCargo");

  // apis
  const getAircargo = useApi(shipment.getAirCargo);
  const uploadImg = useApi(shipment.uploadImg);

  // states
  const [data, setData] = useState({});
  const [imgs, setImgs] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const thisStatusIndex = env.followUpStatus.findIndex(
    (status) => status.key === data.followUpStatus
  );

  const isFile = (file) => {
    const extension = file.substr(file.lastIndexOf(".") - file.length + 1);

    if (extension === "pdf") return true;
    if (extension === "docx" || extension === "dot") return true;
    if (extension === "pptx" || extension === "ppt" || extension === "ppsx")
      return true;
    if (
      extension === "xlsx" ||
      extension === "xlsm" ||
      extension === "xls" ||
      extension === "xlsb"
    )
      return true;
    return false;
  };

  const files = [...(data?.images || "")].filter((item) => isFile(item));
  const imges = [...(data?.images || "")].filter((img) => !isFile(img));
  const swiftFiles = [...(data.swift?.split(",") || "")].filter((item) =>
    isFile(item)
  );
  const swiftImges = [...(data.swift?.split(",") || "")].filter(
    (img) => !isFile(img)
  );

  const getShipment = async () => {
    const res = await getAircargo.request(id);

    if (res.status === 200) {
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });
      res.data.data.orderNumber = `${res.data.data.country?.countryCode}-${res.data.data.orderNumber}AC`;
      setData(res.data.data);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const res = await uploadImg.request(id, imgs);
    const newData = { ...data };
    newData.imges = res.data.data.imges;
    setData(newData);

    if (res.status === 200) {
      sweetAlert("Done");
      setImgs({});
    }

    setSpinner(false);
  };

  useEffect(() => {
    getShipment();
  }, []);

  return (
    <div>
      {alert}
      {getAircargo.errorAlert}
      {uploadImg.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getAircargo.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.airShipment[culture]]} backBtn />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.airShipment[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <form>
                  <Row>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {commenContent.orderNumber[culture]}
                        </label>
                        <Input
                          id="example3cols1Input"
                          type="text"
                          disabled
                          value={data.orderNumber || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="client-name"
                        >
                          {commenContent.customerName[culture]}
                        </label>
                        <Input
                          id="client-name"
                          placeholder={commenContent.customerName[culture]}
                          type="text"
                          value={data.customer?.customerName || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          {commenContent.customerCode[culture]}
                        </label>
                        <Input
                          id="client"
                          placeholder={commenContent.orderNumber[culture]}
                          type="text"
                          value={data.customer?.customerCode || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="weight">
                          {commenContent.weight[culture]}
                        </label>
                        <Input
                          id="weight"
                          placeholder={commenContent.weight[culture]}
                          type="text"
                          value={data.weight}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="noOfPkgs"
                        >
                          {commenContent.numOfPackages[culture]}
                        </label>
                        <Input
                          id="noOfPkgs"
                          placeholder={commenContent.numOfPackages[culture]}
                          type="text"
                          value={data.numberOfPkgs}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="country">
                          {commenContent.country[culture]}
                        </label>
                        <Input
                          id="country"
                          type="text"
                          value={data.country?.name || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="followUpStatus"
                        >
                          {commenContent.followUpStatus[culture]}
                        </label>
                        <Input
                          id="followUpStatus"
                          type="text"
                          value={
                            env.followUpStatus[thisStatusIndex]?.[culture] || ""
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    {data.followUpStatus === 13 && (
                      <Fragment>
                        <Col md="6" lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="client"
                            >
                              {content.remainigNoOfPackage[culture]}
                            </label>
                            <Input
                              id="client"
                              type="text"
                              disabled
                              value={data?.restNoOfPkgs || ""}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="client"
                            >
                              {content.remainingWeight[culture]}
                            </label>
                            <Input
                              id="client"
                              type="text"
                              disabled
                              value={data?.restWeights || ""}
                            />
                          </FormGroup>
                        </Col>
                      </Fragment>
                    )}
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commidityType"
                        >
                          {commenContent.commodityType[culture]}
                        </label>
                        <Input
                          id="commidityType"
                          placeholder="Follow up status"
                          type="select"
                          disabled
                          value={data?.commodityType || ""}
                        >
                          <option disabled value="">
                            {" "}
                            -- {commenContent.noOptionSelected[culture]} --{" "}
                          </option>
                          {env.commidityType.map((status, i) => (
                            <option key={i} value={i + 1}>
                              {status}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commodity"
                        >
                          {commenContent.commodity[culture]}
                        </label>
                        <Input
                          id="commodity"
                          type="textarea"
                          disabled
                          value={data?.commodity || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* {env.followUpStatus[thisStatusIndex]?.key < 6 && 
                  <Button 
                    color="success" 
                    type="submit"
                    className='mt-4'
                    onClick={() => {}}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-check" />
                    </span>
                    <span>
                      {nextStatus?.text || ""}
                    </span>
                  </Button>
                } */}
                </form>
              </CardBody>
            </Card>
            {checkAbility("edit") && (
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between">
                    <h3 className={`mb-0 text-md-left`}>Upload images</h3>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <FilePicker images={imgs} setImages={setImgs} />
                    <Button
                      color="success"
                      type="submit"
                      className="mt-4"
                      disabled={imgs.length === 0}
                      onClick={handleUpload}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-long-arrow-alt-up"></i>
                      </span>
                      <span>Update</span>
                    </Button>
                  </form>
                </CardBody>
              </Card>
            )}
            <SpTable api={shipment} />
            {swiftFiles.length ? (
              <DocsCard title="Swift attachments" files={swiftFiles} />
            ) : (
              ""
            )}
          </Container>
          <Gallery title="Swift image" imgs={swiftImges || []} />
          <Container>
            {files.length ? <DocsCard files={files} /> : ""}
          </Container>

          <Gallery imgs={imges} />
        </>
      )}
    </div>
  );
}

export default AirshipmentDetail;
