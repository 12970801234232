import client from "api/client";

const endpoint = "/v1";

const getTickets = (page, docsPerPage, type) =>
  client.get(
    `${endpoint}/Ticket${type}?Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getOldTickets = (page, docsPerPage, body) =>
  client.put(
    `${endpoint}/Ticket/Filter?Page=${page}&PostsPerPage=${docsPerPage}`,
    body
  );

const createTicket = (schema) => client.post(`${endpoint}/Ticket/`, schema);

const findById = (id) => client.get(`${endpoint}/Ticket/${id}`);

const updateState = (id, state) =>
  client.put(`${endpoint}/Ticket/${state}/${id}`);

  const updateTicket = (id, subject, description) =>
  client.put(
    `${endpoint}/Ticket?Id=${id}&Subject=${subject}&Description=${description}`
  );

  const deleteTicket = (id) =>
  client.delete(`${endpoint}/Ticket/${id}`);

const createComment = (type, schema) =>
  client.post(`${endpoint}/Comment/${type}`, schema);

const assignHolder = (ticketId, userId) =>
  client.post(
    `${endpoint}/Ticket/Assignholder?TicketId=${ticketId}&UserId=${userId}`
  );

const getByColumn = (col, val, page, docsPerPage, state) =>
  client.get(
    `${endpoint}/ticket/GetByCoulum?col=${col}&value=${val}&ticketState=${state}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getMyTicketsByCoulum = (col, val, page, docsPerPage, state) =>
  client.get(
    `${endpoint}/ticket/GetMyTicketsByCoulum?col=${col}&value=${val}&ticketState=${state}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

export default {
  getTickets,
  findById,
  getOldTickets,
  createTicket,
  updateState,
  updateTicket,
  deleteTicket,
  createComment,
  assignHolder,
  getByColumn,
  getMyTicketsByCoulum,
};
