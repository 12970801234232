import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";

import content from "./content";

function EditNews({ editSchema, setEditSchema, culture }) {
  const handleChange = (key, val) => {
    setEditSchema({
      ...editSchema,
      [key]: val,
    });
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="title-edit">
              {content.title[culture]}
            </label>
            <Input
              id="title-edit"
              type="text"
              value={editSchema.title || ""}
              onChange={(e) => handleChange("title", e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="description-edit">
              {content.description[culture]}
            </label>
            <Input
              id="description-edit"
              type="textarea"
              value={editSchema.descraption || ""}
              onChange={(e) => handleChange("descraption", e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default EditNews;
