export default {
  user: {
    ar: "المستخدم",
    en: "User",
  },
  users: {
    ar: "المستخدمين",
    en: "Users",
  },
  cleint: {
    ar: "العميل",
    en: "Client",
  },
  selectUser: {
    ar: "اختر المستخدم",
    en: "Select User",
  },
  approveUsers: {
    ar: "قبول المستخدمين",
    en: "Approve Users",
  },
  approveUser: {
    ar: "قبول المستخدم",
    en: "Approve User",
  },
  approveUserPopUp: {
    ar: "هل انت واثق من قبول هذا المستخدم ؟",
    en: "Are you sure you want to Approve this user ?",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer Name",
  },
  userName: {
    ar: "اسم المستخدم",
    en: "User Name",
  },
  generatePassword: {
    ar: "وضع كلمة مرور",
    en: "Generate Password",
  },
  operationCountry: {
    ar: "عمليات على البلدان",
    en: "Operation country",
  },
  operationBranch: {
    ar: "عمليات على الفروع",
    en: "Operation branch",
  },
  selectCountry: {
    ar: "اختر بلد",
    en: "Select Country",
  },
  selectBranch: {
    ar: "اختر فرع",
    en: "Select branch",
  },
  selected: {
    ar: "تم اختياره",
    en: "Selected",
  },
  screen: {
    ar: "الواجهة",
    en: "Screen",
  },
  actions: {
    ar: "العمليات",
    en: "actions",
  },
  ability: {
    ar: "القدرات",
    en: "Ability",
  },
  changedTo: {
    ar: "تم تغيير الرمز الى :",
    en: "Password Changed To :",
  },
  delete: {
    ar: "حذف",
    en: "Delete",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المستخدم ؟",
    en: "Are you sure you want to delete this user ?",
  },
  yes: {
    ar: "نعم",
    en: "Yes",
  },
  operationBranch: {
    ar: "عمليات على الفروع",
    en: "Operation branch",
  },
  selectCountry: {
    ar: "اختر بلد",
    en: "Select Country",
  },
  selectBranch: {
    ar: "اختر فرع",
    en: "Select branch",
  },
  selected: {
    ar: "تم اختياره",
    en: "Selected",
  },
};
