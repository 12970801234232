import client from "./accountingClient";

const endpoint = "/v1/Account";

const getAccounts = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const filterAccounts = (pageNo, docsPerPage, schema) =>
  client.put(
    `${endpoint}/Filter?Page=${pageNo}&PostsPerPage=${docsPerPage}`,
    schema
  );

const accountsPreload = (pageNo, docsPerPage) =>
  client.get(`${endpoint}/Preload?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const getRoots = () => client.get(`${endpoint}/GetRoots`);

const getChildren = (id) => client.get(`${endpoint}/getchildren/${id}`);

const createAccount = (schema) => client.post(`${endpoint}`, schema);

const updateAccount = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const deleteAccount = (id) => client.delete(`${endpoint}/${id}`);

const getByColumn = (col, val, page, docsPerPage) =>
  client.put(`${endpoint}/filter?Page=${page}&PostsPerPage=${docsPerPage}`, {
    [col]: val,
  });

const customerSumReport = (id) =>
  client.get(`${endpoint}/CustomersSumReport/${id}`);

const getDetails = (id) => client.get(`${endpoint}/Details/${id}`);

const filterWithoutIdEntry = (schema, id, page, docsPerPage) =>
  client.put(
    `${endpoint}/FilterWithoutId?id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`,
    schema
  );

const transaction = (id, date, uptoDate = "") =>
  client.get(
    `${endpoint}/AccountTransactionsReport?AccountId=${id}&date=${date}&upto=${uptoDate}`
  );

const filter = (schema = {}, page, docsPerPage) =>
  client.put(
    `${endpoint}/filter?Page=${page}&PostsPerPage=${docsPerPage}`,
    schema
  );

const balanceWithMainTreasury = (id) =>
  client.get(`${endpoint}/BalanceWithMainTreasury?customerId=${id}`);

const balanceWithRevenue = (id) =>
  client.get(`${endpoint}/BalanceWithRevenue?customerId=${id}`);

const balanceWithAnyTreasury = (custoemerId, treasuryId) =>
  client.get(
    `${endpoint}/BalanceWithAnyTreasury?customerId=${custoemerId}&treasuryId=${treasuryId}`
  );

const balanceWithTransfersAccount = (custoemerId, treasuryId) =>
  client.get(
    `${endpoint}/balanceWithTransfersAccount?customerId=${custoemerId}&treasuryId=${treasuryId}`
  );

const getTreasury = (schema, id, page, docsPerPage) =>
  client.put(
    `${endpoint}/getTreasury?id=${id}&Page=${page}&PostsPerPage=${docsPerPage}`,
    schema
  );

export default {
  getRoots,
  filterAccounts,
  getChildren,
  createAccount,
  updateAccount,
  deleteAccount,
  getAccounts,
  getByColumn,
  accountsPreload,
  customerSumReport,
  getDetails,
  filterWithoutIdEntry,
  transaction,
  filter,
  balanceWithMainTreasury,
  balanceWithRevenue,
  balanceWithAnyTreasury,
  balanceWithTransfersAccount,
  getTreasury
};
