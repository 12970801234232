
const usePackageFilter = (setData, shipmentType, fn) => {
  const filter = async (api, bulkId, search) => {
    if (!search && bulkId) return fn();
    const res = await api.request(shipmentType, bulkId, search);
    if (res.status === 200) setData(res.data.data);
  }

  return filter;
}

export default usePackageFilter