import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import branch from "api/branch";
import usePagination from "hooks/usePagination";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import useSearchPopup from "hooks/useSearchPopup";
import SearchModal from "components/common/SearchModal";
import EditBranch from "./components/EditBranch";
import useAbility from "hooks/useAbility";
import commonContent from "components/common/content";
import content from "./content";

const Branch = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const headers = [
    commonContent.actions[culture],
    commonContent.name[culture],
    commonContent.code[culture],
    content.areaName[culture]
  ];
  const columns = ["name", "code", "areaName"];

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const checkAbility = useAbility("Branch");
  const { alert, sweetAlert } = useAlert();

  // use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const {
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage } = usePagination("");

  const branchesApi = useApi(branch.branches);
  const createBranchApi = useApi(branch.createBranch);
  const getByColumn = useApi(branch.getByColumn);
  const deleteBranchApi = useApi(branch.deleteBranch);
  const updateBranchApi = useApi(branch.updateBranch);

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    (item) => item
  );

  const createBranch = async (formData) => {
    setSpinner(true);
    const res = await createBranchApi.request(formData);
    if (res.status === 200) {
      setData((d) => [res.data.data, ...d]);
      sweetAlert("Done");
      reset();
    }
    setSpinner(false);
  };

  const getBranches = async () => {
    const res = await branchesApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    setData(arr);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteRow = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteBranchApi.request(rowId);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("name", obj.name);
    editSetValue("code", obj.code);
    editSetValue("areaName", obj.areaName);
    setEditModal(true);
  };

  const editBranchHandler = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateBranchApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data];
      const rowIndex = newData.findIndex((item) => item.id === rowId);
      newData[rowIndex] = res.data.data;
      setData(newData);
      sweetAlert("Done");
    }
    setRowId("");
    setSpinner(false);
  };

  const editRenderer = () => checkAbility("edit");

  const deleteRenderer = () => checkAbility("delete");

  useEffect(() => {
    getBranches();
  }, [pageNo, docsPerPage]);

  if (branchesApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {branchesApi.errorAlert}
      {createBranchApi.errorAlert}
      {deleteBranchApi.errorAlert}
      {updateBranchApi.errorAlert}
      {getByColumn.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      <SimpleHeader parents={[commonContent.branch[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{commonContent.branch[culture]}</h3>
            </div>
          </CardHeader>
          {checkAbility("create") && (
            <CardBody>
              <form onSubmit={handleSubmit(createBranch)}>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {commonContent.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: "name field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            placeholder={commonContent.name[culture]}
                            type="text"
                            className={errors.name && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="code">
                        {commonContent.code[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="code"
                        rules={{
                          required: "code field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "only english numbers allowed",
                          },
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="code"
                            placeholder={commonContent.code[culture]}
                            type="text"
                            className={errors.code && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="areaName">
                        {content.areaName[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="areaName"
                        rules={{
                          required: "code field is required",
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="areaName"
                            placeholder={content.areaName[culture]}
                            type="text"
                            className={errors.areaName && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="success" type="submit">
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{commonContent.create[culture]}</span>
                </Button>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              pageName="branches"
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              editRenderer={editRenderer}
              deleteRenderer={deleteRenderer}
              onHeaderClick={searchPopup.handleHeaderClicked}
            >
              <span fun="handleEdit" className="me-1" condition="editRenderer">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="me-1"
                fun="handleDelete"
                condition="deleteRenderer"
              >
                <div id="delete" className="table-action cursor-pointer">
                  <i className="fas fa-trash hover-danger fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commonContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editBranchHandler)}
        color="primary"
      >
        <Container>
          <EditBranch control={editControl} errors={editErrors} />
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.yes[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={(item) => item}
          // popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: "name", column: "name" }}
        />
      )}
    </div>
  );
};

export default Branch;
