import React from "react";
import { Controller } from "react-hook-form";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import commonContent from "components/common/content";

const EditCountry = ({ control, errors }) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  return (
    <Row>
      <Col md="6" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            {commonContent.name[culture]}
          </label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "name field is required",
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="name"
                placeholder={commonContent.name[culture]}
                type="text"
                className={errors.name && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default EditCountry;
