const abilityReducer = (state, action) => {
  if (action.type === "ADD_ABILITY") {
    // Check if the value already exist
    // if so remove it

    const isExist = state.userAbility.findIndex(
      (item) =>
        item.claimName === action.screen && item.claimValue === action.value
    );

    if (isExist > -1) {
      const newState = { ...state };
      newState.userAbility.splice(isExist, 1);
      return newState;
    }

    return {
      ...state,
      userAbility: [
        ...state.userAbility,
        {
          claimName: action.screen,
          claimValue: action.value,
        },
      ],
    };
  }

  if (action.type === "CHECK_ALL_ROW") {
    const roles = [...action.roles];

    state.userAbility.forEach((user) => {
      if (user.claimName === action.screen) {

        const index = roles.findIndex((role) => role.role === user.claimValue);

        if (index > -1)
          roles.splice(index, 1);
      }
    });

    const newState = { ...state };
    roles.forEach((role) => {
      newState.userAbility.push({
        claimName: action.screen,
        claimValue: role.role,
      });
    });

    return newState;
  }

  
  if (action.type === "UNCHECK_ALL_ROW") {
    const userAbility = [...state.userAbility];
    for(let i = userAbility.length - 1; i >= 0; i--) {
      if (userAbility[i].claimName === action.screen) {
        userAbility.splice(i, 1);
      }
    }

    return {
      ...state,
      userAbility
    }
  }

  if (action.type === "PRELOAD_ABILITIES") {
    const userAbilities = action.abilities.map((item) => ({
      claimName: item.type,
      claimValue: item.value,
    }));

    return {
      initialAbility: [...userAbilities],
      userAbility: [...userAbilities],
    };
  }
  return state;
};

export default abilityReducer;
