export default {
  //     "lclId": 0,
  //   "orderId": "string",
  //   "client": 0,
  //   "commodity": "string",
  //   "volume": 0,
  //   "noOfPkgs": 0,
  //   "followUpStatus": 0,
  //   "date": "2022-04-17T11:23:47.391Z",
  //   "recieveDate": "2022-04-17T11:23:47.391Z",
  //   "exportDate": "2022-04-17T11:23:47.391Z",
  //   "arrivalDate": "2022-04-17T11:23:47.391Z",
  //   "arrangedDate": "2022-04-17T11:23:47.391Z",
  //   "country": 0

  lcl: {
    ar: "LCL",
    en: "LCL",
  },
  lclId: {
    en: "ID",
  },
  orderId: {
    en: "order ID",
  },
  client: {
    en: "Client",
  },
  volume: {
    en: "Volume",
  },
  noOfPkgs: {
    en: "Packages No.",
  },
  commodity: {
    en: "Commodity",
  },
  followUpStatus: {
    en: "Follow up status",
  },
  date: {
    en: "Date",
    ar: "التاريخ",
  },
  exportDate: {
    en: "Export date",
  },
  recieveDate: {
    en: "Recieve date",
    ar: "تاريخ الاستلام",
  },
  arrangedDate: {
    en: "Arranged date",
  },
  dateArrival: {
    en: "Arrival date",
  },
  sealNum: {
    en: "Seal No.",
  },
  contNum: {
    en: "Cont No.",
  },
  country: {
    en: "Country",
    ar: "البلد",
  },
  orderBulk: {
    ar: "رقم التجميعة",
    en: "Order bulk",
  },
  stateDate: {
    ar: "تاريخ الحالة",
    en: "State date",
  },
  uploadImg: {
    ar: "رفع صورة",
    en: "Upload image",
  },
  exportDate: {
    ar: "تاريخ التصدير",
    en: "Export Date",
  },
};
