import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import env from "env";
import bulks from "api/bulks";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner";
import Table from "components/common/CustomTable";
import CreateBulkTable from "../components/CreateBulkTable";
import Pagination from "components/common/Pagination";
import { Item } from "react-floating-button";

const CustomerPopup = ({
  setClients,
  clients = [],
  shipmentType,
  id,
  create = false,
  sweetAlert,
}) => {
  // Apis
  const getOrdersOnBulkApi = useApi(bulks.getOrdersOnBulk);
  // const getClients = useApi(bulks.getClients);
  const findBulkByCode = useApi(bulks.findBulkByCode);

  // States
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [clientCode, setClientCode] = useState("");

  const headers = [
    "actions",
    "Name",
    "Code",
    "Order Number",
    "No pkg",
    "No of actual pkg",
  ];
  const columns = [
    "customer.customerName",
    "customer.customerCode",
    "orderNumber",
    "numberOfPkgs",
    "actualNoPackg",
  ];

  if (shipmentType === "aircargo") {
    headers.push("weight");
    headers.push("Actuall weight");
    columns.push("weight");
    columns.push("actualWeight");
  }

  if (shipmentType === "lcl") {
    headers.push("volume");
    headers.push("Actual volume");
    columns.push("volume");
    columns.push("actualVolume");
  }

  const getAllCustomers = async () => {
    const response = await getOrdersOnBulkApi.request(
      shipmentType,
      id,
      pageNo,
      docsPerPage
    );
    if (response.ok) {

      setData(
        response.data.data[0][
          shipmentType === "aircargo" ? "airCargos" : "lclCargos"
        ]
      );
    }
  };

  // const getAllClients = async () => {
  //   const response = await getClients.request(shipmentType, id, pageNo, docsPerPage);
  //   console.log({response});

  //   if (response.ok) {
  //     response.data.data.forEach(item => {
  //       // item.id = item.orderNumber
  //       item.actualNoPackg = 0;
  //       if (shipmentType === 'aircargo') {
  //         item.actualWeight = 0;
  //       } else {
  //         item.actualVolume = 0;
  //       }
  //     })

  //     setData(response.data.data)
  //   }
  // }

  const editRespone = (row) => {
    if (row.followUpStatus === 6) {
      row.numberOfPkgs = row.restNumberOfPkgs || row.restNoOfPkgs;
      row.weight = row.restlWeight;
      row.volume = row.restVolume;
    }

    row.actualNoPackg = 0;
    if (shipmentType === "aircargo") {
      row.actualWeight = 0;
    } else {
      row.actualVolume = 0;
    }

    row.orderNumber = `${row.country?.countryCode}-${row.orderNumber}${shipmentType.toLowerCase() === "aircargo" ? "AC" : "LC"}`;
    return row;
  };

  const getBulkByCode = async () => {
    // if search filed is empty get the normal request
    // if (!clientCode) return getAllClients();
    const res = await findBulkByCode.request(
      shipmentType,
      id,
      clientCode,
      pageNo,
      docsPerPage
    );
    if (res.ok) {
      // const editedResponse = [...res.data.data];
      res.data.data.forEach((row) => editRespone(row));
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    // if multipe is true the 'customer' state should be an array to store multiple customers
    setClients((client) => [...client, obj]);
  };

  // this function render approve icon
  // always shows approve icon if the 'multiple' prop if false
  // if 'multiple' prop is true toggle approve icon with 'x'
  const renderApprove = (child) => {
    return !clients.find((user) => user.id === child.id);
  };

  // this function render 'x' icon
  // but this will only call if multiple is false
  const renderCancel = (child) => {
    return clients.find((user) => user.id === child.id);
  };

  const handleUnselect = (obj) => {
    setClients((customers) =>
      customers.filter((cus) => cus.id !== obj.id)
    );
  };

  const handleChange = (obj, event) => {
    const { name, value } = event.target;

    // check if the name is actualNoPackg
    if (name === "actualNoPackg") {
      // if number of pkgs is 0 then reset all values
      if (+event.target.value === 0) {
        obj.actualNoPackg = 0;
        // reset the "actualWeight" in aircargo page and "actualVolume" in lcl page
        shipmentType === "aircargo"
          ? (obj.actualWeight = 0)
          : (obj.actualVolume = 0);
      }

      // The 'actualNoPackg' can't be more than the 'numberOfPkgs'
      if (+event.target.value > obj.numberOfPkgs) {
        // if it's more make it equal to the numberOfPkgs
        obj.actualNoPackg = obj.numberOfPkgs;
      } else {
        // else just update it
        obj.actualNoPackg = parseInt(+event.target.value) || 0;
      }
      // the same for the 'actualWeight' and 'weight'
    } else if (name === "actualWeight") {
      if (+event.target.value > obj.weight) {
        obj.actualWeight = obj.weight;
      } else {
        obj.actualWeight = +event.target.value || 0;
      }
    } else if (name === "actualVolume") {
      if (+event.target.value > obj.volume) {
        obj.actualVolume = obj.volume;
      } else {
        obj.actualVolume = +event.target.value || 0;
      }
    } else {
      obj[name] = value;
    }

    setClients((client) => {
      const newClients = [...client];
      const index = newClients.findIndex((cli) => cli.id === obj.id);
      newClients[index] = obj;
      return newClients;
    });
  };

  useEffect(() => {
    // if (clientCode) return getBulkByCode();
    if (create) return getBulkByCode();
    getAllCustomers();
  }, [pageNo, docsPerPage]);


  // useEffect(() => (getClients.error && getClients.data) && sweetAlert(getClients.data ,true), [getClients.data])

  return (
    <div className="popup-bigger">
      {getOrdersOnBulkApi.errorAlert}
      {findBulkByCode.errorAlert}
      {getOrdersOnBulkApi.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <div>
          <div className="pd-sides-1">
            <Row>
              {create && (
                <Fragment>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="client">
                        {create ? "Order number" : "Customer code"}
                      </label>
                      <Input
                        id="client"
                        type={create ? "number" : "text"}
                        onChange={(e) => setClientCode(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" && getBulkByCode()}
                      />
                    </FormGroup>
                  </Col>
                  <span style={{ paddingTop: 7 }}>
                    <Button
                      color="success"
                      type="button"
                      className="mt-4"
                      onClick={getBulkByCode}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-search" />
                      </span>
                      <span>Search</span>
                    </Button>
                  </span>
                </Fragment>
              )}
            </Row>
          </div>
          <ul className="popup-customer-list pd-sides-1">
            {clients.map((user) => (
              <li key={user.id}>
                <i
                  className="fas fa-times hover-danger"
                  onClick={handleUnselect.bind(null, user)}
                />
                {user.customer?.customerName}
              </li>
            ))}
          </ul>
          <Pagination
            dataLength={data?.length || 0}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            {!create ? (
              <Table data={data} setData={setData} headers={headers} columns={columns} isPrint={false} />
            ) : (
              <CreateBulkTable
                data={data}
                setData={setData}
                headers={headers}
                columns={columns}
                clients={clients}
                renderCancel={renderCancel}
                handleSelect={handleSelect}
                renderApprove={renderApprove}
                handleUnselect={handleUnselect}
                handleChange={handleChange}
              >
                <span
                  className="me-1"
                  fun="handleSelect"
                  condition="renderApprove"
                >
                  <div id="approve" className="table-action cursor-pointer">
                    <i className="fas fa-check fa-lg hover-info"></i>
                  </div>
                </span>
                <span
                  className="me-1"
                  fun="handleUnselect"
                  condition="renderCancel"
                >
                  <div id="remove" className="table-action cursor-pointer">
                    <i className="fas fa-times fa-lg hover-danger padding-half" />
                  </div>
                </span>
              </CreateBulkTable>
            )}
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default CustomerPopup;
