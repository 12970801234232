import React, { useState, useEffect } from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Card,
  CardBody,
  Input,
  FormGroup,
  Col,
  Row,
  Button,
  Container,
  CardHeader,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import FilePicker from "components/common/FilePicker";
import template from "./templateApi";
import useApi from "hooks/useApi";
import Spinner from "../../components/Spinner";
import useAlert from "hooks/useAlert";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import content from "./content";

const Aboutus = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [spinner, setSpinner] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const templateApi = useApi(template.getPortfolio);
  const aboutApi = useApi(template.postAbout);

  const { alert, sweetAlert } = useAlert();

  const preload = async () => {
    const res = await templateApi.request();

    if (res.status === 200) {
      setValue("aboutHeader", res.data.data?.aboutHeader || "");
      setValue("arAboutHeader", res.data.data?.arAboutHeader || "");
      setValue("aboutContent", res.data.data?.aboutContent || "");
      setValue("arAboutContent", res.data.data?.arAboutContent || "");
    }
  };

  const submitHeader = async (data) => {
    setSpinner(true);
    const formData = new FormData();
    for (const key in data) {
      if (key === "aboutImg") formData.append(key, data[key][0]);
      else formData.append(key, data[key]);
    }

    const res = await aboutApi.request(formData);

    if (res.status === 200) {
      sweetAlert("Done");
    }
    setSpinner(false);
  };

  const images = watch("aboutImg") || {};

  useEffect(() => {
    preload();
  }, []);

  if (templateApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {templateApi.errorAlert}
      {aboutApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}

      <SimpleHeader parents={[content.template[culture]]} backBtn />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.aboutUs[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(submitHeader)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="aboutus-title"
                    >
                      Title
                    </label>
                    <Controller
                      control={control}
                      name="aboutHeader"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="aboutus-title"
                          placeholder="Title"
                          type="text"
                          className={errors.Title && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="content">
                      Content
                    </label>
                    <Controller
                      control={control}
                      name="aboutContent"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="content"
                          placeholder="content"
                          type="textarea"
                          rows="5"
                          className={errors.subText && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4" className="rtl-col">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="ar-aboutus-title"
                    >
                      العنوان
                    </label>
                    <Controller
                      control={control}
                      name="arAboutHeader"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="ar-aboutus-title"
                          placeholder="العنوان"
                          type="text"
                          className={errors.Title && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="rtl-col">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="ar-content">
                      المحتوى
                    </label>
                    <Controller
                      control={control}
                      name="arAboutContent"
                      rules={{
                        required: "name field is required",
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="ar-content"
                          placeholder="المحتوى"
                          rows="5"
                          type="textarea"
                          className={errors.subText && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <label className="form-control-label" htmlFor="arSubText">
                    {content.bg[culture]}
                  </label>
                  <FilePicker
                    multiple={false}
                    images={images}
                    setImages={(img) => setValue("aboutImg", img)}
                  />
                </Col>
              </Row>
              <Button color="success" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{content.save[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Aboutus;
