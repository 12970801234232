import React, { useState, useEffect, Fragment } from "react";
import useApi from "hooks/useApi";
import { useParams } from "react-router";
import { Row, CardBody, Button, Col, FormGroup, Input } from "reactstrap";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import Spinner from "../../../components/Spinner";
import bulks from "api/bulks";
import content from "../content";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

const BulkFilter = ({ setBulkNumber, setBulkModal, setValue, setBulkId }) => {
  const { shipmentType } = useParams();
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [number, setNumber] = useState("");

  const getByColumn = useApi(bulks.getByColumn);

  const weightOrVolume = shipmentType === "1" ? commenContent.weight[culture] : commenContent.volume[culture];  
  const colWeithOrVolume = shipmentType === "1" ? "weight" : "volume";  
  const headers = [commenContent.actions[culture], commenContent.code[culture], weightOrVolume];
  const columns = ["bulkPckgNumShown", colWeithOrVolume];

  const editResponse = (item) => {
    item.bulkPckgNumShown = `B-${item.bulkPckgNum}${item.country?.countryCode}`;
    return item;
  };
  const getPackages = async () => {
    const shipment = shipmentType === "1" ? "airCargo" : "lcl";

    const res = await getByColumn.request(
      "bulkPckgNum",
      number,
      pageNo,
      docsPerPage,
      shipment
    );

    if (res.status === 200) {
      res.data.data.forEach((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    setValue("bulks", obj.bulkPckgNum);
    setValue("country", obj.country?.name);
    setValue("bulkPckgNumShown", obj.bulkPckgNumShown);
    setValue("countryCode", obj.country?.countryCode);
    setBulkNumber(obj.bulkPckgNum);
    setBulkId(obj.id);
    setBulkModal(false);
    setValue("numberOfPkgs", obj.numberOfPkgs);
    if (shipmentType === "1") setValue("weight", obj.weight);
    else setValue("volume", obj.volume);
  };

  useEffect(() => {
    getPackages();
  }, [pageNo, docsPerPage]);

  return (
    <Fragment>
      {getByColumn.errorAlert}
      {getByColumn.loading && <Spinner gate="#29bb64" bg="#fff" />}
      <CardBody>
        <Row>
          <Fragment>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="client">
                  {content.bulkPackageNumber[culture]}
                </label>
                <Input
                  id="client"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && getPackages()}
                />
              </FormGroup>
            </Col>
            <span style={{ paddingTop: 7 }}>
              <Button
                color="success"
                type="button"
                className="mt-4"
                onClick={getPackages}
                disabled={getByColumn.loading}
              >
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-search" />
                </span>
                <span>{commenContent.search[culture]}</span>
              </Button>
            </span>
          </Fragment>
        </Row>
      </CardBody>
      <Pagination
        dataLength={data.length}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={headers}
          columns={columns}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info"></i>
            </div>
          </span>
        </Table>
      </Pagination>
    </Fragment>
  );
};

export default BulkFilter;
