import React, { useState, useEffect, useContext } from "react";
import content from "./content";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledTooltip,
  Form,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import useApi from "hooks/useApi";
import Spinner from "../../../components/Spinner";
import useAbility from "hooks/useAbility";
import useAlert from "hooks/useAlert";
import Pagination from "components/common/Pagination";
import Table from "components/common/CustomTable";
import user from "api/user";
import commenContent from "components/common/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import usePagination from "hooks/usePagination";
import { PaginationContext } from "context/PaginationContext";
import useSearchPopup from "hooks/useSearchPopup";
import usePrevRequest from "hooks/usePrevRequest";
import SearchModal from "components/common/SearchModal";

const Ability = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const headers = [commenContent.actions[culture], commenContent.name[culture]];
  const columns = ["fullName"];

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const checkAbility = useAbility("User");

  const { alert } = useAlert();

  const usersApi = useApi(user.users);
  const filterUsersApi = useApi(user.filter);
  const getByColumn = useApi(user.getNormalUsersByColumn);

  const paginationCtx = useContext(PaginationContext);
  // This varibale holds the page name so when the user navigate to 'air recived details'
  // and then came back i will set him back when he was
  const pageName = "users";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const editResponse = (item) => item;

  const updateData = (arr) => {
    setData(arr);
  };

  // search popup
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data,
    editResponse
  );

  const getUsers = async () => {
    const res = await usersApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (pageNo > 1) {
      return setPageNo(1);
    }
    handleSeach();
  };

  const handleSeach = async () => {
    const res = await filterUsersApi.request(search, 1, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const editRenderer = () => checkAbility("edit");
  const deleteRenderer = () => checkAbility("delete");

  usePrevRequest(
    getUsers,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck,
    search,
    handleSeach
  );

  if (usersApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      {alert}
      {usersApi.errorAlert}
      {filterUsersApi.errorAlert}
      {getByColumn.errorAlert}
      {/* {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />} */}
      <SimpleHeader parents={[content.user[culture]]} />
      <Container className="mt--6" fluid>
        <Card className="card-plain">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className="mb-0">{content.users[culture]}</h3>
            </div>
          </CardHeader>
          {checkAbility("view") && (
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="4" lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="user">
                        {content.user[culture]}
                      </label>
                      <Input
                        id="user"
                        placeholder={commenContent.search[culture]}
                        type="text"
                        onChange={(event) => setSearch(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mt-2">
                    <Button color="success" type="submit" className="mt-4" disabled={!search}>
                      <span className="btn-inner--icon me-1">
                        <i className="fas fa-search" />
                      </span>
                      <span>{commenContent.search[culture]}</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          )}
          <Pagination
            dataLength={data.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
            setPageNo={
              searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
            }
            docsPerPage={
              searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
            }
            setDocsPerPage={
              searchPopup.isSelected
                ? searchPopup.setDocsPerPage
                : setDocsPerPage
            }
          >
            <Table
              pageName={pageName}
              headers={headers}
              columns={columns}
              // when the user search using the popup and select a row
              // after then only use the hookData states from searchPopup hook
              data={data}
              setData={setData}
              editRenderer={editRenderer}
              deleteRenderer={deleteRenderer}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
            >
              <span
                to={`/${culture}-admin/settings/updateability`}
                condition="editRenderer"
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commenContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="me-1" condition="deleteRenderer">
                <div id="delete" className="table-action cursor-pointer">
                  <i className="fas fa-trash hover-danger fa-lg" />
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commenContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
        />
      )}
    </div>
  );
};

export default Ability;
