import { useReducer, createContext, useEffect } from "react";

const initialState = {
  key: 0,
  pageNo: 1,
  docsPerPage: 10,
  pageName: "",
  header: [],
  column: [],
  isSelect: false,
  searchField: [],
  colIndex: 0,
  visitedRowId: ""
};

export const AdvanceSearchContext = createContext();

const reducer = (state, action) => {
  if (action.type === "SET_ITEMS") {
    return {
      ...state,
      ...action.obj,
    };
  }

  return state;
};

export const AdvanceSearchContextProvider = ({ children }) => {
  const [searchState, searchDispatcher] = useReducer(reducer, initialState);

  const setItems = (obj) => {
    searchDispatcher({ type: "SET_ITEMS", obj });
  };

  const searchContext = {
    key: searchState.key,
    pageNo: searchState.pageNo,
    pageName: searchState.pageName,
    docsPerPage: searchState.docsPerPage,
    header: searchState.header,
    column: searchState.column,
    isSelect: searchState.isSelect,
    searchField: searchState.searchField,
    colIndex: searchState.colIndex,
    visitedRowId: searchState.visitedRowId,
    setItems,
  };

  const isSelect = searchContext.isSelect;

  useEffect(() => {
    if (isSelect) setItems({ isSelect: false })
  }, [isSelect])

  return (
    <AdvanceSearchContext.Provider value={searchContext}>
      {children}
    </AdvanceSearchContext.Provider>
  );
};
