import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import countryApi from "api/country";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import content from "./content";
import useCulture from "hooks/useCulture";
import costCenter from "api/costCenter";

const CostCenterTable = (props) => {
  const culture = useCulture();

  const { setModal, setValue, sweetAlert } = props;

  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [filter, setFilter] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const findByName = useApi(costCenter.filter);

  const findCountryByName = async () => {
    const schema = {};

    if (filter) schema.name = filter;

    const res = await findByName.request(schema, pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    setModal(false);
    setValue("costCenter", obj.name);
    setValue("costCenterId", obj.id);
  };

  useEffect(() => {
    findCountryByName();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
      findByName.error && findByName.data && sweetAlert(findByName.data, true),
    [findByName.data]
  );

  if (findByName.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="cost center">
                {content.name[culture]}
              </label>
              <Input
                id="cost center"
                placeholder={content.search[culture]}
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findCountryByName()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findCountryByName}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span>{content.search[culture]}</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={data?.length || 0}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={[content.actions[culture], content.name[culture]]}
          columns={["name"]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info" />
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
};

export default CostCenterTable;
