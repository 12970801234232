import client from './client';

const endpoint = '/v1/calculatorSetting';

const minimumCharges = (page, docPerPage) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const createOne = schema => client.post(`${endpoint}`, schema);

const findByName = (name, page, docPerPage) => client.get(`${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`);

const getByColumn = (col, val, page, docsPerPage) => (
  client.get(`${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

const deleteOne = id => client.delete(`${endpoint}/${id}`);

const updateOne = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const activate = id => client.put(`${endpoint}/activate/${id}`)

const filter = (schema, page, docsPerPage) =>
  client.put(
    `${endpoint}/filter?Page=${page}&PostsPerPage=${docsPerPage}`, schema
  );

export default {
  minimumCharges,
  filter,
  createOne,
  findByName,
  getByColumn,
  deleteOne,
  updateOne,
  activate
}